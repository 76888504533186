import * as types from "./constants";
// router.post("/wheel/add_wheel", auth, wheels.add_wheel);
// router.post("/wheel/update_wheel", auth, wheels.update_wheel);
// router.post("/wheel/play", auth, wheels.play);


export function add_wheel(payload) {
  return {
    type: types.ADD_WHEEL,
    payload,
  };
}
export function update_wheel(payload) {
  return {
    type: types.UPDATE_WHEEL,
    payload,
  };
}
export function get_wheel(payload) {
  return {
    type: types.GET_WHEEL,
    payload,
  };
}
export function play_wheel(payload) {
  return {
    type: types.PLAY_WHEEL,
    payload,
  };
}
export function delete_wheel(payload) {
  return {
    type: types.DELETE_WHEEL,
    payload,
  };
}
export function reset_wheel(payload) {
  return {
    type: types.RESET_WHEEL,
    payload,
  };
}
export function get_historys(payload) {
  return {
    type: types.GET_HISTORYS,
    payload,
  };
}