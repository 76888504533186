import * as Types from "./constants";

const initState = {
  itemList: {
    msg: null,
    status: false,
    isLoading: false,
    data: [],
    total: 0,
    last_page: 0,
    current_page: 0,
    per_page: 20,
  },
  itemPriceList: {
    msg: null,
    status: false,
    isLoading: false,
    data: [],
    total: 0,
    last_page: 0,
    current_page: 0,
    per_page: 20,
  },
  addItemPrice: {
    isLoading: false,
    status: false,
    msg: null,
    data: {}
  },
  updateItemPrice: {
    isLoading: false,
    status: false,
    msg: null,
    data: {}
  },
  deleteItemPrice: {
    isLoading: false,
    status: false,
    msg: null,
    data: {}
  },

  addItem: {
    isLoading: false,
    status: false,
    msg: null,
    data: {}
  },
  updateItem: {
    isLoading: false,
    status: false,
    msg: null,
    data: {}
  },
  deleteItem: {
    isLoading: false,
    status: false,
    msg: null,
    data: {}
  },
  buyItem: {
    isLoading: false,
    status: false,
    msg: null,
    data: {}
  },
  historyList: {
    msg: null,
    status: false,
    isLoading: false,
    data: [],
    total: 0,
    last_page: 0,
    current_page: 0,
    per_page: 20,
  },
  updateHistory: {
    isLoading: false,
    status: false,
    msg: null,
    data: {}
  },
  deleteHistory: {
    isLoading: false,
    status: false,
    msg: null,
    data: {}
  },
  itemPrice: {
    isLoading: false,
    status: false,
    msg: null,
    data: {}
  },
};
export default function itemReducer(state = initState, action = {}) {
  switch (action.type) {
    case Types.ADD_ITEM:
      return {
        ...state,
        addItem: {
          ...state.addItem,
          isLoading: true,
          status: false,
          msg: null,
        }
      };
    case Types.ADD_ITEM_SUCCESS:
      if (action.payload.status == true)
        state.itemList.data = [action.payload.data].concat(state.itemList.data)

      return {
        ...state,
        itemList: {
          ...state.itemList,
          data: state.itemList.data
        },
        addItem: {
          ...state.addItem,
          ...action.payload,
          isLoading: false,
        }
      };
    case Types.ADD_ITEM_ERROR:
      return {
        ...state,
        addItem: {
          ...state.addItem,
          ...action.payload,
          isLoading: false,
          status: false
        }
      };
    //////////
    case Types.UPDATE_ITEM:
      return {
        ...state,
        updateItem: {
          ...state.updateItem,
          isLoading: true,
          status: false,
          msg: null,
        }
      };
    case Types.UPDATE_ITEM_SUCCESS:
      if (action.payload.status == true) {
        let objIndex = state.itemList.data.findIndex((obj => obj._id == action.payload.data._id));
        state.itemList.data[objIndex] = action.payload.data
      }
      return {
        ...state,
        itemList: {
          ...state.itemList,
          data: [...state.itemList.data]
        },
        updateItem: {
          ...state.updateItem,
          ...action.payload,
          isLoading: false,
        }
      };
    case Types.UPDATE_ITEM_ERROR:
      return {
        ...state,
        updateItem: {
          ...state.updateItem,
          ...action.payload,
          isLoading: false,
          status: false
        }
      };
    /////////
    case Types.GET_ITEM:
      return {
        ...state,
        itemList: {
          ...state.itemList,
          isLoading: true,
          status: false,
          msg: null,
        }
      };
    case Types.GET_ITEM_SUCCESS:
      return {
        ...state,
        itemList: {
          ...state.itemList,
          ...action.payload.data,
          isLoading: false,
          status: action.payload.status,
          msg: action.payload.msg
        }
      };
    case Types.GET_ITEM_ERROR:
      return {
        ...state,
        itemList: {
          ...state.itemList,
          status: false,
          isLoading: false,
          msg: action.payload.msg
        }
      };
    /////////
    case Types.GET_HISTORYS:
      return {
        ...state,
        historyList: {
          ...state.historyList,
          isLoading: true,
          status: false,
          msg: null,
        }
      };
    case Types.GET_HISTORYS_SUCCESS:
      return {
        ...state,
        historyList: {
          ...state.historyList,
          ...action.payload.data,
          isLoading: false,
          status: action.payload.status,
          msg: action.payload.msg
        }
      };
    case Types.GET_HISTORYS_ERROR:
      return {
        ...state,
        historyList: {
          ...state.historyList,
          status: false,
          isLoading: false,
          msg: action.payload.msg
        }
      };
    /////////
    case Types.DELETE_ITEM:
      return {
        ...state,
        deleteItem: {
          ...state.deleteItem,
          status: false,
          isLoading: true,
          msg: null,
        }
      };
    case Types.DELETE_ITEM_SUCCESS:
      if (action.payload.status) {
        state.itemList.data = state.itemList.data.filter((item) => item._id !== action.payload.data._id)
      }
      return {
        ...state,
        itemList: {
          ...state.itemList,
          data: state.itemList.data
        },
        deleteItem: {
          ...state.deleteItem,
          isLoading: false,
          ...action.payload,
        }
      };
    case Types.DELETE_ITEM_ERROR:
      return {
        ...state,
        deleteItem: {
          ...state.deleteItem,
          ...action.payload,
          status: false,
          isLoading: false,
        }
      };
    /////////
    case Types.BUY_ITEM:
      return {
        ...state,
        buyItem: {
          ...state.buyItem,
          status: false,
          isLoading: true,
          msg: null,
        }
      };
    case Types.BUY_ITEM_SUCCESS:

      return {
        ...state,
        buyItem: {
          ...state.buyItem,
          isLoading: false,
          ...action.payload,
        }
      };
    case Types.BUY_ITEM_ERROR:
      return {
        ...state,
        buyItem: {
          ...state.buyItem,
          ...action.payload,
          status: false,
          isLoading: false,
        }
      };



    //////////
    case Types.UPDATE_HISTORYS:
      return {
        ...state,
        updateHistory: {
          ...state.updateHistory,
          isLoading: true,
          status: false,
          msg: null,
        }
      };
    case Types.UPDATE_HISTORYS_SUCCESS:
      if (action.payload.status == true) {
        let objIndex = state.historyList.data.findIndex((obj => obj._id == action.payload.data._id));
        state.historyList.data[objIndex] = action.payload.data
      }
      return {
        ...state,
        historyList: {
          ...state.historyList,
          data: [...state.historyList.data]
        },
        updateHistory: {
          ...state.updateHistory,
          ...action.payload,
          isLoading: false,
        }
      };
    case Types.UPDATE_HISTORYS_ERROR:
      return {
        ...state,
        updateHistory: {
          ...state.updateHistory,
          ...action.payload,
          isLoading: false,
          status: false
        }
      };
    ///////
    case Types.DELETE_HISTORYS:
      return {
        ...state,
        deleteHistory: {
          ...state.deleteHistory,
          status: false,
          isLoading: true,
          msg: null,
        }
      };
    case Types.DELETE_HISTORYS_SUCCESS:
      if (action.payload.status) {
        state.historyList.data = state.historyList.data.filter((item) => item._id !== action.payload.data._id)
      }
      return {
        ...state,
        historyList: {
          ...state.historyList,
          data: state.historyList.data
        },
        deleteHistory: {
          ...state.deleteHistory,
          isLoading: false,
          ...action.payload,
        }
      };
    case Types.DELETE_HISTORYS_ERROR:
      return {
        ...state,
        deleteHistory: {
          ...state.deleteHistory,
          ...action.payload,
          status: false,
          isLoading: false,
        }
      };
    case Types.GET_ITEM_PRICE:
      return {
        ...state,
        itemPrice: {
          ...state.itemPrice,
          isLoading: true,
          status: false,
          msg: null,
        }
      };
    case Types.GET_ITEM_PRICE_SUCCESS:

      return {
        ...state,
        itemPrice: {
          ...state.itemPrice,
          data: action.payload.data,
          isLoading: false,
          status: action.payload.status,
          msg: action.payload.msg
        }
      };
    case Types.GET_ITEM_PRICE_ERROR:
      return {
        ...state,
        itemPrice: {
          ...state.itemPrice,
          status: false,
          isLoading: false,
          msg: action.payload.msg
        }
      };
    /////////
    case Types.ADD_ITEM_PRICE_TYPE:
      return {
        ...state,
        addItemPrice: {
          ...state.addItemPrice,
          isLoading: true,
          status: false,
          msg: null,
        }
      };
    case Types.ADD_ITEM_PRICE_TYPE_SUCCESS:
      if (action.payload.status == true)
        state.itemPriceList.data = [action.payload.data].concat(state.itemPriceList.data)

      return {
        ...state,
        itemPriceList: {
          ...state.itemPriceList,
          data: state.itemPriceList.data
        },
        addItemPrice: {
          ...state.addItemPrice,
          ...action.payload,
          isLoading: false,
        }
      };
    case Types.ADD_ITEM_PRICE_TYPE_ERROR:
      return {
        ...state,
        addItemPrice: {
          ...state.addItemPrice,
          ...action.payload,
          isLoading: false,
          status: false
        }
      };
    //////////
    case Types.UPDATE_ITEM_PRICE_TYPE_ERROR:
      return {
        ...state,
        updateItemPrice: {
          ...state.updateItemPrice,
          isLoading: true,
          status: false,
          msg: null,
        }
      };
    case Types.UPDATE_ITEM_PRICE_TYPE_SUCCESS:
      if (action.payload.status == true) {
        let objIndex = state.itemPriceList.data.findIndex((obj => obj._id == action.payload.data._id));
        state.itemPriceList.data[objIndex] = action.payload.data
      }
      return {
        ...state,
        itemPriceList: {
          ...state.itemPriceList,
          data: [...state.itemPriceList.data]
        },
        updateItemPrice: {
          ...state.updateItemPrice,
          ...action.payload,
          isLoading: false,
        }
      };
    case Types.UPDATE_ITEM_PRICE_TYPE_ERROR:
      return {
        ...state,
        updateItemPrice: {
          ...state.updateItemPrice,
          ...action.payload,
          isLoading: false,
          status: false
        }
      };
    /////////
    case Types.GET_ITEM_PRICE_TYPE:
      return {
        ...state,
        itemPriceList: {
          ...state.itemPriceList,
          isLoading: true,
          status: false,
          msg: null,
        }
      };
    case Types.GET_ITEM_PRICE_TYPE_SUCCESS:
      return {
        ...state,
        itemPriceList: {
          ...state.itemPriceList,
          ...action.payload.data,
          isLoading: false,
          status: action.payload.status,
          msg: action.payload.msg
        }
      };
    case Types.GET_ITEM_PRICE_TYPE_ERROR:
      return {
        ...state,
        itemPriceList: {
          ...state.itemPriceList,
          status: false,
          isLoading: false,
          msg: action.payload.msg
        }
      };
    /////////
    /////////
    case Types.DELETE_ITEM_PRICE_TYPE:
      return {
        ...state,
        deleteItemPrice: {
          ...state.deleteItemPrice,
          status: false,
          isLoading: true,
          msg: null,
        }
      };
    case Types.DELETE_ITEM_PRICE_TYPE_SUCCESS:
      if (action.payload.status) {
        state.itemPriceList.data = state.itemPriceList.data.filter((item) => item._id !== action.payload.data._id)
      }
      return {
        ...state,
        itemPriceList: {
          ...state.itemPriceList,
          data: state.itemPriceList.data
        },
        deleteItemPrice: {
          ...state.deleteItemPrice,
          isLoading: false,
          ...action.payload,
        }
      };
    case Types.DELETE_ITEM_PRICE_TYPE_ERROR:
      return {
        ...state,
        deleteItemPrice: {
          ...state.deleteItemPrice,
          ...action.payload,
          status: false,
          isLoading: false,
        }
      };
    ///////
    case Types.RESET_ITEM:
      let newState = {}
      if (action.payload.remove[0] == "all")
        newState = initState
      else
        for (let [key, value] of Object.entries(state)) {
          if (action.payload.remove.includes(key))
            newState[key] = { ...initState[key] }
          else newState[key] = value
        };
      return newState

    default:
      return state;
  }
}
