import { Space, Layout, Button, Row, Col, Popover } from 'antd';
import { isMobile, BrowserView, MobileView } from 'react-device-detect';
import {
  SketchOutlined,
  LogoutOutlined,
  MenuFoldOutlined,
  MenuUnfoldOutlined,
  FireOutlined,
  UserOutlined,
  ShoppingOutlined,
  LinkOutlined,
  AppstoreOutlined,
  QuestionCircleOutlined
} from '@ant-design/icons';
import React, { useEffect, useState } from 'react';
import Mobile_Menu from '../components/Mobile_Menu'
import App from '../router/App';
import { useDispatch, useSelector } from "react-redux";
import { Link } from "react-router-dom";
import { logout } from '../modules/user/actions';
import { httpClient } from '../utils/Api';
import { curencyFormat } from '../utils/help';
import Seeding from '../components/Seeding';
const { Header, Content, Footer } = Layout;
const Main_App = (props) => {
  const { history, location, match } = props
  const _me = useSelector(({ user }) => user.me);
  const _settingList = useSelector(({ setting }) => setting.settingList);
  const user = _me.user

  const dispatch = useDispatch()
  const initialState = {
    menuOpen: false,
    contact: null,
    link: null
  }
  const [state, setState] = useState(initialState)
  const onLogoutClick = () => {
    dispatch(logout())
    localStorage.removeItem('skintc_token');
    httpClient.defaults.headers.common['Authorization'] = '';
    history.push('/');
  }
  useEffect(() => {
    if (_settingList.status) {

      let contact = _settingList.data.length && _settingList.data.find(item => item.key == "contact")
      let link = _settingList.data.length && _settingList.data.find(item => item.key == "link")

      setState({
        ...state,
        link: link?.value,
        contact: contact?.value,

      })
    }

  }, [_settingList])
  const Menu_Content = (props) => {
    return (
      <>
        <Link to="/shop/accounts" style={{ marginTop:20, height: 30, borderBottom: isMobile? "1px solid rgb(241 232 232)" :null}}>
          <Button type='link' icon={<ShoppingOutlined />} size="small" style={{ color: "black", fontSize: 14, margin: "0 4px"  }}>
            <span style={{ color: "black", fontSize: 14, marginLeft: 8 }}>Shop Acc</span>
          </Button>
        </Link>
        <Link to="/shop/items" style={{ marginTop:20, height: 30, borderBottom:  isMobile? "1px solid rgb(241 232 232)" :null}}>
          <Button type='link' icon={<ShoppingOutlined />} size="small" style={{ color: "black", fontSize: 14, margin: "0 4px" }}>
            <span style={{ color: "black", fontSize: 14, marginLeft: 8 }}>Shop Vật phẩm</span>
          </Button>
        </Link>
        <Link to="/boostings" style={{ marginTop:20, height: 30, borderBottom:  isMobile? "1px solid rgb(241 232 232)" :null}}>
          <Button type='link' icon={<FireOutlined />} size="small" style={{ color: "black", fontSize: 14, margin: "0 4px" }}>
            <span style={{ color: "black", fontSize: 14, marginLeft: 8 }}>Cày Thuê</span>
          </Button>
        </Link>
        <Link to="/profile/deposit" style={{ marginTop:20, height: 30, borderBottom:  isMobile? "1px solid rgb(241 232 232)" :null}}>
          <Button type='link' icon={<SketchOutlined />} size="small" style={{ color: "black", fontSize: 14, margin: "0 4px" }}>
            <span style={{ color: "black", fontSize: 14, marginLeft: 8 }}>Nạp Tiền</span>
          </Button>
        </Link>
        <Button type='link' icon={<QuestionCircleOutlined />} size="small" style={{color: "black",  height: 30, borderBottom:  isMobile? "1px solid rgb(241 232 232)" :null , margin: "20px 0px 20px 4px"}}>
          <a href={state.link?.help} target="_blank" style={{ color: "black", fontSize: 14, marginLeft: 8 }}>Hướng Dẫn</a>
        </Button>
        {
          user && user?.username ? <>
            {/* <Button type='link' icon={<UserOutlined />} size="small" style={{ color: "black", fontSize: 14, margin: "0 4px" }}>
              <Link to="/profile">
                <span style={{ color: "black", fontSize: 14, marginLeft: 8 }}>Tài khoản</span>
              </Link>
            </Button> */}
            {user.role == 0 ?
              <Link to="/admin">
                <Button type='link' icon={<AppstoreOutlined />} size="small" style={{ color: "black", fontSize: 14, margin: "0 4px" }}>
                  <span style={{ color: "black", fontSize: 14, marginLeft: 8 }}>Admin</span>
                </Button>
              </Link> : null
            }
          </> : null
        }
        {
          !isMobile ?
            user && user?.username ? <>
              <Popover placement="bottom" content={
                <Space>
                  <Link to="/profile">
                    <Button type='dashed' icon={<UserOutlined />} size="small" style={{ color: "black", fontSize: 14, margin: "0 4px" }}>

                      <span style={{ color: "black", fontSize: 14, marginLeft: 8 }}>Tài khoản</span>

                    </Button>
                  </Link>
                  <Button type="dashed" size='small' danger onClick={() => onLogoutClick()}>Đăng xuất</Button>
                </Space>
              } title={`Xin chào ${user?.name}!`}>
                <Link to="/profile/" >

                  <Button icon={<UserOutlined />} type="primary" size='small' style={{ borderRadius: 20, fontSize: 14, padding: "0 10px", margin: "0 4px" }}
                    className="color1">
                    <span style={{ color: "white", fontSize: 14 }}>
                      <span >{user.username.length > 10 ? (user.username.substr(0, 10).toUpperCase() + "***") : user.username.toUpperCase()}</span>
                      <span style={{ marginLeft: 5, marginRight: 5 }}> - </span>
                      <span>{curencyFormat(user.balance)}</span>
                    </span>


                  </Button>
                </Link>
              </Popover>

            </> :
              <>
                <Link to="/signin">
                  <Button icon={<UserOutlined />} type="primary" size='small' style={{ color: "white", fontSize: 14, padding: "0 10px", margin: "0 4px" }} className="color1">

                    <span style={{ color: "white", fontSize: 14, marginLeft: 8 }}>Đăng Nhập</span>

                  </Button>
                </Link>
                <Link to="/signup">
                  <Button icon={<UserOutlined />} type="dashed" size='small' style={{ color: "white", fontSize: 14, padding: "0 10px" }} className='color2'>

                    <span style={{ fontSize: 14, marginLeft: 8, color: "white" }} >Đăng Ký</span>

                  </Button>
                </Link>
              </> : null
        }

        <MobileView>
          {
            user && user?.username ?

              <>
                <Link to="/profile/">
                  <Button icon={<UserOutlined />} type="primary" size='small' style={{ fontSize: 14, width: "100%", borderRadius: 5, height: 40, marginBottom: 10, border: "none" }} className="color1_" >

                    <span style={{ color: "white", fontSize: 14 }}>
                      <span >{user.username.toUpperCase()}</span>
                      <span style={{ marginLeft: 5, marginRight: 5 }}> - </span>
                      <span>{curencyFormat(user.balance)}</span>
                    </span>


                  </Button>
                </Link>
                <Button icon={<LogoutOutlined />} type="dashed" size='small' danger onClick={() => onLogoutClick()} style={{ fontSize: 14, width: "100%", borderRadius: 5, height: 40, marginBottom: 10, border: "none" }} className="color2_" >ĐĂNG XUẤT</Button>
              </>
              :
              <>
                <Link to="/signin">
                  <Button type="primary" size='small' style={{ fontSize: 14, width: "100%", borderRadius: 5, height: 40, marginBottom: 10, border: "none" }} className="color1_" >

                    <span style={{ color: "white", fontSize: 14, marginLeft: 8 }}>ĐĂNG NHẬP</span>


                  </Button>
                </Link>
                <Link to="/signup">
                  <Button type="dashed" size='small' danger
                    style={{ fontSize: 14, width: "100%", borderRadius: 5, height: 40, marginBottom: 10, border: "none" }} className="color2_" >

                    <span style={{ fontSize: 14, marginLeft: 8, color: "white" }} >ĐĂNG KÝ</span>

                  </Button>
                </Link>
              </>
          }
        </MobileView>
      </>
    )
  }

  return (
    <Layout>
      <Header
        style={{
          position: 'fixed',
          zIndex: 1,
          width: '100%',
          padding: 0,
          background: "white",
          height: 70,
          zIndex: 999
        }}
      >
        <div style={{
          width: location.pathname.includes("profile") || location.pathname.includes("admin") ? "100%" : isMobile ? "100%" : "1400px",
          margin: "0 auto",
          padding: isMobile ? '0 10px' : '0 50px',

        }}>

          <Row>
            <Col onClick={() => history.push("/")} span={5} style={{ textAlign: "start" }}>
              <img style={{ cursor: "pointer" }} onClick={() => history.push("/")} height={50} src="/assets/images/logo.gif" />
            </Col>
            <Col span={19} style={{ textAlign: "end" }}>
              <MobileView>
                <Button
                  className='color1_'
                  type="primary"
                  danger
                  style={{ border: "none" }}
                  onClick={() => setState({ ...state, menuOpen: !state.menuOpen })}
                >
                  {state.menuOpen ? <MenuUnfoldOutlined /> : <MenuFoldOutlined />}
                </Button>
                <Mobile_Menu onLogoutClick={onLogoutClick} open={state.menuOpen} onClose={() => setState({ ...state, menuOpen: false })} menu={<Menu_Content />} />
              </MobileView>
              <BrowserView>
                <Row
                  style={{
                    flexDirection: "row",
                    justifyContent: "flex-end",
                    alignSelf: "center",
                    alignItems: "center",
                  }}
                  span={24}
                >
                  <Col>
                    <Menu_Content />
                  </Col>
                </Row>
              </BrowserView>

            </Col>
          </Row>
        </div>

      </Header>
      <Content
        className="site-layout"
        style={{
          padding: isMobile ? '0 10px' : '0 50px',
          width: location.pathname.includes("profile") || location.pathname.includes("admin") ? "100%" : isMobile ? "100%" : "1400px",
          margin: "0 auto",
          marginTop: 80,
          minHeight: 'calc(100vh - 180px)'
        }}
      >

        <div
          className="site-layout-background"

        >
          <App match={match} user={user} location={location} history={history} />
        </div>
      </Content>
      <Footer
        style={{
          minHeight: 250,
          background: "#06171e",
          padding: 20
        }}
      >
        <div style={{
          padding: isMobile ? '0 10px' : '0 50px',
          width: isMobile ? "100%" : "1248px",
          margin: "0 auto",

        }}>
          <Row>
            <Col xl={8} lg={6} md={12} sm={12} xs={24} style={{ padding: !isMobile ? "30px 30px 30px 0" : "10px" }}>
              <p style={{ color: "white", fontSize: 30, fontFamily: "'Anton', sans-serif", fontStyle: "italic", marginBottom: 0 }}>SKIN <span style={{ color: "#ff5722", fontSize: 35 }}>TOCCHIEN</span></p>
              <p style={{ color: "white", textAlign: "justify" }}>Shop Skins & Acc Game Giao Dịch Tự Động Uy Tín Số 1 Việt Nam. SHOP UY TÍN KHÔNG Á? PHẢI THỬ MỚI BIẾT ĐƯỢC!</p>

              {/* <Tag icon={<TwitterOutlined />} color="#55acee">
                Webiste
              </Tag>
              <Tag icon={<YoutubeOutlined />} color="#cd201f">
                Youtube
              </Tag>
              <Tag icon={<FacebookOutlined />} color="#3b5999">
                Facebook
              </Tag> */}

            </Col>
            <Col xl={8} lg={6} md={12} sm={12} xs={24} style={{ padding: !isMobile ? "30px" : 10 }}>
              <p style={{ color: "white", fontSize: 30, fontFamily: "'Anton', sans-serif", fontStyle: "italic", marginBottom: 0, marginLeft: !isMobile ? 10 : 0 }}>SUPPORT <span style={{ color: "#2196f3", fontSize: 35 }}>BLOG</span></p>
              <Button type='link' icon={<LinkOutlined />} size="small" style={{ color: "white", fontSize: 14, marginBottom: 15 }}>
                <a href={state.link?.link_1?.url} target="_blank">
                  <span style={{ color: "white", fontSize: 16, marginLeft: 8 }}>{state.link?.link_1?.name}</span>
                </a>
              </Button>
              <br />
              <Button type='link' icon={<LinkOutlined />} size="small" style={{ color: "white", fontSize: 14, marginBottom: 15 }}>
                <a href={state.link?.link_2?.url} target="_blank">
                  <span style={{ color: "white", fontSize: 16, marginLeft: 8 }}>{state.link?.link_2?.name}</span>
                </a>
              </Button>
              <br />
              <Button type='link' icon={<LinkOutlined />} size="small" style={{ color: "white", fontSize: 14, marginBottom: 15 }}>
                <a href={state.link?.link_3?.url} target="_blank">
                  <span style={{ color: "white", fontSize: 16, marginLeft: 8 }}>{state.link?.link_3?.name}</span>
                </a>
              </Button>
              <br />

            </Col>
            <Col xl={8} lg={6} md={12} sm={12} xs={24} style={{ padding: !isMobile ? "30px 0px 30px 30px" : 10 }}>
              <p style={{ color: "white", fontSize: 30, fontFamily: "'Anton', sans-serif", fontStyle: "italic", marginBottom: 0, }}>SUPPORT <span style={{ color: "rgb(255 0 0)", fontSize: 35 }}>CSKH</span></p>
              <p style={{ color: "white", textAlign: "justify" }}>Liên hệ ngay bộ phận CSKH nếu cần sự hỗ trợ. Chúng tôi sẽ hỗ trợ và giải đáp yêu cầu của bạn sớm nhất có thể!</p>
              <Button type='dashed' icon={<LinkOutlined />}
                onClick={() => {
                  window.open(`https://www.tiktok.com/@${state.contact?.telegram}`, "_blank")
                }}
                className="color1" style={{ marginBottom: 10, marginRight: 10, width: "100%" }}>
                Tiktok: {state.contact?.telegram}
              </Button>
              <Button type='dashed' icon={<LinkOutlined />}
                onClick={() => {
                  window.open(`https://zalo.me/${state.contact?.zalo}`, "_blank")
                }}
                className="color1" style={{ marginBottom: 10, marginRight: "2%", width: "49%" }}>
                Zalo: {state.contact?.zalo}
              </Button>
              <Button
                onClick={() => {
                  window.open(`tel:${state.contact?.phone}`)
                }}
                type='dashed' icon={<LinkOutlined />} className="color2" style={{ marginBottom: 10, width: "49%" }}>
                Tel:{state.contact?.phone}
              </Button>

            </Col>
          </Row>
        </div>

        <Seeding />

      </Footer>
    </Layout>
  )
}

export default Main_App;