
import { AlertOutlined, AppleOutlined, CarryOutOutlined, GiftOutlined, HistoryOutlined, IdcardOutlined, PartitionOutlined, RadarChartOutlined, SettingOutlined, ShoppingOutlined, SkinOutlined, TagOutlined, TrophyOutlined, UserOutlined } from '@ant-design/icons';
import { Col, Menu, message, Row, Badge } from 'antd';
import React, { useEffect, useState } from 'react';
import { isMobile } from 'react-device-detect';
import { useSelector, useDispatch } from 'react-redux';
import { Link } from "react-router-dom";
import { get_pending } from '../../modules/setting/actions';
import Settings from "../Admin/Settings";
import Router from "./Router";

const App = (props) => {
    const { match, history, location } = props
    const [current, setCurrent] = useState("/admin/me");
    const _me = useSelector(({ user }) => user.me);
    const _pending = useSelector(({ setting }) => setting.pending);

    const _user = _me.user
    const dispatch = useDispatch()
    useEffect(() => {
        if (location.pathname == "/admin/") setCurrent("/admin/me")
        else {
            setCurrent(location.pathname)
        }
    }, [location.pathname])
    useEffect(() => {
        if (_user && _user?._id && _user?.role !== 0) {
            message.warning("Bạn không có quyển quản lý website")
            history.push("/")
        }
    }, [_user])
    useEffect(()=>{
       dispatch( get_pending({}))
    },[])
    const [state, setState] = useState({
        isVisibleSetting: false
    })
    const items = [
        {
            label:
                <Link to="/admin/summarys">
                    <span style={{ color: isMobile ? "white" : "black", fontSize: 14, marginLeft: 8 }}>Tổng quan</span>
                </Link>,
            key: '/admin/summarys',
            icon: <AppleOutlined />,
        },
        {
            label:
                <Link to="/admin/users">
                    <span style={{ color: isMobile ? "white" : "black", fontSize: 14, marginLeft: 8 }}>Người dùng</span>
                </Link>,
            key: '/admin/users',
            icon: <UserOutlined />,
        },
        {
            label: <Link to="/admin/categorys">
                <span style={{ color: isMobile ? "white" : "black", fontSize: 14, marginLeft: 8 }}>Danh mục</span>
            </Link>,
            key: '/admin/categorys',
            icon: <PartitionOutlined />,
        },
        {
            label: <Link to="/admin/accounts" >
                <span style={{ color: isMobile ? "white" : "black", fontSize: 14, marginLeft: 8 }}>Tài khoản Game</span>
            </Link>,
            key: '/admin/accounts',
            icon: <IdcardOutlined />,
            style: { borderTop: "1px solid rgb(204 204 204 / 33%)" }
        },
        {
            label: <Link to="/admin/accounts_order">
                <span style={{ color: isMobile ? "white" : "black", fontSize: 14, marginLeft: 8 }}>Đơn tài khoản game</span>
            </Link>,
            key: '/admin/accounts_order',
            icon: <ShoppingOutlined />,
        },
        {
            label: <Link to="/admin/items">
                <span style={{ color: isMobile ? "white" : "black", fontSize: 14, marginLeft: 8 }}>Vật phẩm / Skin</span>
            </Link>,
            key: '/admin/items',
            icon: <SkinOutlined />,
            style: { borderTop: "1px solid rgb(204 204 204 / 33%)" }

        },
        {
            label: <Link to="/admin/item_prices">
                <span style={{ color: isMobile ? "white" : "black", fontSize: 14, marginLeft: 8 }}>Phân loại giá</span>
            </Link>,
            key: '/admin/item_prices',
            icon: <TagOutlined />,
        },
        {
            label: 
                
                    <Link to="/admin/items_order">
                       
                        <span style={{ color: isMobile ? "white" : "black", fontSize: 14, marginLeft: 8 }}>Đơn Vật phẩm / Skin</span>
                        {_pending?.data?.item && _pending?.data?.item.length ?
                           <Badge size="default" count={_pending?.data?.item[0].pending} style={{right: "-15px", top:"-2px"}}>
                           </Badge>: null
                        }
                     
                    </Link>   
               ,
            key: '/admin/items_order',
            icon: <ShoppingOutlined />,
        },
        {
            label: <Link to="/admin/wheels">
                <span style={{ color: isMobile ? "white" : "black", fontSize: 14, marginLeft: 8 }}>Vòng quay</span>
            </Link>,
            key: '/admin/wheels',
            icon: <RadarChartOutlined />,
            style: { borderTop: "1px solid rgb(204 204 204 / 33%)" }
        },
        {
            label: <Link to="/admin/wheels_history">
                <span style={{ color: isMobile ? "white" : "black", fontSize: 14, marginLeft: 8 }}>Lịch sử vòng quay</span>
            </Link>,
            key: '/admin/wheels_history',
            icon: <HistoryOutlined />,

        },
        // {
        //     label: <Link to="/admin/withdraws">
        //         <span style={{ color: isMobile ? "white" : "black", fontSize: 14, marginLeft: 8 }}>Đơn rút tiền</span>
        //     </Link>,
        //     key: '/admin/withdraws',
        //     icon: <AlertOutlined />,
        //     style: { borderTop: "1px solid rgb(204 204 204 / 33%)" }
        // },
        {
            label: <Link to="/admin/wildcores">
                <span style={{ color: isMobile ? "white" : "black", fontSize: 14, marginLeft: 8 }}>Đơn rút WildCore</span>
                {_pending?.data?.wildcore && _pending?.data?.wildcore.length ?
                           <Badge size="default" count={_pending?.data?.wildcore[0].pending} style={{right: "-15px", top:"-2px"}}>
                           </Badge>: null
                        }
            </Link>,
            key: '/admin/wildcores',
            icon: <CarryOutOutlined />,
            style: { borderTop: "1px solid rgb(204 204 204 / 33%)" }
        },
        {
            label: <Link to="/admin/boostings">
                <span style={{ color: isMobile ? "white" : "black", fontSize: 14, marginLeft: 8 }}>Đơn cày thuê</span>
                {_pending?.data?.boosting && _pending?.data?.boosting.length ?
                           <Badge size="default" count={_pending?.data?.boosting[0].pending} style={{right: "-15px", top:"-2px"}}>
                           </Badge>: null
                        }
            </Link>,
            key: '/admin/boostings',
            icon: <TrophyOutlined />,
        },
        {
            label: <Link to="/admin/discounts">
                <span style={{ color: isMobile ? "white" : "black", fontSize: 14, marginLeft: 8 }}>Mã giảm giá</span>
            </Link>,
            key: '/admin/discounts',
            icon: <GiftOutlined />,
        },
        {
            label: <Link to="/admin/deposits">
                <span style={{ color: isMobile ? "white" : "black", fontSize: 14, marginLeft: 8 }}>Lịch sử nạp tiền</span>
            </Link>,
            key: '/admin/deposits',
            icon: <HistoryOutlined />,
        },
        {
            label: <Link to="#" onClick={() => {
                setState({ ...state, isVisibleSetting: true })
            }} >
                <span style={{ color: isMobile ? "white" : "black", fontSize: 14, marginLeft: 8 }}>Cài đặt</span>
            </Link>,
            key: '/admin/settings',
            icon: <SettingOutlined />,
            // style: {display: _user?.username == "admin" ? "block": "none"}
        },

    ];

    return (
        <>
            <Row style={{ width: "100%" }} className="cpanel">
                <Col xl={4} lg={4} md={4} sm={4} xs={3} style={{ background: "white", borderRight: "1px solid rgb(241 241 241)" }}>
                    <Menu
                        selectedKeys={[current]}
                        mode="inline"
                        inlineCollapsed={isMobile}
                        className="menu_cpanel"
                        style={{
                            minHeight: 'calc(100vh - 180px)'
                            // height:"auto",
                            // background:"white"
                        }}
                        items={items}
                    />
                </Col>
                <Col Col xl={20} lg={20} md={20} sm={20} xs={21}>
                    <div style={{ background: "white", padding: 10, width: "100%", height: "100%" }}>
                        <Router match={match} location={location} history={history} />
                    </div>
                </Col>
            </Row>
            <Settings state={state} setState={setState} />
        </>

    )

};

export default App;
