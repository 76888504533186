import * as Types from "./constants";

const initState = {
  categoryList: {
    msg: null,
    status: false,
    isLoading: false,
    data: [],
    total: 0,
    last_page: 0,
    current_page: 0,
    per_page: 20,
  },
  createCategory: {
    isLoading: false,
    status: false,
    msg: null,
    data: {}
  },
  updateCategory: {
    isLoading: false,
    status: false,
    msg: null,
    data: {}
  },
  deleteCategory: {
    isLoading: false,
    status: false,
    msg: null,
    data: {}
  },
};
export default function categoryReducer(state = initState, action = {}) {
  switch (action.type) {
    case Types.CREATE_CATEGORY:
      return {
        ...state,
        createCategory: {
          ...state.createCategory,
          isLoading: true,
          status: false,
          msg: null,
        }
      };
    case Types.CREATE_CATEGORY_SUCCESS:
      if(action.payload.status == true)
        state.categoryList.data = [action.payload.data].concat(state.categoryList.data)

      return {
        ...state,
        categoryList:{
          ...state.categoryList,
          data: state.categoryList.data
        },
        createCategory: {
          ...state.createCategory,
          ...action.payload,
          isLoading: false,
        }
      };
    case Types.CREATE_CATEGORY_ERROR:
      return {
        ...state,
        createCategory: {
          ...state.createCategory,
          ...action.payload,
          isLoading: false,
          status: false
        }
      };
    //////////
    case Types.UPDATE_CATEGORY:
      return {
        ...state,
        updateCategory: {
          ...state.updateCategory,
          isLoading: true,
          status: false,
          msg: null,
        }
      };
    case Types.UPDATE_CATEGORY_SUCCESS:
      if (action.payload.status == true) {
        let objIndex = state.categoryList.data.findIndex((obj => obj._id == action.payload.data._id));
        state.categoryList.data[objIndex] = action.payload.data
      }
      return {
        ...state,
        categoryList: {
          ...state.categoryList,
          data: [...state.categoryList.data]
        },
        updateCategory: {
          ...state.updateCategory,
          ...action.payload,
          isLoading: false,
        }
      };
    case Types.UPDATE_CATEGORY_ERROR:
      return {
        ...state,
        updateCategory: {
          ...state.updateCategory,
          ...action.payload,
          isLoading: false,
          status: false
        }
      };
    /////////
    case Types.GET_CATEGORY:

      return {
        ...state,
        categoryList: {
          ...state.categoryList,
          isLoading: true,
          status: false,
          msg: null,
        }
      };
    case Types.GET_CATEGORY_SUCCESS:
    
      return {
        ...state,
        categoryList: {
          ...state.categoryList,
          ...action.payload.data,
          isLoading: false,
          status: action.payload.status,
          msg: action.payload.msg
        }
      };
    case Types.GET_CATEGORY_ERROR:
     
      return {
        ...state,
        categoryList: {
          ...state.categoryList,
          status: false,
          isLoading: false,
          msg: action.payload.msg
        }
      };
    /////////
    case Types.DELETE_CATEGORY:
      return {
        ...state,
        deleteCategory: {
          ...state.deleteCategory,
          status: false,
          isLoading: true,
          msg: null,
        }
      };
    case Types.DELETE_CATEGORY_SUCCESS:
      if (action.payload.status) {
        state.categoryList.data = state.categoryList.data.filter((item) => item._id !== action.payload.data._id)
      }
      return {
        ...state,
        categoryList: {
          ...state.categoryList,
          data: state.categoryList.data
        },
        deleteCategory: {
          ...state.deleteCategory,
          isLoading: false,
          ...action.payload,
        }
      };
    case Types.DELETE_CATEGORY_ERROR:
      return {
        ...state,
        deleteCategory: {
          ...state.deleteCategory,
          ...action.payload,
          status: false,
          isLoading: false,
        }
      };

    /////////
    case Types.RESET_CATEGORY:
      let newState = {}
      if (action.payload.remove[0] == "all")
        newState = initState
      else
        for (let [key, value] of Object.entries(state)) {
          if (action.payload.remove.includes(key))
            newState[key] = { ...initState[key] }
          else newState[key] = value
        };
      return newState

    default:
      return state;
  }
}
