import { httpClient as request } from "../../utils/Api";
// router.post("/account/add_account", auth, accounts.add_account);
// router.post('/account/change_account_info', auth_valid, accounts.change_account_info)
// router.post("/account/update_account", auth, accounts.update_account);
// router.post("/account/add_account_random", auth, accounts.add_account_random);
// router.post("/account/delete_account", auth, accounts.delete_account);
// router.post("/account/get_accounts", auth, accounts.get_accounts);
// router.post("/account/get_accounts_by_admin", auth, accounts.get_accounts_by_admin);
// router.post("/account/get_accounts_owner", auth, accounts.get_accounts_owner);
// router.post("/account/buy_account", auth, accounts.buy_account);

export const add_account = payload => {
  return request.post("/account/add_account", payload)
};

export const change_account_info = payload => {
  return request.post("/account/change_account_info", payload)
};

export const update_account = payload => {
  return payload.request_type == 1 ?   request.post("/account/change_owner", payload) : request.post("/account/update_account", payload)
};

export const delete_account = payload => {
  return request.post("/account/delete_account", payload)
};

export const add_account_random = payload => {
  return request.post("/account/add_account_random", payload)
};

export const get_accounts = payload => {
  return request.post("/account/get_accounts", payload)
};

export const get_accounts_by_admin = payload => {
  return request.post("/account/get_accounts_by_admin", payload)
};

export const get_accounts_owner = payload => {
  return request.post("/account/get_accounts_owner", payload)
};

export const buy_account = payload => {
  return request.post("/account/buy_account", payload)
};

export const get_historys = payload => {
  return request.post("/account/get_historys", payload)
};

export const delete_history = payload => {
  return request.post("/account/delete_history", payload)
};

export const update_history = payload => {
  return request.post("/account/update_history", payload)
};

export const get_account_price = payload => {
  return request.post("/account/get_account_price", payload)
};


export const get_account_info = payload => {
  return request.post("/account/get_account_info", payload)
};