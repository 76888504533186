import { httpClient as request } from "../../utils/Api";
// router.post("/boost/buy_boost", auth, boosts.buy_boost);
// router.post("/boost/update_boost", auth, boosts.update_boost);
// router.post("/boost/get_boost", auth, boosts.get_boost);
// router.post("/boost/apply_boost", auth, boosts.apply_boost);
export const buy_boost = payload => {
  return request.post("/boost/buy_boost", payload)
};

export const update_boost = payload => {
  return request.post("/boost/update_boost", payload)
};

export const get_boost = payload => {
  return request.post("/boost/get_boost", payload)
};

export const apply_boost = payload => {
  return request.post("/boost/apply_boost", payload)
};


export const get_boost_price = payload => {
  return request.post("/boost/get_boost_price", payload)
};