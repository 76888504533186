import { put, call, takeLatest } from "redux-saga/effects";
import * as types from "./constants";
import * as services from "./services";
// router.post("/setting/set", auth, settings.setting_set);
// router.post("/setting/get", settings.setting_get);

export function* setting_set(action) {
  try {
    const { data, status } = yield call(services.setting_set, action.payload);
    yield put({
      type: types.SETTING_SET_SUCCESS,
      payload: data,
    });
  } catch (error) {
    if (error.response) {
      const { data, status } = error.response
      if (status == 401)
        yield put({
          type: "user/LOG_OUT_SUCCESS",
          payload: {
            msg: data.msg,
            status: false
          },
        });
      else yield put({
        type: types.SETTING_SET_ERROR,
        payload: {msg:"Không thể kết nối server", status:false},
      });
    }
    else
      yield put({
        type: types.SETTING_SET_ERROR,
        payload: {
          msg: `Không thể kết nối server`,
          status: false
        },
      });
  }
}
export function* setting_get(action) {
  try {
    const { data, status } = yield call(services.setting_get, action.payload);
    yield put({
      type: types.SETTING_GET_SUCCESS,
      payload: data,
    });
  } catch (error) {
    if (error.response) {
      const { data, status } = error.response
      if (status == 401)
        yield put({
          type: "user/LOG_OUT_SUCCESS",
          payload: {
            msg: data.msg,
            status: false
          },
        });
      else yield put({
        type: types.SETTING_GET_ERROR,
        payload: {msg:"Không thể kết nối server", status:false},
      });
    }
    else
      yield put({
        type: types.SETTING_GET_ERROR,
        payload: {
          msg: `Không thể kết nối server`,
          status: false
        },
      });
  }
}
export function* setting_summarys(action) {
  try {
    const { data, status } = yield call(services.setting_summarys, action.payload);
    yield put({
      type: types.SETTING_SUMMARYS_SUCCESS,
      payload: data,
    });
  } catch (error) {
    if (error.response) {
      const { data, status } = error.response
      if (status == 401)
        yield put({
          type: "user/LOG_OUT_SUCCESS",
          payload: {
            msg: data.msg,
            status: false
          },
        });
      else yield put({
        type: types.SETTING_SUMMARYS_ERROR,
        payload: {msg:"Không thể kết nối server", status:false},
      });
    }
    else
      yield put({
        type: types.SETTING_SUMMARYS_ERROR,
        payload: {
          msg: `Không thể kết nối server`,
          status: false
        },
      });
  }
}
export function* get_pending(action) {
  try {
    const { data, status } = yield call(services.get_pending, action.payload);
    yield put({
      type: types.GET_PENDING_SUCCESS,
      payload: data,
    });
  } catch (error) {
    if (error.response) {
      const { data, status } = error.response
      if (status == 401)
        yield put({
          type: "user/LOG_OUT_SUCCESS",
          payload: {
            msg: data.msg,
            status: false
          },
        });
      else yield put({
        type: types.GET_PENDING_ERROR,
        payload: {msg:"Không thể kết nối server", status:false},
      });
    }
    else
      yield put({
        type: types.GET_PENDING_ERROR,
        payload: {
          msg: `Không thể kết nối server`,
          status: false
        },
      });
  }
}
export default function* boostSaga() {
  yield takeLatest(types.SETTING_SET, setting_set);
  yield takeLatest(types.SETTING_GET, setting_get);
  yield takeLatest(types.GET_PENDING, get_pending);
  yield takeLatest(types.SETTING_SUMMARYS, setting_summarys);


}
