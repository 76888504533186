import React from "react";
import { Route, Switch } from "react-router-dom";
import asyncComponent from "../../utils/asyncComponent";
const App = ({ match, history, location }) => {

    return (
        <Switch>
            <Route path={`/admin/summarys`} component={asyncComponent(() => import('./Summarys'))} />

            <Route path={`/admin/categorys`} component={asyncComponent(() => import('./Categorys'))} />
            <Route path={`/admin/accounts`} component={asyncComponent(() => import('./Accounts'))} />
            <Route path={`/admin/accounts_order`} component={asyncComponent(() => import('./Accounts_History'))} />

            <Route path={`/admin/items`} component={asyncComponent(() => import('./Items'))} />
            <Route path={`/admin/item_prices`} component={asyncComponent(() => import('./Item_Prices'))} />

            <Route path={`/admin/items_order`} component={asyncComponent(() => import('./Items_History'))} />

            <Route path={`/admin/wheels`} component={asyncComponent(() => import('./Wheels'))} />
            <Route path={`/admin/wheels_history`} component={asyncComponent(() => import('./Wheels_History'))} />

            <Route path={`/admin/boostings`} component={asyncComponent(() => import('./Boostings'))} />
            <Route path={`/admin/discounts`} component={asyncComponent(() => import('./Discount'))} />

            <Route path={`/admin/deposits`} component={asyncComponent(() => import('./Deposits'))} />
            {/* <Route path={`/admin/withdraws`} component={asyncComponent(() => import('./Withdraws'))} /> */}
            <Route path={`/admin/wildcores`} component={asyncComponent(() => import('./WildCores'))} />
            <Route path={`/admin/settings`} component={asyncComponent(() => import('./Settings'))} />

            <Route path={`/admin/`} component={asyncComponent(() => import('./Users'))} />
        </Switch>

    );
}



export default App;
