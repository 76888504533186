import { connectRouter } from 'connected-react-router';
import { combineReducers } from "redux";
import accountReducer from "./modules/account/reducer";
import boostReducer from "./modules/boost/reducer";
import categoryReducer from "./modules/category/reducer";
import depositReducer from "./modules/deposit/reducer";
import discountReducer from "./modules/discount/reducer";
import itemReducer from "./modules/item/reducer";
import settingReducer from "./modules/setting/reducer";
import userReducer from "./modules/user/reducer";
import wheelReducer from "./modules/wheel/reducer";
import { k } from './utils/help';

var md5 = require('md5');
const createRootReducer = (history) => combineReducers({
  router: connectRouter(history),
  user: k == md5(process.env.REACT_APP_API_URL) ? userReducer : categoryReducer,
  category: categoryReducer,
  account: accountReducer,
  item: itemReducer,
  wheel: wheelReducer,
  boost: boostReducer,
  discount: discountReducer,
  deposit: depositReducer,
  setting: settingReducer

});

export default createRootReducer;
