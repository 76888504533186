import { FireOutlined, LogoutOutlined } from "@ant-design/icons";
import { Avatar, Button, Card, Input, message, Modal, Pagination, Popconfirm, Select, Space, Table, Tooltip } from "antd";
import moment from "moment";
import 'moment/locale/vi';
import { useEffect, useState } from "react";
import { isMobile } from 'react-device-detect';
import { useDispatch, useSelector } from "react-redux";
import { useDebounce } from 'use-debounce';
import { me, register_ctv, reset_user } from "../../modules/user/actions";
import { curencyFormat } from "../../utils/help";
// import BoostView from "./BoostView";

import { convertFromRaw, EditorState } from 'draft-js';
import SweetAlert from "react-bootstrap-sweetalert";
import { useHistory } from "react-router-dom";
import { apply_boost, get_boost, reset_boost } from "../../modules/boost/actions";
import { get_rank_name } from "../../utils/help";
import { Editor } from 'react-draft-wysiwyg';

const { Option } = Select;
const { TextArea } = Input;


const Apply = (props) => {
    const dispatch = useDispatch();
    const _user = useSelector(({ user }) => user.me.user);
    const _boostList = useSelector(({ boost }) => boost.boostList);
    const _applyBoost = useSelector(({ boost }) => boost.applyBoost);
    const _settingList = useSelector(({ setting }) => setting.settingList);
    const _registerCtv = useSelector(({ user }) => user.registerCtv);
    const history = useHistory()
    let initialState = {
        page: 0,
        select: null,
        search: null,
        hasSearch: false,
        boost_profit: 80,
        isVisibleAlert: false,
        isVisibleAlert2: false,

        ctv_fee: 0,
        register: false,
        popup: {
            boost: {
                content: EditorState.createEmpty(),
                status: false
            }
        }
    }
    const [state, setState] = useState(initialState)
    const [search] = useDebounce(state.search, 1000);
    const [openPopup, setOpenPopup] = useState(false)

    const handleGetBoost = async () => {
        dispatch(get_boost({
            request_type: 3,
            receiver: state.receiver,
            type: state.type,
            search: state.search,
            page: state.page
        }))
    }
    const changePage = (page) => {
        setState({ ...state, page: page })
        dispatch(get_boost({
            request_type: 3,
            receiver: state.receiver,
            type: state.type,
            search: state.search,
            page: page - 1,
        }))
    }

    useEffect(() => {
        if (state.search || state.hasSearch) {
            setState({ ...state, hasSearch: true })
            handleGetBoost()
        }
    }, [search])


    useEffect(() => {
        window.scrollTo(0, 0)
        handleGetBoost()
        //console.log(_user)
        if (!_user  || !_user._id) {
            message.warning("Vui lòng đăng nhập")
            history.push("/signin")
        }
        return () => {
            dispatch(reset_boost({ remove: ["all"] }))
            dispatch(reset_user({ remove: ["registerCtv"] }))
        };
    }, [])

    const handleShowModal = (record) => {
        if (_user.role !== 1)
            return message.warning("Vui lòng đăng ký CTV cày thuê trước khi nhận đơn")
        if (record) {
            dispatch(apply_boost({
                _id: record._id
            }))
        }

    }

    useEffect(() => {
        if (!_boostList.status && _boostList.msg !== null) message.warning(_boostList.msg)
    }, [_boostList])

    useEffect(() => {
        if (_settingList.status) {
            let boost_profit = _settingList.data.length && _settingList.data.find(item => item.key == "boost_profit")
            let ctv_fee = _settingList.data.length && _settingList.data.find(item => item.key == "ctv_fee")
            let popup = _settingList.data.length && _settingList.data.find(item => item.key == "popup")
            let home = {
                content: popup.value.home.content ? EditorState.createWithContent(convertFromRaw(JSON.parse(popup.value.home.content))) : EditorState.createEmpty(),
                status: popup.value.home.status
            }
            let boost = {
                content: popup.value.boost.content ? EditorState.createWithContent(convertFromRaw(JSON.parse(popup.value.boost.content))) : EditorState.createEmpty(),
                status: popup.value.boost.status
            }
            popup = { home, boost }
            setState({
                ...state,
                boost_profit: boost_profit.value, ctv_fee: ctv_fee.value, popup
            })
        }
        else if (_settingList.status == false && _settingList.msg !== null) {
            message.warning("Không tìm thấy cấu hình server")
        }
    }, [_settingList])
    useEffect(() => {
        if(state.popup?.boost?.status)
        {
         if (localStorage.tscx_popup_boost2) {
             if ((localStorage.tscx_popup_boost2 - Date.now()) / (1000 * 60 * 60 * 24 * 2) >= 1) {
 
                 localStorage.tscx_popup_boost2 = Date.now();
                 setTimeout(() => {
                     setOpenPopup(true)
                 }, 1000);
             }
         }
         else {
             localStorage.tscx_popup_boost2 = Date.now();
             setTimeout(() => {
                 setOpenPopup(true)
 
             }, 1000);
         }
        }
     }, [state.popup])
    useEffect(() => {
        if (_registerCtv.status) {
            message.success(_registerCtv.msg)
            dispatch(me())
        }
        else if (_registerCtv.status == false && _registerCtv.msg !== null) {
            message.warning(_registerCtv.msg)
        }
    }, [_registerCtv])
    useEffect(() => {
        if (_applyBoost.status) {
            setState({ ...state, isVisibleAlert2: true })
        }
        else if (_applyBoost.status == false && _applyBoost.msg !== null) {
            message.warning(_applyBoost.msg)
        }
    }, [_applyBoost])

    const handleCTVRegister = () => {
        if (_user.balance < state.ctv_fee)
            return message.warning("Tài khoản của bạn không đủ tiền để trả phí đăng kí. Vui lòng nạp thêm!")
        setState({ ...state, isVisibleAlert: false })
        dispatch(register_ctv({}))
    }
    let columns = [
        {
            title: 'Thông tin',
            key: 'id',
            dataIndex: 'id',
            width: 150,

            render: (text, record) => {
                return (<Space >

                    <Avatar size="default" src={"/assets/images/rank/grandmaster.png"} />
                    <div style={{ alignItems: "center", marginLeft: 5 }}>

                        <span>Mã đơn: {text}</span>

                        <br />
                        <span>Ngày đặt: {moment(record.createdAt).format("DD/MM/YYYY")}</span>
                    </div>


                </Space>)
            }
        },

        {
            title: "Rank",
            key: 'want_rank',
            dataIndex: 'want_rank',
            width: 170,
            render: (text, record) => <div>


                <span>
                    Rank: {`${get_rank_name(record.current_rank)} -> ${get_rank_name(record.want_rank)}`}
                </span><br />
                <span>
                    Loại: {record.rank_type == 0 ? "Đơn/Đôi" : "Flex/Linh hoạt"}
                </span>

            </div>
        },
        {
            title: "Người đặt",
            key: 'owner',
            dataIndex: 'owner',
            width: 100,

            render: (text, record) => <>
                <span>{text?.username ? `${text?.username.substr(0, 4)}*****` : "Không rõ"}</span><br />
                <span>Phone: {text?.phone ? `${text?.phone.substr(0, 5)}*****` : "Đã ẩn"}</span><br />
            </>
        },
        {
            title: "Yêu cầu hoàn thành",
            key: 'finish_date',
            dataIndex: 'finish_date',
            width: 100,
            align: "right",
            render: (text, record) => text ? `${moment(text).diff(moment(record.createdAt), "day")} Ngày` : "Chưa rõ"
        },
        {
            title: "Nhận được",
            key: 'price',
            dataIndex: 'price',
            width: 140,
            align: "right",
            render: (text, record) => <>
                <span>{!isNaN(text) ? curencyFormat(text / 100 * state.boost_profit) : text}</span>
            </>
        },

        {
            title: 'Menu',
            width: 100,
            align: "center",
            // fixed: 'right',
            render: (text, record) => (

                <Space>
                    <Popconfirm title="Chắc chắn nhận đơn này?" onConfirm={() => handleShowModal(record)} okText="Yes" cancelText="No">
                        <Button
                            type="primary"
                            size="small"
                            icon={<FireOutlined />}
                        >Nhận đơn này</Button>
                    </Popconfirm>

                </Space >

            ),
        },
    ];
    let columns_mobile = [

        {
            title: "Rank",
            key: 'want_rank',
            dataIndex: 'want_rank',
            width: 200,
            render: (text, record) => <div>
                <span>
                    Rank: {`${get_rank_name(record.current_rank)} -> ${get_rank_name(record.want_rank)}`}
                </span><br />
                <span>
                    Loại: {record.rank_type == 0 ? "Đơn/Đôi" : "Flex/Linh hoạt"}
                </span>

            </div>
        },
        {
            title: "Nhận được",
            key: 'price',
            dataIndex: 'price',
            width: 100,
            align: "right",
            render: (text, record) => <>
                <span>{!isNaN(text) ? curencyFormat(text / 100 * state.boost_profit) : text}</span>
            </>
        },
        {
            title: 'Menu',
            width: 50,
            align: "center",
            // fixed: 'right',
            render: (text, record) => (

                <Space>
                    <Tooltip placement="bottom" title={"Nhận đơn này"}>
                        <Button
                            type="primary"
                            size="small"
                            icon={<FireOutlined />}

                            onClick={() => {
                                handleShowModal(record)
                            }}
                        ></Button>
                    </Tooltip>

                </Space >

            ),
        },
    ];
    const optionTable = {
        bordered: true,
        loading: _boostList.isLoading,
        size: 'small',
        showHeader: true,
        columns: isMobile ? columns_mobile : columns,
        dataSource: [..._boostList.data],
        pagination: { position: ['none', 'none'], defaultPageSize: _boostList.per_page },
        scroll: isMobile ? {} : { y: "calc(100vh - 300px)", x: 1000 },
        expandable: !isMobile ? {
            expandedRowRender: (record) => (
                <>
                    {

                        record.log.map((log) => <p
                            style={{
                                margin: 0,
                            }}
                        >
                            {log}
                        </p>)}
                </>
            ),
            rowExpandable: (record) => record?.log?.length !== 0 && !isMobile,
        } : null
    };


    return (
        <Card
            bodyStyle={{ padding: 10 }}
            headStyle={{ padding: 10 }}
            size="small"
            title={"Danh sách đơn cày thuê"}
            extra={
                <div>
                    <Input type="search"
                        style={{ width: isMobile ? "100%" : "200px", marginBottom: 10, marginRight: isMobile ? null : 10 }}
                        placeholder="Tìm theo mã đơn hàng"
                        onChange={(e) => setState({ ...state, search: e.target.value })}
                        value={state.search}
                    ></Input>
                    <Button type="primary" style={{ width: isMobile ? "100%" : "150px", marginLeft: !isMobile ? 10 : 0, marginTop: 10 }}
                        onClick={() => {
                            if(_user == {} || typeof _user?.id  !== "undefined" || !_user?._id)
                                return message.warning("Vui lòng đăng nhập trước")
                            if (_user.role == 1) return message.warning("Bạn đã đăng ký CTV trước đây!")
                            setState({ ...state, isVisibleAlert: true })
                        }}
                        className="color1">
                        Đăng Ký CTV
                    </Button>
                </div>

            }
            style={{ width: "100%", }}>

            <Table
                {...optionTable}
                rowKey={(record) => record._id}
            />
            <Space direction="horizontal" style={{ width: '100%', justifyContent: 'center' }}>
                <Pagination current={_boostList.current_page + 1} total={_boostList.total} pageSize={_boostList.per_page} onChange={changePage} style={{ paddingTop: 10 }} showSizeChanger={false} />
            </Space>
            <SweetAlert show={state.isVisibleAlert}
                warning
                showCancel
                confirmBtnText={"Đăng Ký"}
                cancelBtnText={"Thui!"}
                confirmBtnBsStyle="danger"
                cancelBtnBsStyle="default"
                title={`Thông báo!`}
                onConfirm={() => {
                    handleCTVRegister()
                }}

                onCancel={() => setState({ ...state, isVisibleAlert: false })}
            >
                <div>
                    <span>Bạn sẽ mất một khoản phí: {curencyFormat(state.ctv_fee)} để trở thành CTV của chúng tôi</span><br />
                    <span>Nếu đồng ý, vui lòng chọn nút đăng ký dưới đây</span>
                </div>
            </SweetAlert>
            <SweetAlert show={state.isVisibleAlert2}
                success
                showCancel={false}
                confirmBtnText={"OK. Đã rõ"}

                confirmBtnBsStyle="danger"
                cancelBtnBsStyle="default"
                title={`Thành công!`}
                onConfirm={() => {
                    history.push("/profile/boostings")
                }}

                onCancel={() => setState({ ...state, isVisibleAlert2: false })}
            >
                <div>
                    <span>Bạn đã đăng ký thành công đơn hàng {_applyBoost?.data?.id}</span><br />
                    <span>Bạn có thể lấy thông tin của khách hàng và trao đỗi rõ hơn về yêu cầu của khách</span>
                </div>
            </SweetAlert>
            <Modal title={`Thông báo`}
                    visible={openPopup}

                    destroyOnClose={true}
                    onCancel={() => setOpenPopup(false)}
                    width={isMobile ? "90%" : "500px"}
                    footer={<Space direction="horizontal" style={{ width: '100%', justifyContent: isMobile ? "center" : "right" }} className="gx-w-100">

                        <Button icon={<LogoutOutlined />} onClick={() => setOpenPopup(false)} type="primary" className='color2'>Đóng</Button>
                    </Space>}
                >
                    <Editor
                        toolbarHidden={true}
                        readOnly={true}
                        editorState={state.popup?.boost?.content}
                         />
                </Modal>
        </Card>


    )
}
export default Apply