import { httpClient as request } from "../../utils/Api";
// router.post("/category/create_category", auth, categorys.create_category);
// router.post("/category/update_category", auth, categorys.update_category);
// router.post("/category/delete_category", auth, categorys.delete_category);
// router.post("/category/get_categorys", auth, categorys.get_categorys);
export const create_category = payload => {
  return request.post("/category/create_category", payload)
};

export const update_category = payload => {
  return request.post("/category/update_category", payload)
};

export const delete_category = payload => {
  return request.post("/category/delete_category", payload)
};

export const get_categorys = payload => {

  return payload.dashboard ? request.post("/category/get_categorys", payload) : request.post("/category/get_categorys_user", payload)
};