import * as types from "./constants";
// router.post("/account/add_account", auth, accounts.add_account);
// router.post('/account/change_account_info', auth_valid, accounts.change_account_info)
// router.post("/account/update_account", auth, accounts.update_account);
// router.post("/account/add_account_random", auth, accounts.add_account_random);
// router.post("/account/delete_account", auth, accounts.delete_account);
// router.post("/account/get_accounts", auth, accounts.get_accounts);
// router.post("/account/get_accounts_by_admin", auth, accounts.get_accounts_by_admin);
// router.post("/account/get_accounts_owner", auth, accounts.get_accounts_owner);
// router.post("/account/buy_account", auth, accounts.buy_account);


export function add_account(payload) {
  return {
    type: types.ADD_ACCOUNT,
    payload,
  };
}
export function change_account_info(payload) {
  return {
    type: types.CHANGE_ACCOUNT_INFO,
    payload,
  };
}
export function update_account(payload) {
  return {
    type: types.UPDATE_ACCOUNT,
    payload,
  };
}
export function add_account_random(payload) {
  return {
    type: types.ADD_ACCOUNT_RANDOM,
    payload,
  };
}
export function delete_account(payload) {
  return {
    type: types.DELETE_ACCOUNT,
    payload,
  };
}
export function get_accounts(payload) {
  return {
    type: types.GET_ACCOUNT_BY_CATEGORY,
    payload,
  };
}
export function get_accounts_by_admin(payload) {
  return {
    type: types.GET_ACCOUNT_BY_ADMIN,
    payload,
  };
}
export function get_accounts_owner(payload) {
  return {
    type: types.GET_ACCOUNT_OWNER,
    payload,
  };
}
export function buy_account(payload) {
  return {
    type: types.BUY_ACCOUNT,
    payload,
  };
}
export function get_historys(payload) {
  return {
    type: types.GET_HISTORYS,
    payload,
  };
}
export function delete_history(payload) {
  return {
    type: types.DELETE_HISTORYS,
    payload,
  };
}
export function reset_account(payload) {
  return {
    type: types.RESET_ACCOUNT,
    payload,
  };
}
export function update_history(payload) {
  return {
    type: types.UPDATE_HISTORYS,
    payload,
  };
}

export function get_account_price(payload) {
  return {
    type: types.GET_ACCOUNT_PRICE,
    payload,
  };
}
export function get_account_info(payload) {
  return {
    type: types.GET_ACCOUNT_INFO,
    payload,
  };
}
