import { Button, Drawer, Radio, Space } from 'antd';
import React from 'react';
import { useSelector } from 'react-redux';
import {
  HomeOutlined
} from '@ant-design/icons';
import { Link } from 'react-router-dom';
const App = (props) => {
  const _me = useSelector(({ user }) => user.me);
  const user = _me.user

  return (
    <Drawer
      title="Menu"
      placement={'right'}
      width={"90%"}
      onClose={props.onClose}
      open={props.open}
      extra={
        <Space>
          <Button type='dashed' icon={<HomeOutlined />} size="small" style={{ color: "black", fontSize: 14, height:30}} onClick={props.onClose}>
              <Link to="/">
                <span style={{ color: "black", fontSize: 14, marginLeft: 8 }}>Skin Tốc Chiến</span>
              </Link>
            </Button>
        </Space>
      }
    >
      <div style={{ display: "flex", flexDirection: "column" }} className="mobile_menu" onClick={props.onClose}>
        {props.menu}
      </div>
    </Drawer>

  );
};

export default App;