// router.post("/boost/buy_boost", auth, boosts.buy_boost);
// router.post("/boost/update_boost", auth, boosts.update_boost);
// router.post("/boost/get_boost", auth, boosts.get_boost);
// router.post("/boost/apply_boost", auth, boosts.apply_boost);

export const BUY_BOOST = "boost/BUY_BOOST";
export const BUY_BOOST_SUCCESS = "boost/BUY_BOOST_SUCCESS";
export const BUY_BOOST_ERROR = "boost/BUY_BOOST_ERROR";

export const UPDATE_BOOST = "boost/UPDATE_BOOST";
export const UPDATE_BOOST_SUCCESS = "boost/UPDATE_BOOST_SUCCESS";
export const UPDATE_BOOST_ERROR = "boost/UPDATE_BOOST_ERROR";

export const GET_BOOST = "boost/GET_BOOST";
export const GET_BOOST_SUCCESS = "boost/GET_BOOST_SUCCESS";
export const GET_BOOST_ERROR = "boost/GET_BOOST_ERROR";

export const APPLY_BOOST = "boost/APPLY_BOOST";
export const APPLY_BOOST_SUCCESS = "boost/APPLY_BOOST_SUCCESS";
export const APPLY_BOOST_ERROR = "boost/APPLY_BOOST_ERROR";

export const RESET_BOOST = "boost/RESET_BOOST";
export const RESET_BOOST_SUCCESS = "boost/RESET_BOOST_SUCCESS";
export const RESET_BOOST_ERROR = "boost/RESET_BOOST_ERROR";

export const GET_BOOST_PRICE = "boost/GET_BOOST_PRICE";
export const GET_BOOST_PRICE_SUCCESS = "boost/GET_BOOST_PRICE_SUCCESS";
export const GET_BOOST_PRICE_ERROR = "boost/GET_BOOST_PRICE_ERROR";