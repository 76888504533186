// router.post("/deposit/add_deposit", auth, deposits.add_deposit);
// router.post('/deposit/change_deposit_info', auth_valid, deposits.change_deposit_info)
// router.post("/deposit/update_deposit", auth, deposits.update_deposit);
// router.post("/deposit/add_deposit_random", auth, deposits.add_deposit_random);
// router.post("/deposit/delete_deposit", auth, deposits.delete_deposit);
// router.post("/deposit/get_deposits_by_category", auth, deposits.get_deposits_by_category);
// router.post("/deposit/get_deposits_by_admin", auth, deposits.get_deposits_by_admin);
// router.post("/deposit/get_deposits_owner", auth, deposits.get_deposits_owner);
// router.post("/deposit/buy_deposit", auth, deposits.buy_deposit);


export const CREATE_DEPOSIT = "deposit/CREATE_DEPOSIT";
export const CREATE_DEPOSIT_SUCCESS = "deposit/CREATE_DEPOSIT_SUCCESS";
export const CREATE_DEPOSIT_ERROR = "deposit/CREATE_DEPOSIT_ERROR";

export const UPDATE_DEPOSIT = "deposit/UPDATE_DEPOSIT";
export const UPDATE_DEPOSIT_SUCCESS = "deposit/UPDATE_DEPOSIT_SUCCESS";
export const UPDATE_DEPOSIT_ERROR = "deposit/UPDATE_DEPOSIT_ERROR";

export const DELETE_DEPOSIT = "deposit/DELETE_DEPOSIT";
export const DELETE_DEPOSIT_SUCCESS = "deposit/DELETE_DEPOSIT_SUCCESS";
export const DELETE_DEPOSIT_ERROR = "deposit/DELETE_DEPOSIT_ERROR";

export const GET_DEPOSIT = "deposit/GET_DEPOSIT";
export const GET_DEPOSIT_SUCCESS = "deposit/GET_DEPOSIT_SUCCESS";
export const GET_DEPOSIT_ERROR = "deposit/GET_DEPOSIT_ERROR";

export const RESET_DEPOSIT = "deposit/RESET_DEPOSIT";
export const RESET_DEPOSIT_SUCCESS = "deposit/RESET_DEPOSIT_SUCCESS";
export const RESET_DEPOSIT_ERROR = "deposit/RESET_DEPOSIT_ERROR";