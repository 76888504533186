import {
    SmileOutlined,
} from '@ant-design/icons';
import { notification } from 'antd';
import React, { useEffect, useState } from 'react';
import { genUsername, winLabel } from '../utils/help';
function randomIntFromInterval(min, max) { // min and max included 
    return Math.floor(Math.random() * (max - min + 1) + min)
}

const Seeding = (props) => {

    const [reset, setReset] = useState(0)
    const [historyData, setHistoryData] = useState([])
    let type = [`nhận được phần thưởng`, `mua`]
    const handleGenData = () => {
        let data = []
        for (let index = 0; index < 100; index++) {
            let username = genUsername();
            username = username.substring(0, 10) + "****"
            data.push({
                username: username,
                description: `${winLabel[Math.floor(Math.random() * winLabel.length)]}`
            })
        }
        setHistoryData(data)
    }
    useEffect(() => {
        handleGenData()
    }, [])
    useEffect(() => {
        if (historyData.length > 0) {
            let time = randomIntFromInterval(120, 420) * 1000
            setTimeout(() => {
                let item = historyData[Math.floor(Math.random() * historyData.length)]
                let { username, description } = item
                let typeMess = type[Math.floor(Math.random() * type.length)]
                notification.open({
                    icon: <SmileOutlined style={{ color: 'yellowgreen' }} />,
                    message: `${typeMess == "mua" ? "Người dùng":"Chúc mừng"} ${username}`,
                    description: `Đã ${typeMess}: ${description} ${typeMess == "mua" ? "":"tại vòng quay may mắn"}`,
                    placement: "bottomLeft"
                });
                setReset(reset + 1)
            }, time);
        }

    }, [reset])

    return (<></>)
};
export default Seeding;