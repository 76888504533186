import React from "react";
import { Route, Switch } from "react-router-dom";
import asyncComponent from "../../utils/asyncComponent";
import Boosting from "./Boostings";
const App = ({ match, history, location }) => {

    return (
        <Switch>
            <Route path={`/profile/deposit`}  component={asyncComponent(() => import('./Deposit'))} />
            <Route path={`/profile/wildcore`}   component={asyncComponent(() => import('./WildCore'))} />
            {/* <Route path={`/profile/withdraw`}   component={asyncComponent(() => import('./Withdraw'))} /> */}
            <Route path={`/profile/wheels`}   component={asyncComponent(() => import('./Wheels_History'))} />

            <Route path={`/profile/accounts`}   component={asyncComponent(() => import('./Accounts'))} />
            <Route path={`/profile/items`}   component={asyncComponent(() => import('./Items'))} />
            <Route path={`/profile/boostings`}  exact component={Boosting} />
            <Route path={`/profile/`}   component={asyncComponent(() => import('./Me'))} />
        </Switch>

    );
}



export default App;
