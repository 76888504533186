import { EyeInvisibleOutlined, EyeTwoTone, KeyOutlined, LogoutOutlined, NumberOutlined, SendOutlined, TagOutlined, UserOutlined } from "@ant-design/icons";
import { Badge, Button, Col, Collapse, DatePicker, Input, Row, Select, Space } from "antd";
import Modal from "antd/lib/modal/Modal";
import moment from "moment";
import 'moment/locale/vi';
import React from "react";
import "react-draft-wysiwyg/dist/react-draft-wysiwyg.css";

const { Option } = Select;
const CategoryView = (props) => {
   let state = props.state
   let isMobile = props.isMobile
   let update = props.update
   let setState = props.setState
   let select = state.select
   return (
      <Modal title={`Chỉnh sửa`}
         visible={state.isVisibleModal}
         style={{ top: 20 }}
         destroyOnClose={true}
         onCancel={() => setState({ ...state, isVisibleModal: false })}
         width={isMobile ? "90%" : "40%"}
         footer={<Space direction="horizontal" style={{ width: '100%', justifyContent: isMobile ? "center" : "right" }} className="gx-w-100">
            <Button
               className='color1'
               loading={update.isLoading}
               onClick={() => {
                  props.handleUpdate()
               }} type="primary" icon={<SendOutlined />}>{"Cập nhật"}</Button>
            <Button icon={<LogoutOutlined />} onClick={() => setState({ ...state, isVisibleModal: false })} type="primary" className='color2'>Thoát</Button>
         </Space>}
      >
         <Row >
            <Col xl={12} lg={12} md={24} sm={24} className={!isMobile ? "pdR" : "pdB"}>
               <p style={{ marginBottom: "4px" }}>CTV nhận ngày</p>
               <DatePicker disabled placeholder="Chọn ngày nhận" style={{ width: "100%" }} format={"DD/MM/YYYY"} value={select.received_date ? moment(select.received_date) : null} onChange={(value) => {
                  setState({ ...state, select: { ...select, received_date: value } })

               }} />
            </Col>
            <Col xl={12} lg={12} md={24} sm={24} className={!isMobile ? "pdR" : "pdB"}>
               <p style={{ marginBottom: "4px" }}>Dự kiến hoàn thành</p>
               <DatePicker disabled placeholder="Chọn ngày hoàn thành" style={{ width: "100%" }} format={"DD/MM/YYYY"} value={select.finish_date ? moment(select.finish_date) : null} onChange={(value) => {
                  setState({ ...state, select: { ...select, finish_date: value } })

               }} />
            </Col>
         </Row>
         <Row >
            <Col xl={12} lg={12} md={24} sm={24} xs={24}>
               <Input size="middle" placeholder="Mã đơn hàng" prefix={<NumberOutlined />}
                  value={select.id}
                  addonAfter="Mã đơn"
                  className={!isMobile ? "pdR" : "pdB"}
                  disabled
               />
            </Col>
            <Col xl={12} lg={12} md={24} sm={24} className={!isMobile ? "pdR" : "pdB"}>
               <Input size="middle" placeholder="Giá tiền" prefix={<TagOutlined />}
                  value={select.price}
                  addonAfter="Giá tiền"
                  onChange={(e) => setState({ ...state, select: { ...select, price: e.target.value } })}
                  disabled
               />
            </Col>
         </Row>

         <Row>
            <Col xl={12} lg={12} md={24} sm={24} xs={24}>
               <Input size="middle" placeholder="Username" prefix={<UserOutlined />}
                  value={select.username}
                  addonAfter="Username"
                  disabled={select.type == 3 ? true: false}
                  className={!isMobile ? "pdR" : "pdB"}
                  onChange={(e) => setState({ ...state, select: { ...select, username: e.target.value } })}

               />

            </Col>

            <Col xl={12} lg={12} md={24} sm={24} xs={24}>
               <Input.Password size="middle" placeholder="Password" prefix={<KeyOutlined />}
                  value={select.password}
                  addonAfter="Password"
                  disabled={select.type == 3 ? true: false}
                  className={!isMobile ? "pdR" : "pdB"}
                  iconRender={(visible) => (visible ? <EyeTwoTone /> : <EyeInvisibleOutlined />)}

                  onChange={(e) => setState({ ...state, select: { ...select, password: e.target.value } })}
               />
            </Col>
         </Row>
         <Row >
            <Col xl={12} lg={12} md={24} sm={24} className={!isMobile ? "pdR" : "pdB"}>
               <p style={{ marginBottom: "4px" }}>Loại rank</p>
               <Select

                  defaultValue={select.rank_type}
                  placeholder="Chọn loại rank"
                  disabled={select.type == 3 ? true: false}
                  value={select.rank_type}
                  style={{ width: "100%", }} onChange={(value) => setState({ ...state, select: { ...select, rank_type: value } })}
               >
                  <Option value={0}><Badge color={"orange"} size="20" style={{ marginRight: 10 }}></Badge>Đơn / Đôi</Option>
                  <Option value={1}><Badge color={"blue"} size="20" style={{ marginRight: 10 }}></Badge>Flex / Linh hoạt</Option>
               </Select>

            </Col>

            <Col xl={12} lg={12} md={24} sm={24} className={!isMobile ? "pdR" : "pdB"}>
               <p style={{ marginBottom: "4px" }}>Trạng thái đơn</p>
               <Select
                  defaultValue={select.type}
                  placeholder="Chọn trạng thái"
                  value={select.type}
                  disabled={select.type == 3 ? true: false}
                  style={{ width: "100%", }} onChange={(value) => setState({ ...state, select: { ...select, type: value } })}
               >
                  <Option value={0}><Badge color={"yellow"} size="20" style={{ marginRight: 10 }}></Badge>Đang chờ nhận đơn</Option>
                  <Option value={1}><Badge color={"blue"} size="20" style={{ marginRight: 10 }}></Badge>CTV đã nhận đơn</Option>
                  <Option value={2}><Badge color={"red"} size="20" style={{ marginRight: 10 }}></Badge>Huỷ đơn, hoàn tiền</Option>
                  <Option value={3}><Badge color={"green"} size="20" style={{ marginRight: 10 }}></Badge>Xác nhận hoàn thành</Option>


               </Select>
            </Col>
         </Row>


      </Modal>

   )
}
export default CategoryView