import 'babel-polyfill';
import React from 'react';
import ReactDOM from 'react-dom/client';
import './css/App.css';
import NextApp from './NextApp';
import { k } from './utils/help';
var md5 = require('md5');
class Doc extends React.PureComponent {
  render(){
    return(
      <div>
        {
        md5(process.env.REACT_APP_API_URL) == k ? <NextApp /> : <></>
       }
      </div>
    )
  }
}
const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(
  <Doc />
);

