import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Route, Switch, useHistory, useLocation, useRouteMatch, } from "react-router-dom";
import Main_App from "./Main_App";
import { httpClient } from "../utils/Api";
import { me, reset_user } from "../modules/user/actions";
import { ConfigProvider, message, Spin } from 'antd';
import { isMobile } from "react-device-detect";
import { setting_get } from "../modules/setting/actions";
const RestrictedRoute = ({ component: Component, location, user, ...rest }) =>
  <Route
    {...rest}
    render={props => {

      return <Component {...props} />
    }
    }
  />;
const App = (props) => {
  const dispatch = useDispatch();
  const _user = useSelector(({ user }) => user.user);
  const _me = useSelector(({ user }) => user.me);
  const history = useHistory();
  const match = useRouteMatch();
  const [isLoading, setIsLoading] = useState(true)
  useEffect(() => {
    if (_me.status == false && _me.msg !== null) {
      setIsLoading(false)
      localStorage.removeItem('skintc_token');
      httpClient.defaults.headers.common['Authorization'] = '';
      // message.warning("Hết phiên đăng nhập")
      history.push("/signin")
    }
    else if (_me.status)
      setIsLoading(false)

  }, [_me]);

  useEffect(() => {
    if (_user.status == true) {
      localStorage.setItem("skintc_token", _user.token)
      httpClient.defaults.headers.common['Authorization'] = 'Bearer ' + _user.token;
      history.push("/")
      message.success(_user.msg)
      setIsLoading(false)

    }
    else if (_user.status == false && _user.msg !== null) {
      localStorage.removeItem('skintc_token');
      httpClient.defaults.headers.common['Authorization'] = '';
      message.warning(_user.msg)
      setIsLoading(false)
    }

  }, [_user])

  useEffect(() => {
    let token = localStorage.getItem("skintc_token")
    if (token) {
      httpClient.defaults.headers.common['Authorization'] = 'Bearer ' + token;
      dispatch(me());
    }
    else setIsLoading(false)
    dispatch(setting_get({
      request_type: 1
    }))
    message.config({
      top: isMobile ? 5 : 40,
      duration: 2,
    });
    return () => {
      dispatch(reset_user({ remove: ["register", "user", "me"] }))
    }
  }, [])
  return (
    <div>
    
        <ConfigProvider>
        {isLoading ? <Spin className="center" /> :
          <Switch>
            <RestrictedRoute path={`${match.url}`} component={Main_App} />
          </Switch>
            }
        </ConfigProvider>
    

    </div>
  )
};

export default App;
