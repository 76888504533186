// router.post("/category/create_category", auth, categorys.create_category);
// router.post("/category/update_category", auth, categorys.update_category);
// router.post("/category/delete_category", auth, categorys.delete_category);
// router.post("/category/get_categorys", auth, categorys.get_categorys);

export const CREATE_CATEGORY = "category/CREATE_CATEGORY";
export const CREATE_CATEGORY_SUCCESS = "category/CREATE_CATEGORY_SUCCESS";
export const CREATE_CATEGORY_ERROR = "category/CREATE_CATEGORY_ERROR";

export const UPDATE_CATEGORY = "category/UPDATE_CATEGORY";
export const UPDATE_CATEGORY_SUCCESS = "category/UPDATE_CATEGORY_SUCCESS";
export const UPDATE_CATEGORY_ERROR = "category/UPDATE_CATEGORY_ERROR";

export const DELETE_CATEGORY = "category/DELETE_CATEGORY";
export const DELETE_CATEGORY_SUCCESS = "category/DELETE_CATEGORY_SUCCESS";
export const DELETE_CATEGORY_ERROR = "category/DELETE_CATEGORY_ERROR";

export const GET_CATEGORY = "category/GET_CATEGORY";
export const GET_CATEGORY_SUCCESS = "category/GET_CATEGORY_SUCCESS";
export const GET_CATEGORY_ERROR = "category/GET_CATEGORY_ERROR";

export const RESET_CATEGORY = "category/RESET_CATEGORY";
export const RESET_CATEGORY_SUCCESS = "category/RESET_CATEGORY_SUCCESS";
export const RESET_CATEGORY_ERROR = "category/RESET_CATEGORY_ERROR";