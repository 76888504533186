import * as types from "./constants";
// router.post("/setting/set", auth, settings.setting_set);
// router.post("/setting/get", settings.setting_get);
// router.post("/setting/upload",  auth, upload.array("files", 1), medias.upload);

export function setting_set(payload) {
  return {
    type: types.SETTING_SET,
    payload,
  };
}
export function setting_get(payload) {
  return {
    type: types.SETTING_GET,
    payload,
  };
}
export function reset_setting(payload) {
  return {
    type: types.RESET_SETTING,
    payload,
  };
}
export function setting_summarys(payload) {
  return {
    type: types.SETTING_SUMMARYS,
    payload,
  };
}
export function get_pending(payload) {
  return {
    type: types.GET_PENDING,
    payload,
  };
}

