import { httpClient as request } from "../../utils/Api";
// router.post("/setting/set", auth, settings.setting_set);
// router.post("/setting/get", settings.setting_get);
// router.post("/setting/upload",  auth, upload.array("files", 1), medias.upload);
export const setting_set = payload => {
  return  payload.request_type == 0 ? request.post("/setting/set", payload) : request.post("/setting/save", payload)
};

export const setting_get = payload => {
  return payload.request_type == 0 ? request.post("/setting/get", payload): request.post("/setting/server", payload)
};

export const setting_summarys = payload => {
  return request.post("/setting/summarys", payload)
};


export const get_pending = payload => {
  return request.post("/setting/get_pending", payload)
};

