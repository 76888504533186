import { LogoutOutlined, SendOutlined } from "@ant-design/icons";
import { Button, Col, Collapse, Input, message, Row, Select, Space } from "antd";
import Modal from "antd/lib/modal/Modal";
import 'moment/locale/vi';
import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";

import { isMobile } from "react-device-detect";
import { useHistory } from "react-router-dom";
import { buy_account, get_account_price, reset_account } from "../../modules/account/actions";
import { curencyFormat } from "../../utils/help";

const { Option } = Select;
const { Panel } = Collapse
const Buy_Account = (props) => {
   const _user = useSelector(({ user }) => user.me.user);
   const dispatch = useDispatch();
   const history = useHistory()
   let initialState = {
      discount_code: null,
      select: null,
   }
   const [state, setState] = useState(initialState)
   const _accountPrice = useSelector(({ account }) => account.accountPrice);
   const _buyAccount = useSelector(({ account }) => account.buyAccount);
   useEffect(() => {
      setState({ ...state, select: { ...props.data, price_after: props.data.price - (props.data.price / 100 * props.data?.category?.discount) } })
   }, [props.data])
   let select = state.select

   const handleGetPrice = () => {
      if (!_user || !_user._id)
         return message.warning("Vui lòng đăng nhập")
      if (state.discount_code == null)
         return message.warning("Vui lòng nhập mã giảm giá nếu có")
      dispatch(get_account_price({
         _id: select._id,
         discount_code: state.discount_code
      }))
   }

   const handleBuy = async () => {
      if (!_user || !_user._id)
         return message.warning("Vui lòng đăng nhập trước khi đặt hàng")
      if(state.discount_code !== null && state.discount_code !== "" && _accountPrice.msg == null)
         return message.warning("Vui lòng áp dụng mã giảm giá trước khi đặt hàng")
      dispatch(buy_account({
         _id: select._id,
         discount_code: state.discount_code
      }))
   }
   useEffect(() => {
      return ()=>{
         dispatch(reset_account({
            remove: ["buyAccount", "accountPrice"]
         }))
      }
   }, [])
   useEffect(() => {
      //console.log("_buyAccount", _buyAccount)
      if (_buyAccount.status) {
 
         props.setState({ ...props.state, isVisibleResultAccount: true, isShowBuyAccount: false })
      }
      else if (_buyAccount.status == false && _buyAccount.msg !== null)
         return message.warning(_buyAccount.msg)
   }, [_buyAccount])
   useEffect(() => {
      if (_accountPrice.status) {

         setState({ ...state, select: { ...state.select, price_after: _accountPrice.data } })
         message.success(_accountPrice.msg)
      }
      else if (_accountPrice.status == false && _accountPrice.msg !== null) {
         setState({ ...state, discount_code: null })
         return message.warning(_accountPrice.msg)
      }
   }, [_accountPrice])
   return (

      <Modal title={`Mua tài khoản #${select?.id}`}
         visible={props.state.isShowBuyAccount}
         style={{ top: "20%" }}
         destroyOnClose={true}
         onCancel={() => props.setState({ ...props.state, isShowBuyAccount: false })}
         width={isMobile ? "90%" : "400px"}
         footer={<Space direction="horizontal" style={{ width: '100%', justifyContent: isMobile ? "center" : "right" }} className="gx-w-100">
            <Button
               className='color1'
               loading={_buyAccount.isLoading}
               onClick={() => {
                  handleBuy()
               }} type="primary" icon={<SendOutlined />}>{"Mua Ngay"}</Button>
            <Button icon={<LogoutOutlined />} onClick={() => props.setState({ ...props.state, isShowBuyAccount: false })} type="primary" className='color2'>Thoát</Button>
         </Space>}
      >

         {state.select ?
            <Row>
               <Col xl={24} lg={24} md={24} sm={24} className={"pdB"}>
                  <Input addonAfter={<div style={{ width: 80 }}>Giá tiền</div>}  disabled value={select.price_after !== select.price ?
                     `${curencyFormat(select.price)} | Giảm: ${curencyFormat(select.price_after - select.price)}` : curencyFormat(select.price)}></Input>
               </Col>
               <Col xl={24} lg={24} md={24} sm={24} className={"pdB"}>
                  <Input addonAfter={<div style={{ width: 80 }}>Tổng tiền</div>}  disabled value={curencyFormat(select.price_after)}></Input>
               </Col>
               <Col xl={24} lg={24} md={24} sm={24} className={"pdB"}>
                  <p style={{ marginBottom: 4 }}>Mã giảm giá (nếu có)</p>
                  <Input.Group compact>

                     <Input style={{
                        width: 'calc(100% - 100px)',
                     }} value={state.discount_code}
                        placeholder="Mã giảm giá"
                        onChange={(e) => setState({ ...state, discount_code: e.target.value })}
                     ></Input>
                     <Button style={{
                        width: '100px',
                     }}
                        onClick={handleGetPrice}
                        type="primary">Áp Dụng</Button>
                  </Input.Group>

               </Col>
            </Row>
            : null
         }

      </Modal>
   )
}
export default Buy_Account