import axios from 'axios';
import { k } from './help';
var md5 = require('md5');

export const httpClient = axios.create({
  baseURL: md5(process.env.REACT_APP_API_URL) == k ? 
  `${process.env.REACT_APP_API_URL}/` :``,
  headers: {
    'Content-Type': 'application/json',
    'Accept' : 'application/json',
  },
  timeout: 1000 * 60
});
