import { put, call, takeLatest } from "redux-saga/effects";
import * as types from "./constants";
import * as services from "./services";
// router.post("/items/add_item", auth, items.add_item);
// router.post("/items/update_item", auth, items.update_item);
// router.post("/items/delete_item", auth, items.delete_item);
// router.post("/items/buy_item", auth, items.buy_item);
// router.post("/items/get_item", auth, items.get_item);

export function* add_item(action) {
  try {
    const { data, status } = yield call(services.add_item, action.payload);
    yield put({
      type: types.ADD_ITEM_SUCCESS,
      payload: data,
    });
  } catch (error) {
    if (error.response) {
      const { data, status } = error.response
      if (status == 401)
        yield put({
          type: "user/LOG_OUT_SUCCESS",
          payload: {
            msg: data.msg,
            status: false
          },
        });
      else yield put({
        type: types.ADD_ITEM_ERROR,
        payload: {msg:"Không thể kết nối server", status:false},
      });
    }
    else
      yield put({
        type: types.ADD_ITEM_ERROR,
        payload: {
          msg: `Không thể kết nối server`,
          status: false
        },
      });
  }
}
export function* update_item(action) {
  try {
    const { data, status } = yield call(services.update_item, action.payload);
    yield put({
      type: types.UPDATE_ITEM_SUCCESS,
      payload: data,
    });
  } catch (error) {
    if (error.response) {
      const { data, status } = error.response
      if (status == 401)
        yield put({
          type: "user/LOG_OUT_SUCCESS",
          payload: {
            msg: data.msg,
            status: false
          },
        });
      else yield put({
        type: types.UPDATE_ITEM_ERROR,
        payload: {msg:"Không thể kết nối server", status:false},
      });
    }
    else
      yield put({
        type: types.UPDATE_ITEM_ERROR,
        payload: {
          msg: `Không thể kết nối server`,
          status: false
        },
      });
  }
}
export function* buy_item(action) {
  try {
    const { data, status } = yield call(services.buy_item, action.payload);
    yield put({
      type: types.BUY_ITEM_SUCCESS,
      payload: data,
    });
  } catch (error) {
    if (error.response) {
      const { data, status } = error.response
      if (status == 401)
        yield put({
          type: "user/LOG_OUT_SUCCESS",
          payload: {
            msg: data.msg,
            status: false
          },
        });
      else yield put({
        type: types.BUY_ITEM_ERROR,
        payload: {msg:"Không thể kết nối server", status:false},
      });
    }
    else
      yield put({
        type: types.BUY_ITEM_ERROR,
        payload: {
          msg: `Không thể kết nối server`,
          status: false
        },
      });
  }
}
export function* get_item(action) {
  try {
    const { data, status } = yield call(services.get_items, action.payload);
    yield put({
      type: types.GET_ITEM_SUCCESS,
      payload: data,
    });
  } catch (error) {
    if (error.response) {
      const { data, status } = error.response
      if (status == 401)
        yield put({
          type: "user/LOG_OUT_SUCCESS",
          payload: {
            msg: data.msg,
            status: false
          },
        });
      else yield put({
        type: types.GET_ITEM_ERROR,
        payload: {msg:"Không thể kết nối server", status:false},
      });
    }
    else
      yield put({
        type: types.GET_ITEM_ERROR,
        payload: {
          msg: `Không thể kết nối server`,
          status: false
        },
      });
  }
}
export function* delete_item(action) {
  try {
    const { data, status } = yield call(services.delete_item, action.payload);
    yield put({
      type: types.DELETE_ITEM_SUCCESS,
      payload: data,
    });
  } catch (error) {
    if (error.response) {
      const { data, status } = error.response
      if (status == 401)
        yield put({
          type: "user/LOG_OUT_SUCCESS",
          payload: {
            msg: data.msg,
            status: false
          },
        });
      else yield put({
        type: types.DELETE_ITEM_ERROR,
        payload: {msg:"Không thể kết nối server", status:false},
      });
    }
    else
      yield put({
        type: types.DELETE_ITEM_ERROR,
        payload: {
          msg: `Không thể kết nối server`,
          status: false
        },
      });
  }
}
export function* get_historys(action) {
  try {
    const { data, status } = yield call(services.get_historys, action.payload);
    yield put({
      type: types.GET_HISTORYS_SUCCESS,
      payload: data,
    });
  } catch (error) {
    if (error.response) {
      const { data, status } = error.response
      if (status == 401)
        yield put({
          type: "user/LOG_OUT_SUCCESS",
          payload: {
            msg: data.msg,
            status: false
          },
        });
      else yield put({
        type: types.GET_HISTORYS_ERROR,
        payload: {msg:"Không thể kết nối server", status:false},
      });
    }
    else
      yield put({
        type: types.GET_HISTORYS_ERROR,
        payload: {
          msg: `Không thể kết nối server`,
          status: false
        },
      });
  }
}
export function* delete_history(action) {
  try {
    const { data, status } = yield call(services.delete_history, action.payload);
    yield put({
      type: types.DELETE_HISTORYS_SUCCESS,
      payload: data,
    });
  } catch (error) {
    if (error.response) {
      const { data, status } = error.response
      if (status == 401)
        yield put({
          type: "user/LOG_OUT_SUCCESS",
          payload: {
            msg: data.msg,
            status: false
          },
        });
      else yield put({
        type: types.DELETE_HISTORYS_ERROR,
        payload: {msg:"Không thể kết nối server", status:false},
      });
    }
    else
      yield put({
        type: types.DELETE_HISTORYS_ERROR,
        payload: {
          msg: `Không thể kết nối server`,
          status: false
        },
      });
  }
}
export function* update_history(action) {
  try {
    const { data, status } = yield call(services.update_history, action.payload);
    yield put({
      type: types.UPDATE_HISTORYS_SUCCESS,
      payload: data,
    });
  } catch (error) {
    if (error.response) {
      const { data, status } = error.response
      if (status == 401)
        yield put({
          type: "user/LOG_OUT_SUCCESS",
          payload: {
            msg: data.msg,
            status: false
          },
        });
      else yield put({
        type: types.UPDATE_HISTORYS_ERROR,
        payload: {msg:"Không thể kết nối server", status:false},
      });
    }
    else
      yield put({
        type: types.UPDATE_HISTORYS_ERROR,
        payload: {
          msg: `Không thể kết nối server`,
          status: false
        },
      });
  }
}
export function* get_item_price(action) {
  try {
    const { data, status } = yield call(services.get_item_price, action.payload);
    yield put({
      type: types.GET_ITEM_PRICE_SUCCESS,
      payload: data,
    });
  } catch (error) {
    if (error.response) {
      const { data, status } = error.response
      if (status == 401)
        yield put({
          type: "user/LOG_OUT_SUCCESS",
          payload: {
            msg: data.msg,
            status: false
          },
        });
      else yield put({
        type: types.GET_ITEM_PRICE_ERROR,
        payload: {msg:"Không thể kết nối server", status:false},
      });
    }
    else
      yield put({
        type: types.GET_ITEM_PRICE_ERROR,
        payload: {
          msg: `Không thể kết nối server`,
          status: false
        },
      });
  }
}

export function* add_item_price_type(action) {
  try {
    const { data, status } = yield call(services.add_item_price_type, action.payload);
    yield put({
      type: types.ADD_ITEM_PRICE_TYPE_SUCCESS,
      payload: data,
    });
  } catch (error) {
    if (error.response) {
      const { data, status } = error.response
      if (status == 401)
        yield put({
          type: "user/LOG_OUT_SUCCESS",
          payload: {
            msg: data.msg,
            status: false
          },
        });
      else yield put({
        type: types.ADD_ITEM_PRICE_TYPE_ERROR,
        payload: {msg:"Không thể kết nối server", status:false},
      });
    }
    else
      yield put({
        type: types.ADD_ITEM_PRICE_TYPE_ERROR,
        payload: {
          msg: `Không thể kết nối server`,
          status: false
        },
      });
  }
}
export function* update_item_price_type(action) {
  try {
    const { data, status } = yield call(services.update_item_price_type, action.payload);
    yield put({
      type: types.UPDATE_ITEM_PRICE_TYPE_SUCCESS,
      payload: data,
    });
  } catch (error) {
    if (error.response) {
      const { data, status } = error.response
      if (status == 401)
        yield put({
          type: "user/LOG_OUT_SUCCESS",
          payload: {
            msg: data.msg,
            status: false
          },
        });
      else yield put({
        type: types.UPDATE_ITEM_PRICE_TYPE_ERROR,
        payload: {msg:"Không thể kết nối server", status:false},
      });
    }
    else
      yield put({
        type: types.UPDATE_ITEM_PRICE_TYPE_ERROR,
        payload: {
          msg: `Không thể kết nối server`,
          status: false
        },
      });
  }
}
export function* get_item_price_type(action) {
  try {
    const { data, status } = yield call(services.get_item_price_type, action.payload);
    yield put({
      type: types.GET_ITEM_PRICE_TYPE_SUCCESS,
      payload: data,
    });
  } catch (error) {
    if (error.response) {
      const { data, status } = error.response
      if (status == 401)
        yield put({
          type: "user/LOG_OUT_SUCCESS",
          payload: {
            msg: data.msg,
            status: false
          },
        });
      else yield put({
        type: types.GET_ITEM_PRICE_TYPE_ERROR,
        payload: {msg:"Không thể kết nối server", status:false},
      });
    }
    else
      yield put({
        type: types.GET_ITEM_PRICE_TYPE_ERROR,
        payload: {
          msg: `Không thể kết nối server`,
          status: false
        },
      });
  }
}
export function* delete_item_price_type(action) {
  try {
    const { data, status } = yield call(services.delete_item_price_type, action.payload);
    yield put({
      type: types.DELETE_ITEM_PRICE_TYPE_SUCCESS,
      payload: data,
    });
  } catch (error) {
    if (error.response) {
      const { data, status } = error.response
      if (status == 401)
        yield put({
          type: "user/LOG_OUT_SUCCESS",
          payload: {
            msg: data.msg,
            status: false
          },
        });
      else yield put({
        type: types.DELETE_ITEM_PRICE_TYPE_ERROR,
        payload: {msg:"Không thể kết nối server", status:false},
      });
    }
    else
      yield put({
        type: types.DELETE_ITEM_PRICE_TYPE_ERROR,
        payload: {
          msg: `Không thể kết nối server`,
          status: false
        },
      });
  }
}
export default function* itemSaga() {
  yield takeLatest(types.ADD_ITEM, add_item);
  yield takeLatest(types.UPDATE_ITEM, update_item);
  yield takeLatest(types.BUY_ITEM, buy_item);
  yield takeLatest(types.GET_ITEM, get_item);
  yield takeLatest(types.DELETE_ITEM, delete_item);
  yield takeLatest(types.GET_HISTORYS, get_historys);
  yield takeLatest(types.UPDATE_HISTORYS, update_history);
  yield takeLatest(types.DELETE_HISTORYS, delete_history);
  yield takeLatest(types.GET_ITEM_PRICE, get_item_price);
  yield takeLatest(types.ADD_ITEM_PRICE_TYPE, add_item_price_type);
  yield takeLatest(types.GET_ITEM_PRICE_TYPE, get_item_price_type);
  yield takeLatest(types.DELETE_ITEM_PRICE_TYPE, delete_item_price_type);
  yield takeLatest(types.UPDATE_ITEM_PRICE_TYPE, update_item_price_type);
}
