import * as Types from "./constants";

const initState = {
  depositList: {
    msg: null,
    status: false,
    isLoading: false,
    data: [],
    total: 0,
    last_page: 0,
    current_page: 0,
    per_page: 20,
  },
  createDeposit: {
    isLoading: false,
    status: false,
    msg: null,
    data: {}
  },
  deleteDeposit: {
    isLoading: false,
    status: false,
    msg: null,
    data: {}
  },
  updateDeposit: {
    isLoading: false,
    status: false,
    msg: null,
    data: {}
  },
};
export default function depositReducer(state = initState, action = {}) {
  switch (action.type) {
    case Types.CREATE_DEPOSIT:
      return {
        ...state,
        createDeposit: {
          ...state.createDeposit,
          isLoading: true,
          status: false,
          msg: null,
        }
      };
    case Types.CREATE_DEPOSIT_SUCCESS:
      if (action.payload.status == true)
        state.depositList.data = [action.payload.data].concat(state.depositList.data)

      return {
        ...state,
        depositList: {
          ...state.depositList,
          data: state.depositList.data
        },
        createDeposit: {
          ...state.createDeposit,
          ...action.payload,
          isLoading: false,
        }
      };
    case Types.CREATE_DEPOSIT_ERROR:
      return {
        ...state,
        createDeposit: {
          ...state.createDeposit,
          ...action.payload,
          isLoading: false,
          status: false
        }
      };
    /////////
    case Types.UPDATE_DEPOSIT:
      return {
        ...state,
        updateDeposit: {
          ...state.updateDeposit,
          isLoading: true,
          status: false,
          msg: null,
        }
      };
    case Types.UPDATE_DEPOSIT_SUCCESS:
      if (action.payload.status == true) {
        let objIndex = state.depositList.data.findIndex((obj => obj._id == action.payload.data._id));
        state.depositList.data[objIndex] = action.payload.data
      }
      return {
        ...state,
        depositList: {
          ...state.depositList,
          data: [...state.depositList.data]
        },
        updateDeposit: {
          ...state.updateDeposit,
          ...action.payload,
          isLoading: false,
        }
      };
    case Types.UPDATE_DEPOSIT_ERROR:
      return {
        ...state,
        updateDeposit: {
          ...state.updateDeposit,
          ...action.payload,
          isLoading: false,
          status: false
        }
      };
    /////////
    case Types.GET_DEPOSIT:
      return {
        ...state,
        depositList: {
          ...state.depositList,
          isLoading: true,
          status: false,
          msg: null,
        }
      };
    case Types.GET_DEPOSIT_SUCCESS:
      return {
        ...state,
        depositList: {
          ...state.depositList,
          ...action.payload.data,
          isLoading: false,
          status: action.payload.status,
          msg: action.payload.msg
        }
      };
    case Types.GET_DEPOSIT_ERROR:
      return {
        ...state,
        depositList: {
          ...state.depositList,
          status: false,
          isLoading: false,
          msg: action.payload.msg
        }
      };

    /////////
    case Types.DELETE_DEPOSIT:
      return {
        ...state,
        deleteDeposit: {
          ...state.deleteDeposit,
          status: false,
          isLoading: true,
          msg: null,
        }
      };
    case Types.DELETE_DEPOSIT_SUCCESS:
      if (action.payload.status) {
        state.depositList.data = state.depositList.data.filter((item) => item._id !== action.payload.data._id)
      }
      return {
        ...state,
        depositList: {
          ...state.depositList,
          data: state.depositList.data
        },
        deleteDeposit: {
          ...state.deleteDeposit,
          isLoading: false,
          ...action.payload,
        }
      };
    case Types.DELETE_DEPOSIT_ERROR:
      return {
        ...state,
        deleteDeposit: {
          ...state.deleteDeposit,
          ...action.payload,
          status: false,
          isLoading: false,
        }
      };

    ///////
    case Types.RESET_DEPOSIT:
      let newState = {}
      if (action.payload.remove[0] == "all")
        newState = initState
      else
        for (let [key, value] of Object.entries(state)) {
          if (action.payload.remove.includes(key))
            newState[key] = { ...initState[key] }
          else newState[key] = value
        };
      return newState

    default:
      return state;
  }
}
