import * as Types from "./constants";

const initState = {
  boostList: {
    msg: null,
    status: false,
    isLoading: false,
    data: [],
    total: 0,
    last_page: 0,
    current_page: 0,
    per_page: 20,
  },
  buyBoost: {
    isLoading: false,
    status: false,
    msg: null,
    data: {}
  },
  updateBoost: {
    isLoading: false,
    status: false,
    msg: null,
    data: {}
  },
  applyBoost: {
    isLoading: false,
    status: false,
    msg: null,
    data: {}
  },
  boostPrice: {
    isLoading: false,
    status: false,
    msg: null,
    data: {}
  },
};
export default function boostReducer(state = initState, action = {}) {
  switch (action.type) {
    case Types.BUY_BOOST:
      return {
        ...state,
        buyBoost: {
          ...state.buyBoost,
          isLoading: true,
          status: false,
          msg: null,
        }
      };
    case Types.BUY_BOOST_SUCCESS:
      if(action.payload.status == true)
        state.boostList.data = [action.payload.data].concat(state.boostList.data)

      return {
        ...state,
        boostList:{
          ...state.boostList,
          data: state.boostList.data
        },
        buyBoost: {
          ...state.buyBoost,
          ...action.payload,
          isLoading: false,
        }
      };
    case Types.BUY_BOOST_ERROR:
      return {
        ...state,
        buyBoost: {
          ...state.buyBoost,
          ...action.payload,
          isLoading: false,
          status: false
        }
      };
    //////////
    case Types.UPDATE_BOOST:
      return {
        ...state,
        updateBoost: {
          ...state.updateBoost,
          isLoading: true,
          status: false,
          msg: null,
        }
      };
    case Types.UPDATE_BOOST_SUCCESS:
      if (action.payload.status == true) {
        let objIndex = state.boostList.data.findIndex((obj => obj._id == action.payload.data._id));
        state.boostList.data[objIndex] = action.payload.data
      }
      return {
        ...state,
        boostList: {
          ...state.boostList,
          data: [...state.boostList.data]
        },
        updateBoost: {
          ...state.updateBoost,
          ...action.payload,
          isLoading: false,
        }
      };
    case Types.UPDATE_BOOST_ERROR:
      return {
        ...state,
        updateBoost: {
          ...state.updateBoost,
          ...action.payload,
          isLoading: false,
          status: false
        }
      };
    /////////
    case Types.GET_BOOST:
      return {
        ...state,
        boostList: {
          ...state.boostList,
          isLoading: true,
          status: false,
          msg: null,
        }
      };
    case Types.GET_BOOST_SUCCESS:
      return {
        ...state,
        boostList: {
          ...state.boostList,
          ...action.payload.data,
          isLoading: false,
          status: action.payload.status,
          msg: action.payload.msg
        }
      };
    case Types.GET_BOOST_ERROR:
      return {
        ...state,
        boostList: {
          ...state.boostList,
          status: false,
          isLoading: false,
          msg: action.payload.msg
        }
      };
    /////////
    case Types.APPLY_BOOST:
      return {
        ...state,
        applyBoost: {
          ...state.applyBoost,
          status: false,
          isLoading: true,
          msg: null,
        }
      };
    case Types.APPLY_BOOST_SUCCESS:

      return {
        ...state,
       
        applyBoost: {
          ...state.applyBoost,
          isLoading: false,
          ...action.payload,
        }
      };
    case Types.APPLY_BOOST_ERROR:
      return {
        ...state,
        applyBoost: {
          ...state.applyBoost,
          ...action.payload,
          status: false,
          isLoading: false,
        }
      };
      case Types.GET_BOOST_PRICE:
        return {
          ...state,
          boostPrice: {
            ...state.boostPrice,
            isLoading: true,
            status: false,
            msg: null,
          }
        };
      case Types.GET_BOOST_PRICE_SUCCESS:
     
        return {
          ...state,
          boostPrice: {
            ...state.boostPrice,
            data: action.payload.data,
            isLoading: false,
            status: action.payload.status,
            msg: action.payload.msg
          }
        };
      case Types.GET_BOOST_PRICE_ERROR:
        return {
          ...state,
          boostPrice: {
            ...state.boostPrice,
            status: false,
            isLoading: false,
            msg: action.payload.msg
          }
        };
    /////////
    case Types.RESET_BOOST:
      let newState = {}
      if (action.payload.remove[0] == "all")
        newState = initState
      else
        for (let [key, value] of Object.entries(state)) {
          if (action.payload.remove.includes(key))
            newState[key] = { ...initState[key] }
          else newState[key] = value
        };
      return newState

    default:
      return state;
  }
}
