import { isMobile } from "react-device-detect"

const Header_Line = (props)=>{
    return (
        <div style={{
            textAlign:"center",
            paddingTop: "30px"
            }}>
            <h3 style={{
                fontSize:isMobile ? 20: 30,
                fontWeight: 600,
                color: props.textColor
            }}>{props.text}</h3>
            <div style={{
                    width: "60px",
                    height: "3px",
                    background: props.lineColor,
                    margin: "0 auto 30px auto",
            }}></div>
		</div>
    )
}
export default Header_Line