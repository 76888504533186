import * as Types from "./constants";

const initState = {
  discountList: {
    msg: null,
    status: false,
    isLoading: false,
    data: [],
    total: 0,
    last_page: 0,
    current_page: 0,
    per_page: 20,
  },
  addDiscount: {
    isLoading: false,
    status: false,
    msg: null,
    data: {}
  },
  updateDiscount: {
    isLoading: false,
    status: false,
    msg: null,
    data: {}
  },
  deleteDiscount: {
    isLoading: false,
    status: false,
    msg: null,
    data: {}
  },
};
export default function discountReducer(state = initState, action = {}) {
  switch (action.type) {
    case Types.ADD_DISCOUNT:
      return {
        ...state,
        addDiscount: {
          ...state.addDiscount,
          isLoading: true,
          status: false,
          msg: null,
        }
      };
    case Types.ADD_DISCOUNT_SUCCESS:
      if(action.payload.status == true)
        state.discountList.data = [action.payload.data].concat(state.discountList.data)

      return {
        ...state,
        discountList:{
          ...state.discountList,
          data: state.discountList.data
        },
        addDiscount: {
          ...state.addDiscount,
          ...action.payload,
          isLoading: false,
        }
      };
    case Types.ADD_DISCOUNT_ERROR:
      return {
        ...state,
        addDiscount: {
          ...state.addDiscount,
          ...action.payload,
          isLoading: false,
          status: false
        }
      };
    //////////
    case Types.UPDATE_DISCOUNT:
      return {
        ...state,
        updateDiscount: {
          ...state.updateDiscount,
          isLoading: true,
          status: false,
          msg: null,
        }
      };
    case Types.UPDATE_DISCOUNT_SUCCESS:
      if (action.payload.status == true) {
        let objIndex = state.discountList.data.findIndex((obj => obj._id == action.payload.data._id));
        state.discountList.data[objIndex] = action.payload.data
      }
      return {
        ...state,
        discountList: {
          ...state.discountList,
          data: [...state.discountList.data]
        },
        updateDiscount: {
          ...state.updateDiscount,
          ...action.payload,
          isLoading: false,
        }
      };
    case Types.UPDATE_DISCOUNT_ERROR:
      return {
        ...state,
        updateDiscount: {
          ...state.updateDiscount,
          ...action.payload,
          isLoading: false,
          status: false
        }
      };
    /////////
    case Types.GET_DISCOUNT:
      return {
        ...state,
        discountList: {
          ...state.discountList,
          isLoading: true,
          status: false,
          msg: null,
        }
      };
    case Types.GET_DISCOUNT_SUCCESS:
      return {
        ...state,
        discountList: {
          ...state.discountList,
          ...action.payload.data,
          isLoading: false,
          status: action.payload.status,
          msg: action.payload.msg
        }
      };
    case Types.GET_DISCOUNT_ERROR:
      return {
        ...state,
        discountList: {
          ...state.discountList,
          status: false,
          isLoading: false,
          msg: action.payload.msg
        }
      };
    /////////
    case Types.DELETE_DISCOUNT:
      return {
        ...state,
        deleteDiscount: {
          ...state.deleteDiscount,
          status: false,
          isLoading: true,
          msg: null,
        }
      };
    case Types.DELETE_DISCOUNT_SUCCESS:
      if (action.payload.status) {
        state.discountList.data = state.discountList.data.filter((discount) => discount._id !== action.payload.data._id)
      }
      return {
        ...state,
        discountList: {
          ...state.discountList,
          data: state.discountList.data
        },
        deleteDiscount: {
          ...state.deleteDiscount,
          isLoading: false,
          ...action.payload,
        }
      };
    case Types.DELETE_DISCOUNT_ERROR:
      return {
        ...state,
        deleteDiscount: {
          ...state.deleteDiscount,
          ...action.payload,
          status: false,
          isLoading: false,
        }
      };
      ///////
    case Types.RESET_DISCOUNT:
      let newState = {}
      if (action.payload.remove[0] == "all")
        newState = initState
      else
        for (let [key, value] of Object.entries(state)) {
          if (action.payload.remove.includes(key))
            newState[key] = { ...initState[key] }
          else newState[key] = value
        };
      return newState

    default:
      return state;
  }
}
