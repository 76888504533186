import * as Types from "./constants";

const initState = {
  wheelList: {
    msg: null,
    status: false,
    isLoading: false,
    data: [],
    total: 0,
    last_page: 0,
    current_page: 0,
    per_page: 20,
  },
  historyList: {
    msg: null,
    status: false,
    isLoading: false,
    data: [],
    total: 0,
    last_page: 0,
    current_page: 0,
    per_page: 20,
  },
  addWheel: {
    isLoading: false,
    status: false,
    msg: null,
    data: {}
  },
  updateWheel: {
    isLoading: false,
    status: false,
    msg: null,
    data: {}
  },
  playWheel: {
    isLoading: false,
    status: false,
    msg: null,
    data: {}
  },
  deleteWheel: {
    isLoading: false,
    status: false,
    msg: null,
    data: {}
  },
};
export default function wheelReducer(state = initState, action = {}) {
  switch (action.type) {
    case Types.ADD_WHEEL:
      return {
        ...state,
        addWheel: {
          ...state.addWheel,
          isLoading: true,
          status: false,
          msg: null,
        }
      };
    case Types.ADD_WHEEL_SUCCESS:
      if(action.payload.status == true)
        state.wheelList.data = [action.payload.data].concat(state.wheelList.data)

      return {
        ...state,
        wheelList:{
          ...state.wheelList,
          data: state.wheelList.data
        },
        addWheel: {
          ...state.addWheel,
          ...action.payload,
          isLoading: false,
        }
      };
    case Types.ADD_WHEEL_ERROR:
      return {
        ...state,
        addWheel: {
          ...state.addWheel,
          ...action.payload,
          isLoading: false,
          status: false
        }
      };
    //////////
    case Types.UPDATE_WHEEL:
      return {
        ...state,
        updateWheel: {
          ...state.updateWheel,
          isLoading: true,
          status: false,
          msg: null,
        }
      };
    case Types.UPDATE_WHEEL_SUCCESS:
      if (action.payload.status == true) {
        let objIndex = state.wheelList.data.findIndex((obj => obj._id == action.payload.data._id));
        state.wheelList.data[objIndex] = action.payload.data
      }
      return {
        ...state,
        wheelList: {
          ...state.wheelList,
          data: [...state.wheelList.data]
        },
        updateWheel: {
          ...state.updateWheel,
          ...action.payload,
          isLoading: false,
        }
      };
    case Types.UPDATE_WHEEL_ERROR:
      return {
        ...state,
        updateWheel: {
          ...state.updateWheel,
          ...action.payload,
          isLoading: false,
          status: false
        }
      };
    /////////
      /////////
      case Types.DELETE_WHEEL:
        return {
          ...state,
          deleteWheel: {
            ...state.deleteItem,
            status: false,
            isLoading: true,
            msg: null,
          }
        };
      case Types.DELETE_WHEEL_SUCCESS:
        if (action.payload.status) {
          state.wheelList.data = state.wheelList.data.filter((item) => item._id !== action.payload.data._id)
        }
        return {
          ...state,
          wheelList: {
            ...state.wheelList,
            data: state.wheelList.data
          },
          deleteWheel: {
            ...state.deleteWheel,
            isLoading: false,
            ...action.payload,
          }
        };
      case Types.DELETE_WHEEL_ERROR:
        return {
          ...state,
          deleteWheel: {
            ...state.deleteWheel,
            ...action.payload,
            status: false,
            isLoading: false,
          }
        };
        ///////
    case Types.GET_WHEEL:
      return {
        ...state,
        wheelList: {
          ...state.wheelList,
          isLoading: true,
          status: false,
          msg: null,
        }
      };
    case Types.GET_WHEEL_SUCCESS:
      return {
        ...state,
        wheelList: {
          ...state.wheelList,
          ...action.payload.data,
          isLoading: false,
          status: action.payload.status,
          msg: action.payload.msg
        }
      };
    case Types.GET_WHEEL_ERROR:
      return {
        ...state,
        wheelList: {
          ...state.wheelList,
          status: false,
          isLoading: false,
          msg: action.payload.msg
        }
      };
    /////////
    case Types.PLAY_WHEEL:
      return {
        ...state,
        playWheel: {
          ...state.playWheel,
          status: false,
          isLoading: true,
          msg: null,
        }
      };
    case Types.PLAY_WHEEL_SUCCESS:
    
      return {
        ...state,
        playWheel: {
          ...state.playWheel,
          isLoading: false,
          ...action.payload,
        }
      };
    case Types.PLAY_WHEEL_ERROR:
      return {
        ...state,
        playWheel: {
          ...state.playWheel,
          ...action.payload,
          status: false,
          isLoading: false,
        }
      };
 ///////
 case Types.GET_HISTORYS:
  return {
    ...state,
    historyList: {
      ...state.historyList,
      isLoading: true,
      status: false,
      msg: null,
    }
  };
case Types.GET_HISTORYS_SUCCESS:
  //console.log(action)
  return {
    ...state,
    historyList: {
      ...state.historyList,
      ...action.payload.data,
      isLoading: false,
      status: action.payload.status,
      msg: action.payload.msg
    }
  };
case Types.GET_HISTORYS_ERROR:
  return {
    ...state,
    historyList: {
      ...state.historyList,
      status: false,
      isLoading: false,
      msg: action.payload.msg
    }
  };
/////////
    /////////
    case Types.RESET_WHEEL:
      let newState = {}
      if (action.payload.remove[0] == "all")
        newState = initState
      else
        for (let [key, value] of Object.entries(state)) {
          if (action.payload.remove.includes(key))
            newState[key] = { ...initState[key] }
          else newState[key] = value
        };
      return newState

    default:
      return state;
  }
}
