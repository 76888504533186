import { httpClient as request } from "../../utils/Api";
// router.post("/deposit/create_deposit", auth, deposits.create_deposit);
// router.post('/deposit/change_deposit_info', auth_valid, deposits.change_deposit_info)
// router.post("/deposit/update_deposit", auth, deposits.update_deposit);
// router.post("/deposit/create_deposit_random", auth, deposits.create_deposit_random);
// router.post("/deposit/delete_deposit", auth, deposits.delete_deposit);
// router.post("/deposit/get_deposits_by_category", auth, deposits.get_deposits_by_category);
// router.post("/deposit/get_deposits_by_admin", auth, deposits.get_deposits_by_admin);
// router.post("/deposit/get_deposits_owner", auth, deposits.get_deposits_owner);
// router.post("/deposit/buy_deposit", auth, deposits.buy_deposit);

export const create_deposit = payload => {
  return request.post("/deposit/create_deposit", payload)
};

export const update_deposit = payload => {
  return request.post("/deposit/update_deposit", payload)
};

export const delete_deposit = payload => {
  return request.post("/deposit/delete_deposit", payload)
};

export const get_deposits = payload => {
  return request.post("/deposit/get_deposits", payload)
};

