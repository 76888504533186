//////Item
// router.post("/items/add_item", auth, items.add_item);
// router.post("/items/update_item", auth, items.update_item);
// router.post("/items/delete_item", auth, items.delete_item);
// router.post("/items/buy_item", auth, items.buy_item);

export const ADD_ITEM = "item/ADD_ITEM";
export const ADD_ITEM_SUCCESS = "item/ADD_ITEM_SUCCESS";
export const ADD_ITEM_ERROR = "item/ADD_ITEM_ERROR";

export const UPDATE_ITEM = "item/UPDATE_ITEM";
export const UPDATE_ITEM_SUCCESS = "item/UPDATE_ITEM_SUCCESS";
export const UPDATE_ITEM_ERROR = "item/UPDATE_ITEM_ERROR";

export const DELETE_ITEM = "item/DELETE_ITEM";
export const DELETE_ITEM_SUCCESS = "item/DELETE_ITEM_SUCCESS";
export const DELETE_ITEM_ERROR = "item/DELETE_ITEM_ERROR";

export const BUY_ITEM = "item/BUY_ITEM";
export const BUY_ITEM_SUCCESS = "item/BUY_ITEM_SUCCESS";
export const BUY_ITEM_ERROR = "item/BUY_ITEM_ERROR";

export const GET_ITEM = "item/GET_ITEM";
export const GET_ITEM_SUCCESS = "item/GET_ITEM_SUCCESS";
export const GET_ITEM_ERROR = "item/GET_ITEM_ERROR";

export const GET_HISTORYS = "item/GET_HISTORYS";
export const GET_HISTORYS_SUCCESS = "item/GET_HISTORYS_SUCCESS";
export const GET_HISTORYS_ERROR = "item/GET_HISTORYS_ERROR";

export const UPDATE_HISTORYS = "item/UPDATE_HISTORYS";
export const UPDATE_HISTORYS_SUCCESS = "item/UPDATE_HISTORYS_SUCCESS";
export const UPDATE_HISTORYS_ERROR = "item/UPDATE_HISTORYS_ERROR";

export const DELETE_HISTORYS = "item/DELETE_HISTORYS";
export const DELETE_HISTORYS_SUCCESS = "item/DELETE_HISTORYS_SUCCESS";
export const DELETE_HISTORYS_ERROR = "item/DELETE_HISTORYS_ERROR";

export const RESET_ITEM = "item/RESET_ITEM";
export const RESET_ITEM_SUCCESS = "item/RESET_ITEM_SUCCESS";
export const RESET_ITEM_ERROR = "item/RESET_ITEM_ERROR";

export const GET_ITEM_PRICE = "item/GET_ITEM_PRICE";
export const GET_ITEM_PRICE_SUCCESS = "item/GET_ITEM_PRICE_SUCCESS";
export const GET_ITEM_PRICE_ERROR = "item/GET_ITEM_PRICE_ERROR";

export const GET_ITEM_PRICE_TYPE = "item/GET_ITEM_PRICE_TYPE";
export const GET_ITEM_PRICE_TYPE_SUCCESS = "item/GET_ITEM_PRICE_TYPE_SUCCESS";
export const GET_ITEM_PRICE_TYPE_ERROR = "item/GET_ITEM_PRICE_TYPE_ERROR";

export const DELETE_ITEM_PRICE_TYPE = "item/DELETE_ITEM_PRICE_TYPE";
export const DELETE_ITEM_PRICE_TYPE_SUCCESS = "item/DELETE_ITEM_PRICE_TYPE_SUCCESS";
export const DELETE_ITEM_PRICE_TYPE_ERROR = "item/DELETE_ITEM_PRICE_TYPE_ERROR";

export const ADD_ITEM_PRICE_TYPE = "item/ADD_ITEM_PRICE_TYPE";
export const ADD_ITEM_PRICE_TYPE_SUCCESS = "item/ADD_ITEM_PRICE_TYPE_SUCCESS";
export const ADD_ITEM_PRICE_TYPE_ERROR = "item/ADD_ITEM_PRICE_TYPE_ERROR";

export const UPDATE_ITEM_PRICE_TYPE = "item/UPDATE_ITEM_PRICE_TYPE";
export const UPDATE_ITEM_PRICE_TYPE_SUCCESS = "item/UPDATE_ITEM_PRICE_TYPE_SUCCESS";
export const UPDATE_ITEM_PRICE_TYPE_ERROR = "item/UPDATE_ITEM_PRICE_TYPE_ERROR";
