import React from "react";
import { Route, Switch } from "react-router-dom";

import asyncComponent from "../utils/asyncComponent";
import Admin from "./Admin";
import Apply from "./Boostings/Apply";
import Profile from "./Profile";
import Shop_Detail from "./Shop/Detail";
import SignIn from "./SignIn";
import SignUp from "./SignUp";
import Wheel from "./Wheel";
const App = ({ match}) => {
  
  return (
    <Switch>
      <Route exact path='/signin' component={SignIn} />
      <Route exact path='/signup' component={SignUp} />
      <Route path={`${match.url}/`} component={asyncComponent(() => import('./Home'))} />

      <Route exact path={`${match.url}shop/:type/:id/detail`}  component={Shop_Detail} />
      <Route path={`${match.url}shop/:type/:url`} component={asyncComponent(() => import('./Shop/Category'))} />
      <Route path={`${match.url}shop/:type/:url`} component={asyncComponent(() => import('./Shop/Category'))} />
      <Route exact path={`${match.url}shop/:type`} component={asyncComponent(() => import('./Shop/Category_List'))} />


      <Route exact path={`${match.url}wheel/:url`} component={Wheel} />
      <Route path={`${match.url}boostings`} component={asyncComponent(() => import('./Boostings/Rent'))} />
      {/* <Route path={`${match.url}boostings/apply`} component={Apply} /> */}

      <Route path={`${match.url}boostings/`} component={asyncComponent(() => import('./Boostings/index'))} />

      <Route path={`${match.url}profile`}  component={Profile} />
      <Route path={`${match.url}admin`}  component={Admin} />

    </Switch>

  );
}



export default App;
