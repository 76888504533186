import * as types from "./constants";
// router.post("/items/add_item", auth, items.add_item);
// router.post("/items/update_item", auth, items.update_item);
// router.post("/items/delete_item", auth, items.delete_item);
// router.post("/items/buy_item", auth, items.buy_item);
// router.post("/items/get_item", auth, items.get_item);

export function add_item(payload) {
  return {
    type: types.ADD_ITEM,
    payload,
  };
}
export function update_item(payload) {
  return {
    type: types.UPDATE_ITEM,
    payload,
  };
}
export function delete_item(payload) {
  return {
    type: types.DELETE_ITEM,
    payload,
  };
}
export function get_item(payload) {
  return {
    type: types.GET_ITEM,
    payload,
  };
}
export function buy_item(payload) {
  return {
    type: types.BUY_ITEM,
    payload,
  };
}
export function get_historys(payload) {
  return {
    type: types.GET_HISTORYS,
    payload,
  };
}
export function delete_history(payload) {
  return {
    type: types.DELETE_HISTORYS,
    payload,
  };
}
export function update_history(payload) {
  return {
    type: types.UPDATE_HISTORYS,
    payload,
  };
}
export function reset_item(payload) {
  return {
    type: types.RESET_ITEM,
    payload,
  };
}
export function get_item_price(payload) {
  return {
    type: types.GET_ITEM_PRICE,
    payload,
  };
}
export function get_item_price_type(payload) {
  return {
    type: types.GET_ITEM_PRICE_TYPE,
    payload,
  };
}

export function add_item_price_type(payload) {
  return {
    type: types.ADD_ITEM_PRICE_TYPE,
    payload,
  };
}

export function delete_item_price_type(payload) {
  return {
    type: types.DELETE_ITEM_PRICE_TYPE,
    payload,
  };
}

export function update_item_price_type(payload) {
  return {
    type: types.UPDATE_ITEM_PRICE_TYPE,
    payload,
  };
}


