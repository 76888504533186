import { httpClient } from "../../utils/Api";
import * as Types from "./constants";

const initState = {
  me: {
    user: {},
    isLoading: false,
    status: false,
    msg: null
  },
  user: {
    token: null,
    user: {},
    isLoading: false,
    status: false,
    msg: null
  },
  register: {
    token: null,
    user: {},
    isLoading: false,
    status: false,
    msg: null
  },
  updateUser: {
    msg: null,
    status: false,
    isLoading: false,
    data: {},
  },
  changePassword: {
    msg: null,
    status: false,
    isLoading: false,
    data: {
      user: {},
      token: null
    },
  },
  logout: {
    msg: null,
    status: false,
    isLoading: false,
  },
  userList: {
    payload: {
      user_name: null,
      search: null,
      page: 0
    },
    msg: null,
    status: false,
    isLoading: false,
    data: [],
    total: 0,
    last_page: 0,
    current_page: 0,
    per_page: 20,
  },
  registerCtv: {
    msg: null,
    status: false,
    isLoading: false,
    data: {},
  },
  userPaymentWildCore: {
    msg: null,
    status: false,
    isLoading: false,
    data: [],
    total: 0,
    last_page: 0,
    current_page: 0,
    per_page: 20,
  },
  createPaymentWildCore: {
    msg: null,
    status: false,
    isLoading: false,
    data: {},
  },
  appovePaymentWildCore: {
    msg: null,
    status: false,
    isLoading: false,
    data: {},
  },
  deletePaymentWildCore: {
    msg: null,
    status: false,
    isLoading: false,
    data: {},
  },
  userPaymentWithdraw: {
    msg: null,
    status: false,
    isLoading: false,
    data: [],
    total: 0,
    last_page: 0,
    current_page: 0,
    per_page: 20,
  },
  createPaymentWithdraw: {
    msg: null,
    status: false,
    isLoading: false,
    data: {},
  },
  appovePaymentWithdraw: {
    msg: null,
    status: false,
    isLoading: false,
    data: {},
  },
  deletePaymentWithdraw: {
    msg: null,
    status: false,
    isLoading: false,
    data: {},
  },
};
export default function authReducer(state = initState, action = {}) {
  switch (action.type) {
    case Types.REGISTER:
      return {
        ...state,

        register: {
          ...state.register,
          isLoading: true,
          status: false,
          msg: null,
          user: {},
          token: null
        }
      };
    case Types.REGISTER_SUCCESS:
      //console.log(action.payload)
      return {
        ...state,
        user: action.payload.status ? {
          ...state.user,
          ...action.payload.data,
          isLoading: false,
          status: action.payload.status,
          msg: action.payload.msg,
        } : {
          ...state.user,

        },
        register: {
          ...state.register,
          ...action.payload.data,
          isLoading: false,
          status: action.payload.status,
          msg: action.payload.msg,
        }
      };
    case Types.REGISTER_ERROR:

      return {
        ...state,
        register: {
          ...state.register,
          isLoading: false,
          status: false,
          msg: action.payload.msg,
        }
      };
    ///////////
    case Types.ME:
      return {
        ...state,
        me: {
          user: {},
          isLoading: true,
          status: false,
          msg: null
        }
      };
    case Types.ME_SUCCESS:
      return {
        ...state,
        me: {
          ...state.me,
          user: action.payload.data.user,
          type: action.payload.type,
          isLoading: false,
          status: action.payload.status,
          msg: action.payload.msg,
        }
      };
    case Types.ME_ERROR:

      return {
        ...state,
        me: {
          ...state.me,
          isLoading: false,
          status: false,
          msg: action.payload.msg,
          user: {},
        }
      };
    case Types.SET_ME:
      return {
        ...state,
        me: {
          ...state.me,
          user: {...action.payload},
        }
      };
    ///////////
    case Types.LOGIN:
      return {
        ...state,
        user: {
          ...state.user,
          isLoading: true,
          status: false,
          msg: null,
          user: {},
          token: null
        }
      };
    case Types.LOGIN_SUCCESS:
      return {
        ...state,
        user: {
          ...state.user,
          ...action.payload.data,
          isLoading: false,
          status: action.payload.status,
          msg: action.payload.msg,
        },
        me: action.payload.status ? {
          ...state.user,
          ...action.payload.data,
          isLoading: false,
          status: action.payload.status,
          msg: action.payload.msg,
        } : {
          ...state.me,

        },
      };
    case Types.LOGIN_ERROR:
      return {
        ...state,
        user: {
          ...state.user,
          isLoading: false,
          status: false,
          msg: action.payload.msg,
        }
      };
    /////////
    case Types.CREATE_PAYMENT_WILDCORE:
      return {
        ...state,
        createPaymentWildCoreWildCore: {
          ...state.createPaymentWildCore,
          isLoading: true,
          status: false,
          msg: null,
        }
      };
    case Types.CREATE_PAYMENT_WILDCORE_SUCCESS:
      if (action.payload.status == true)
        state.userPaymentWildCore.data = [action.payload.data].concat(state.userPaymentWildCore.data)

      return {
        ...state,
        userPaymentWildCore: {
          ...state.userPaymentWildCore,
          data: state.userPaymentWildCore.data
        },
        createPaymentWildCore: {
          ...state.createPaymentWildCore,
          ...action.payload,
          isLoading: false,
        }
      };
    case Types.CREATE_PAYMENT_WILDCORE_ERROR:
      return {
        ...state,
        createPaymentWildCore: {
          ...state.createPaymentWildCore,
          ...action.payload,
          isLoading: false,
          status: false
        }
      };
    /////////
    case Types.DELETE_PAYMENT_WILDCORE:
      return {
        ...state,
        deletePaymentWildCore: {
          ...state.deletePaymentWildCore,
          isLoading: true,
          status: false,
          msg: null,
        }
      };
    case Types.DELETE_PAYMENT_WILDCORE_SUCCESS:
      if (action.payload.status == true) {
        state.userPaymentWildCore.data = state.userPaymentWildCore.data.filter(payment => payment._id !== action.payload.data._id)
      }

      return {
        ...state,
        userPaymentWildCore: {
          ...state.userPaymentWildCore,
          data: state.userPaymentWildCore.data
        },
        deletePaymentWildCore: {
          ...state.deletePaymentWildCore,
          ...action.payload,
          isLoading: false,
        }
      };
    case Types.DELETE_PAYMENT_WILDCORE_ERROR:
      return {
        ...state,
        deletePaymentWildCore: {
          ...state.deletePaymentWildCore,
          ...action.payload,
          isLoading: false,
          status: false
        }
      };
    //////////
    case Types.APPROVE_PAYMENT_WILDCORE:
      return {
        ...state,
        appovePaymentWildCore: {
          ...state.appovePaymentWildCore,
          isLoading: true,
          status: false,
          msg: null,
        }
      };
    case Types.APPROVE_PAYMENT_WILDCORE_SUCCESS:
      if (action.payload.status == true) {
        let objIndex = state.userPaymentWildCore.data.findIndex((obj => obj._id == action.payload.data._id));
        state.userPaymentWildCore.data[objIndex] = action.payload.data
      }

      return {
        ...state,
        userPaymentWildCore: {
          ...state.userPaymentWildCore,
          data: [...state.userPaymentWildCore.data]
        },
        appovePaymentWildCore: {
          ...state.appovePaymentWildCore,
          ...action.payload,
          isLoading: false,
        }
      };
    case Types.APPROVE_PAYMENT_WILDCORE_ERROR:
      return {
        ...state,
        appovePaymentWildCore: {
          ...state.appovePaymentWildCore,
          ...action.payload,
          isLoading: false,
          status: false
        }
      };
    //////////
    case Types.GET_PAYMENT_WILDCORE:
      return {
        ...state,
        userPaymentWildCore: {
          ...state.userPaymentWildCore,
          isLoading: true,
          status: false,
          msg: null,
        }
      };
    case Types.GET_PAYMENT_WILDCORE_SUCCESS:
      return {
        ...state,
        userPaymentWildCore: {
          ...state.userPaymentWildCore,
          ...action.payload.data,
          isLoading: false,
          status: action.payload.status,
          msg: action.payload.msg
        }
      };
    case Types.GET_PAYMENT_WILDCORE_ERROR:
      return {
        ...state,
        userPaymentWildCore: {
          ...state.userPaymentWildCore,
          status: false,
          isLoading: false,
          msg: action.payload.msg
        }
      };
    /////////
    case Types.CREATE_PAYMENT_WITHDRAW:
      return {
        ...state,
        createPaymentWithdraw: {
          ...state.createPaymentWithdraw,
          isLoading: true,
          status: false,
          msg: null,
        }
      };
    case Types.CREATE_PAYMENT_WITHDRAW_SUCCESS:
      if (action.payload.status == true)
        state.userPaymentWithdraw.data = [action.payload.data].concat(state.userPaymentWithdraw.data)

      return {
        ...state,
        userPaymentWithdraw: {
          ...state.userPaymentWithdraw,
          data: state.userPaymentWithdraw.data
        },
        createPaymentWithdraw: {
          ...state.createPaymentWithdraw,
          ...action.payload,
          isLoading: false,
        }
      };
    case Types.CREATE_PAYMENT_WITHDRAW_ERROR:
      return {
        ...state,
        createPaymentWithdraw: {
          ...state.createPaymentWithdraw,
          ...action.payload,
          isLoading: false,
          status: false
        }
      };
    /////////
    case Types.APPROVE_PAYMENT_WITHDRAW:
      return {
        ...state,
        appovePaymentWithdraw: {
          ...state.appovePaymentWithdraw,
          isLoading: true,
          status: false,
          msg: null,
        }
      };
    case Types.APPROVE_PAYMENT_WITHDRAW_SUCCESS:
      if (action.payload.status == true) {
        let objIndex = state.userPaymentWithdraw.data.findIndex((obj => obj._id == action.payload.data._id));
        state.userPaymentWithdraw.data[objIndex] = action.payload.data
      }

      return {
        ...state,
        userPaymentWithdraw: {
          ...state.userPaymentWithdraw,
          data: [...state.userPaymentWithdraw.data]
        },
        appovePaymentWithdraw: {
          ...state.appovePaymentWithdraw,
          ...action.payload,
          isLoading: false,
        }
      };
    case Types.APPROVE_PAYMENT_WITHDRAW_ERROR:
      return {
        ...state,
        appovePaymentWithdraw: {
          ...state.appovePaymentWithdraw,
          ...action.payload,
          isLoading: false,
          status: false
        }
      };
    //////////
    case Types.GET_PAYMENT_WITHDRAW:
      return {
        ...state,
        userPaymentWithdraw: {
          ...state.userPaymentWithdraw,
          isLoading: true,
          status: false,
          msg: null,
        }
      };
    case Types.GET_PAYMENT_WITHDRAW_SUCCESS:
      return {
        ...state,
        userPaymentWithdraw: {
          ...state.userPaymentWithdraw,
          ...action.payload.data,
          isLoading: false,
          status: action.payload.status,
          msg: action.payload.msg
        }
      };
    case Types.GET_PAYMENT_WITHDRAW_ERROR:
      return {
        ...state,
        userPaymentWithdraw: {
          ...state.userPaymentWithdraw,
          status: false,
          isLoading: false,
          msg: action.payload.msg
        }
      };
    /////////
    case Types.LOG_OUT:
      return {
        ...state,
        logout: {
          ...state.logout,
          status: false,
          isLoading: true,
          msg: null
        }
      };
    case Types.LOG_OUT_SUCCESS:
      return {
        ...state,
        me: {
          user: null,
          msg: action.payload.msg,
          status: false,
          isLoading: false,
        },
        logout: {
          ...state.logout,
          ...action.payload
        }
      };
    case Types.LOG_OUT_ERROR:
      return {
        ...state,
        logout: {
          ...state.logout,
          status: false,
          isLoading: false,
          msg: action.payload.msg
        }
      };
    /////////
    case Types.GET_USER:
      return {
        ...state,
        userList: {
          ...state.userList,
          payload: action.payload,
          status: false,
          isLoading: true,
          msg: null,
        }
      };
    case Types.GET_USER_SUCCESS:
      let { data, msg, status } = action.payload;
      const { current_page, last_page, total, per_page, } = data
      return {
        ...state,
        userList: {
          ...state.userList,
          data: data.data || [],
          msg: msg,
          isLoading: false,
          status,
          current_page,
          last_page,
          per_page,
          total
        },
      };
    case Types.GET_USER_ERROR:
      return {
        ...state,
        userList: {
          ...state.userList,
          status: false,
          isLoading: false,
          msg: action.payload.msg
        }
      };
    /////////
    case Types.UPDATE_USER:
      return {
        ...state,
        updateUser: {
          ...state.updateUser,
          isLoading: true,
          status: false,
          msg: null,
        }
      };
    case Types.UPDATE_USER_SUCCESS:
      if (action.payload.status == true) {
        let objIndex = state.userList.data.findIndex((obj => obj._id == action.payload.data._id));
        state.userList.data[objIndex] = action.payload.data
      }
      return {
        ...state,
        userList: {
          ...state.userList,
          data: [...state.userList.data]
        },
        updateUser: {
          ...state.updateUser,
          ...action.payload,

          isLoading: false,
        }
      };
    case Types.UPDATE_USER_ERROR:
      return {
        ...state,
        updateUser: {
          ...state.updateUser,
          ...action.payload,
          isLoading: false,
          status: false
        }
      };
    /////////
    case Types.REGISTER_CTV:
      return {
        ...state,

        registerCtv: {
          ...state.registerCtv,
          isLoading: true,
          status: false,
          msg: null,
        }
      };
    case Types.REGISTER_CTV_SUCCESS:

      return {
        ...state,
        registerCtv: {
          ...state.registerCtv,
          ...action.payload.data,
          isLoading: false,
          status: action.payload.status,
          msg: action.payload.msg,
        }
      };
    case Types.REGISTER_CTV_ERROR:
      return {
        ...state,
        registerCtv: {
          ...state.registerCtv,
          isLoading: false,
          status: false,
          msg: action.payload.msg,
        }
      };
      case Types.CHANGE_PASSWORD:
        return {
          ...state,
          changePassword: {
            ...state.changePassword,
            isLoading: true,
            status: false,
            msg: null,
            user: {},
            token: null
          }
        };
      case Types.CHANGE_PASSWORD_SUCCESS:
        if (action.payload.status) {
          state.me.user = action.payload.data.user
          localStorage.setItem("skintc_token", action.payload.data.token)
          httpClient.defaults.headers.common['Authorization'] = 'Bearer ' + action.payload.data.token;
  
        }
        return {
          ...state,
          // me: {
          //   ...state.me,
          //   user: state.me.user,
          // },
          changePassword: {
            ...state.changePassword,
            ...action.payload,
            isLoading: false,
          }
        };
      case Types.CHANGE_PASSWORD_ERROR:
        return {
          ...state,
          changePassword: {
            ...state.changePassword,
            isLoading: false,
            status: false,
            msg: action.payload.msg,
          }
        };
    /////////
    case Types.RESET_USER:
      let newState = {}
      if (action.payload.remove[0] == "all")
        newState = initState
      else
        for (let [key, value] of Object.entries(state)) {
          if (action.payload.remove.includes(key))
            newState[key] = { ...initState[key] }
          else newState[key] = value
        };
      return newState

    default:
      return state;
  }
}
