import { all } from "redux-saga/effects";
import accountSaga from "./modules/account/saga";
import boostSaga from "./modules/boost/saga";
import categorySaga from "./modules/category/saga";
import depositSaga from "./modules/deposit/saga";
import discountSaga from "./modules/discount/saga";
import itemSaga from "./modules/item/saga";
import settingSaga from "./modules/setting/saga";
import userSaga from "./modules/user/saga";
import wheelSaga from "./modules/wheel/saga";

/**
 * Root saga
 * @returns {IterableIterator<AllEffect | GenericAllEffect<any> | *>}
 */
export default function* rootSagas() {
  yield all([
    userSaga(),
    categorySaga(),
    accountSaga(),
    itemSaga(),
    wheelSaga(),
    boostSaga(),
    discountSaga(),
    depositSaga(),
    settingSaga()
  ]);
}
