import { httpClient as request } from "../../utils/Api";
// router.post("/items/add_item", auth, items.add_item);
// router.post("/items/update_item", auth, items.update_item);
// router.post("/items/delete_item", auth, items.delete_item);
// router.post("/items/buy_item", auth, items.buy_item);
// router.post("/items/get_item", auth, items.get_item);
export const add_item = payload => {
  return request.post("/item/add_item", payload)
};

export const update_item = payload => {
  return request.post("/item/update_item", payload)
};

export const buy_item = payload => {
  return request.post("/item/buy_item", payload)
};

export const delete_item = payload => {
  return request.post("/item/delete_item", payload)
};

export const get_items = payload => {
  return payload.dashboard ? request.post("/item/get_items", payload) :request.post("/item/get_items_user", payload)
};

export const get_historys = payload => {
  return request.post("/item/get_historys", payload)
};

export const delete_history = payload => {
  return request.post("/item/delete_history", payload)
};

export const update_history = payload => {
  return request.post("/item/update_history", payload)
};
export const get_item_price = payload => {
  return request.post("/item/get_item_price", payload)
};

export const get_item_price_type = payload => {
  return request.post("/item/get_item_price_type", payload)
};

export const add_item_price_type = payload => {
  return request.post("/item/add_item_price_type", payload)
};
export const delete_item_price_type = payload => {
  return request.post("/item/delete_item_price_type", payload)
};
export const update_item_price_type = payload => {
  return request.post("/item/update_item_price_type", payload)
};