import * as types from "./constants";
// router.post("/category/create_category", auth, categorys.create_category);
// router.post("/category/update_category", auth, categorys.update_category);
// router.post("/category/delete_category", auth, categorys.delete_category);
// router.post("/category/get_categorys", auth, categorys.get_categorys);

export function create_category(payload) {
  return {
    type: types.CREATE_CATEGORY,
    payload,
  };
}
export function update_category(payload) {
  return {
    type: types.UPDATE_CATEGORY,
    payload,
  };
}
export function delete_category(payload) {
  return {
    type: types.DELETE_CATEGORY,
    payload,
  };
}
export function get_categorys(payload) {
  return {
    type: types.GET_CATEGORY,
    payload,
  };
}
export function reset_category(payload) {
  return {
    type: types.RESET_CATEGORY,
    payload,
  };
}
