import { DeleteOutlined, LogoutOutlined, PlusOutlined, QuestionCircleOutlined, SaveOutlined, KeyOutlined } from "@ant-design/icons";
import { Badge, Button, Checkbox, Col, Divider, Input, message, Modal, Popconfirm, Row, Select, Space, Tabs, Tooltip } from "antd";
import 'moment/locale/vi';
import { useEffect, useState } from "react";
import { isMobile } from 'react-device-detect';
import { useDispatch, useSelector } from "react-redux";
import { useDebounce } from 'use-debounce';
import { curencyFormat, NumericInput, uuidv4Short } from "../../../utils/help";

import { convertFromRaw, convertToRaw, EditorState } from 'draft-js';
import RichEditor from "../../../components/RichEditor";
import Tags from "../../../components/Tags";
import { setting_get, setting_set } from "../../../modules/setting/actions";

const { Option } = Select;
const { TextArea } = Input;
const Accounts_History = (props) => {
   let { isVisibleSetting } = props.state
   const dispatch = useDispatch();
   const _settingList = useSelector(({ setting }) => setting.settingList);
   const _settingSet = useSelector(({ setting }) => setting.settingSet);

   let initialState = {
      page: 0,
      select: null,

      historyNote: null,
      note: null,

      search: null,
      owner: null,
      type: null,

      hasSearch: false,
      hasOwner: false,
      hasType: false,

      isVisibleConfirmDelete: false,
      riot_account: {
         list: [],
         current_index: 0
      },
      share_profit: {},
      sell_count: {},
      boost_profit: {},
      ctv_fee: {},
      deposit_bank: {
         bank_list: [],
         rate: 0,
         status: false
      },
      deposit_momo: {
         account_name: null,
         account_number: null,
         token: null,
         rate: 0,
         status: false
      },
      deposit_card: {
         partner_id: null,
         partner_key: null,
         card_list: [],
         status: false
      },
      link: {},

      keyTab: "1"
   }
   const [state, setState] = useState(initialState)
   const [search] = useDebounce(state.search, 1000);

   useEffect(() => {
      dispatch(setting_get({
         request_type: 0
      }))

   }, [isVisibleSetting])
   useEffect(() => {
      if (_settingList.status) {
         let riot_account = _settingList.data.length && _settingList.data.find(item => item.key == "riot_account")
         //  let sell_count = _settingList.data.length && _settingList.data.find(item => item.key == "sell_count")?.value
         let share_profit = _settingList.data.length && _settingList.data.find(item => item.key == "share_profit")
         let sell_count = _settingList.data.length && _settingList.data.find(item => item.key == "sell_count")
         let boost_profit = _settingList.data.length && _settingList.data.find(item => item.key == "boost_profit")
         let ctv_fee = _settingList.data.length && _settingList.data.find(item => item.key == "ctv_fee")
         let deposit_bank = _settingList.data.length && _settingList.data.find(item => item.key == "deposit_bank")
         let deposit_card = _settingList.data.length && _settingList.data.find(item => item.key == "deposit_card")
         let deposit_momo = _settingList.data.length && _settingList.data.find(item => item.key == "deposit_momo")
         let rank_price = _settingList.data.length && _settingList.data.find(item => item.key == "rank_price")
         let rank_date = _settingList.data.length && _settingList.data.find(item => item.key == "rank_date")
         let contact = _settingList.data.length && _settingList.data.find(item => item.key == "contact")
         let link = _settingList.data.length && _settingList.data.find(item => item.key == "link")
         let popup = _settingList.data.length && _settingList.data.find(item => item.key == "popup")
         let home = {
            content: popup.value.home.content ? EditorState.createWithContent(convertFromRaw(JSON.parse(popup.value.home.content))) : EditorState.createEmpty(),
            status: popup.value.home.status
         }
         let boost = {
            content: popup.value.boost.content ? EditorState.createWithContent(convertFromRaw(JSON.parse(popup.value.boost.content))) : EditorState.createEmpty(),
            status: popup.value.boost.status
         }
         let config_telegram = _settingList.data.length && _settingList.data.find(item => item.key == "config_telegram")
         let wildcore = _settingList.data.length && _settingList.data.find(item => item.key == "wildcore")

         setState({
            ...state,
            riot_account: { ...riot_account.value, index: _settingList.data.indexOf(riot_account) },
            share_profit: { value: share_profit.value, description: share_profit.description, index: _settingList.data.indexOf(share_profit) },
            sell_count: { value: sell_count.value, description: sell_count.description, index: _settingList.data.indexOf(sell_count) },
            boost_profit: { value: boost_profit.value, description: boost_profit.description, index: _settingList.data.indexOf(boost_profit) },
            ctv_fee: { value: ctv_fee.value, description: ctv_fee.description, index: _settingList.data.indexOf(ctv_fee) },
            deposit_bank: { ...deposit_bank.value, index: _settingList.data.indexOf(deposit_bank) },
            deposit_card: { ...deposit_card.value, index: _settingList.data.indexOf(deposit_card) },
            deposit_momo: { ...deposit_momo.value, index: _settingList.data.indexOf(deposit_momo) },
            rank_price: { list: rank_price.value, index: _settingList.data.indexOf(rank_price) },
            rank_date: { list: rank_date.value, index: _settingList.data.indexOf(rank_date) },
            contact: { list: contact.value, index: _settingList.data.indexOf(contact) },
            link: { list: link.value, description: link.description, index: _settingList.data.indexOf(link) },
            popup: { list: { home, boost }, description: popup.description, index: _settingList.data.indexOf(popup) },
            config_telegram: { list: config_telegram?.value, description: config_telegram?.description, index: _settingList.data.indexOf(config_telegram) },
            wildcore: { list: wildcore?.value, description: wildcore?.description, index: _settingList.data.indexOf(wildcore) },

         })
      }
      else if (_settingList.status == false && _settingList.msg !== null) {
         message.warning("Không tìm thấy cấu hình server")
      }
   }, [_settingList])

   useEffect(() => {
      if (_settingSet.status) {
         message.success(_settingSet.msg)
      }
      else if (_settingSet.status == false && _settingSet.msg !== null) {
         message.warning(_settingSet.msg)
      }
   }, [_settingSet])


   const handleAddMoreList = () => {
      setState({ ...state, riot_account: { ...state.riot_account, list: [...state.riot_account.list, { ...{ name: "", key: uuidv4Short(), item: [] } }] } })
   }
   const handleSave = () => {
   
      if (state.riot_account?.list?.length == 0 || typeof state.riot_account?.list == "undefined")
         return message.warning("Vui lòng thêm list danh sách account riot kết bạn")
      let riot_account_result = null
      state.riot_account?.list.map((list, index) => {
         if (list.name == "" || !list.name || list.item.length == 0)
            riot_account_result = index
      })
      if (riot_account_result !== null)
         return message.warning(`List số ${riot_account_result + 1} đang cấu hình sai`)
      if (isNaN(state.riot_account.current_index)) return message.warning("Vui lòng chọn list để người dùng kết bạn")

      /////////////////
      if (!state.share_profit.value || state.share_profit.value == "" || isNaN(state.share_profit.value))
         return message.warning("Cấu hình % giftcode sai")
      if (!state.sell_count.value || state.sell_count.value == "" || isNaN(state.sell_count.value))
         return message.warning("Cấu hình đơn rút tiền sai")
      if (!state.boost_profit.value || state.boost_profit.value == "" || isNaN(state.boost_profit.value))
         return message.warning("Cấu hình % lợi nhuận CTV sai")
      if (!state.ctv_fee.value || state.ctv_fee.value == "" || isNaN(state.ctv_fee.value))
         return message.warning("Cấu hình phí đăng ký CTV sai")
      ////////////////
      if (state.deposit_bank?.bank_list?.length == 0 || typeof state.deposit_bank?.bank_list == "undefined")
         return message.warning("Vui lòng thêm tài khoản ngân hàng")
      let deposit_bank_result = null
      state.deposit_bank?.bank_list.map((list, index) => {
         if (!list.account_name || !list.account_number || !list.token || !list.password || !list.bank_name) {
            deposit_bank_result = index
         }
      })
      if (deposit_bank_result !== null) return message.warning(`Taì khoản số ${deposit_bank_result + 1} đang cấu hình sai`)
      if (!state.deposit_bank.rate || isNaN(state.deposit_bank.rate)) return message.warning("Vui lòng nhập rate nạp tiền ngân hàng")
      ///////////////
      if (!state.deposit_momo.account_name || !state.deposit_momo.account_number || !state.deposit_momo.token || !state.deposit_momo.password) {
         return message.warning("Cáu hình momo sai")
      }
      if (state.riot_account?.list?.length == 0 || typeof state.riot_account?.list == "undefined")
         return message.warning("Vui lòng thêm list danh sách account riot kết bạn")
      ///////////////
      if (state.deposit_card?.card_list?.length == 0 || typeof state.deposit_card?.card_list == "undefined")
         return message.warning("Vui lòng thêm list danh sách loại thẻ cào")
      let deposit_card_result = null
      state.deposit_card?.card_list.map((list, index) => {
         if (list.amount == "" || !list.rate || isNaN(list.amount) || isNaN(list.rate))
            deposit_card_result = index
      })
      if (deposit_card_result !== null)
         return message.warning(`Loại thẻ cào số ${riot_account_result + 1} đang cấu hình sai`)
      if (!state.deposit_card.partner_id || !state.deposit_card.partner_key || state.deposit_card?.telco_list?.length == 0 || typeof state.deposit_card?.telco_list == "undefined")
         return message.warning("Cấu hình thẻ cào sai")
      /////////////////
      let rank_price_result = null
      Object.keys(state.rank_price?.list).map((key, index) => {
         let item = state.rank_price.list[key]
         if ((!item.price || isNaN(item.price)) && key !== "iron_4") {
            rank_price_result = item.name
            return
         }
      })

      if (rank_price_result !== null)
         return message.warning(`Giá cày thuê ${rank_price_result} đang cấu hình sai`)

      /////////////////
      let rank_date_result = null
      Object.keys(state.rank_date?.list).map((key, index) => {
         let item = state.rank_date.list[key]
         // //console.log(key,item)
         if (isNaN(item) || item == "" || item == null || item == "undefined") {
            rank_date_result = key
            return
         }
      })
      if (rank_date_result !== null)
         return message.warning(`Ngày cày thuê ${rank_date_result?.toUpperCase()} đang cấu hình sai`)
      /////////////////
      let contact_result = null
      Object.keys(state.contact?.list).map((key, index) => {
         let item = state.contact.list[key]
         // //console.log(key, item)
         if (item == "" || item == null || item == "undefined") {
            contact_result = key
            return
         }
      })

      if (contact_result !== null)
         return message.warning(`Setting ${contact_result} đang cấu hình sai`)
      /////////////////
      let link_result = null
      Object.keys(state.link?.list).map((key, index) => {
         let item = state.link.list[key]
         if (typeof item == "object")
            if (item.url == "" || item.url == null || item.url == "undefined" || item.name == "" || item.name == null || item.name == "undefined") {
               link_result = key
               return
            }
            else if (item == "") {
               link_result = key
               return
            }
      })

      if (link_result !== null)
         return message.warning(`Setting ${link_result} đang cấu hình sai`)
      ///////////////
      if (state.config_telegram?.list?.token == null || state.config_telegram?.list?.token == "" || state.config_telegram?.list?.token == undefined) 
         return message.warning(`Setting telegram token đang cấu hình sai`)
      if (state.config_telegram?.list?.pass == null || state.config_telegram?.list?.pass == "" || state.config_telegram?.list?.pass == undefined) 
         return message.warning(`Setting telegram token đang cấu hình sai`)

      ///////////////
      let popup_result = null
      let popup_data = {}
      Object.keys(state.popup?.list).map((key, index) => {
         let item = state.popup.list[key]
  
            if (item.content == "" || item.content == null || item.content == "undefined" || typeof item.content !== "object") {
               popup_result = key
               return
            }
            let itemx = {...item}
            if(itemx?.content)
            itemx.content = JSON.stringify(convertToRaw(itemx.content.getCurrentContent()))
            popup_data[key] = itemx
          
      })

      if (popup_result !== null)
         return message.warning(`Setting popup ${popup_result} đang cấu hình sai`)
      ///////////////
      if (state.wildcore?.list == null || state.wildcore?.list == "" || state.wildcore?.list == undefined) 
      return message.warning(`Setting wildcore đang cấu hình sai`)

      let newSettingList = _settingList.data
      newSettingList[state.riot_account.index].value = state.riot_account
      newSettingList[state.share_profit.index].value = state.share_profit.value
      newSettingList[state.sell_count.index].value = state.sell_count.value
      newSettingList[state.boost_profit.index].value = state.boost_profit.value
      newSettingList[state.ctv_fee.index].value = state.ctv_fee.value
      newSettingList[state.deposit_bank.index].value = state.deposit_bank
      newSettingList[state.deposit_momo.index].value = state.deposit_momo
      newSettingList[state.deposit_card.index].value = state.deposit_card
      newSettingList[state.rank_price.index].value = state.rank_price.list
      newSettingList[state.rank_date.index].value = state.rank_date.list
      newSettingList[state.contact.index].value = state.contact.list
      newSettingList[state.link.index].value = state.link.list
      newSettingList[state.popup.index].value = popup_data
      newSettingList[state.config_telegram.index].value = state.config_telegram.list
      newSettingList[state.wildcore.index].value = state.wildcore.list

      dispatch(setting_set({
         settings: newSettingList
      }))
   }

   return (
      <Modal title={"Cài đặt"}
         visible={isVisibleSetting}
         style={{ top: 20 }}
         bodyStyle={{ overflowY: 'auto', maxHeight: 'calc(100vh - 200px)' }}
         destroyOnClose={true}
         maskClosable={false}
         onCancel={() => props.setState({ ...state, isVisibleSetting: false })}
         width={isMobile ? "90%" : "800px"}
         footer={<Space direction="horizontal" style={{ width: '100%', justifyContent: isMobile ? "center" : "right" }} className="gx-w-100">
            <Button
               className='color1'
               loading={_settingSet.isLoading}
               onClick={() => {
                  handleSave()
               }}
               type="primary" icon={<SaveOutlined />}>{"Lưu cấu hình"}</Button>
            <Button icon={<LogoutOutlined />} onClick={() => props.setState({ ...state, isVisibleSetting: false })} type="primary" className='color2'>Thoát</Button>
         </Space>}
      >

         <Tabs defaultActiveKey="1" onChange={(value) => setState({ ...state, keyTab: value })}>

            <Tabs.TabPane tab="Chung" key="1">
               <Divider orientation="left" orientationMargin={0}>Thông tin liên hệ</Divider>
               {
                  state.contact ? <>
                     <Row>
                        <Col xl={12} lg={12} md={24} sm={24} className={!isMobile ? "pdR" : "pdB"}>
                           <Input
                              value={state.contact.list.email} addonAfter={<div style={{ width: 80 }}>Email</div>}
                              onChange={(e) => {

                                 setState({ ...state, contact: { ...state.contact, list: { ...state.contact.list, email: e.target.value } } })
                              }}
                           />
                        </Col>
                        <Col xl={12} lg={12} md={24} sm={24} className={!isMobile ? "pdL" : "pdB"}>
                           <Input
                              value={state.contact.list.phone} addonAfter={<div style={{ width: 80 }}>Phone</div>}
                              onChange={(e) => {

                                 setState({ ...state, contact: { ...state.contact, list: { ...state.contact.list, phone: e.target.value } } })
                              }}
                           />
                        </Col>
                     </Row>
                     <Row>
                        <Col xl={12} lg={12} md={24} sm={24} className={!isMobile ? "pdR" : "pdB"}>
                           <Input
                              value={state.contact.list.telegram} addonAfter={<div style={{ width: 80 }}>Fanpage</div>}
                              onChange={(e) => {
                                 setState({ ...state, contact: { ...state.contact, list: { ...state.contact.list, telegram: e.target.value } } })
                              }}
                           />
                        </Col>
                        <Col xl={12} lg={12} md={24} sm={24} className={!isMobile ? "pdL" : "pdB"}>
                           <Input
                              value={state.contact.list.zalo} addonAfter={<div style={{ width: 80 }}>Zalo</div>}
                              onChange={(e) => {
                                 setState({ ...state, contact: { ...state.contact, list: { ...state.contact.list, zalo: e.target.value } } })
                              }}
                           />
                        </Col>
                     </Row>

                  </> : null
               }
               <Divider orientation="left" orientationMargin={0}>Cấu hình chung</Divider>
               <Row>
                  <Col xl={12} lg={12} md={24} sm={24} className={!isMobile ? "pdR" : "pdB"}>
                     <NumericInput prefix={
                        <Tooltip placement="bottom" title={state.share_profit.description}>
                           <QuestionCircleOutlined />
                        </Tooltip>

                     } addonAfter={<div style={{ width: 80 }}>% Giftcode</div>}
                        value={state.share_profit.value}

                        onChange={(e) => setState({ ...state, share_profit: { ...state.share_profit, value: Number(e.target.value) } })}
                     />
                  </Col>
                  <Col xl={12} lg={12} md={24} sm={24} className={!isMobile ? "pdL" : "pdB"}>
                     <NumericInput prefix={
                        <Tooltip placement="bottom" title={state.sell_count.description}>
                           <QuestionCircleOutlined />
                        </Tooltip>

                     } addonAfter={<div style={{ width: 80 }}>Đơn rút tiền</div>}
                        value={state.sell_count.value}

                        onChange={(e) => setState({ ...state, sell_count: { ...state.sell_count, value: Number(e.target.value) } })}
                     />
                  </Col>

               </Row>
               <Row>
                  <Col xl={12} lg={12} md={24} sm={24} className={!isMobile ? "pdR" : "pdB"}>
                     <NumericInput prefix={
                        <Tooltip placement="bottom" title={state.boost_profit.description}>
                           <QuestionCircleOutlined />
                        </Tooltip>

                     } addonAfter={<div style={{ width: 80 }}>% Cày thuê</div>}
                        value={state.boost_profit.value}

                        onChange={(e) => setState({ ...state, boost_profit: { ...state.boost_profit, value: Number(e.target.value) } })}
                     />
                  </Col>
                  <Col xl={12} lg={12} md={24} sm={24} className={!isMobile ? "pdL" : "pdB"}>
                     <NumericInput prefix={
                        <Tooltip placement="bottom" title={state.ctv_fee.description}>
                           <QuestionCircleOutlined />
                        </Tooltip>

                     } addonAfter={<div style={{ width: 80 }}>Phí CTV</div>}
                        value={state.ctv_fee.value}

                        onChange={(e) => setState({ ...state, ctv_fee: { ...state.ctv_fee, value: Number(e.target.value) } })}
                     />
                  </Col>
               </Row>
               <Divider orientation="left" orientationMargin={0}>Link</Divider>
               <Row>
                  <Col xl={24} lg={24} md={24} sm={24} className={"pdB"}>
                     <Input prefix={
                        <Tooltip placement="bottom" title={state.link.description}>
                           <QuestionCircleOutlined />
                        </Tooltip>

                     } addonAfter={<div style={{ width: 80 }}>Hướng Dẫn</div>}
                        value={state.link?.list?.help}

                        onChange={(e) => setState({ ...state, link: { ...state.link, list: { ...state.link.list, help: e.target.value } } })}
                     />
                  </Col>
               </Row>
               <Row>
                  <Col xl={12} lg={12} md={24} sm={24} className={!isMobile ? "pdR" : "pdB"}>
                     <Input prefix={
                        <Tooltip placement="bottom" title={state.link.description}>
                           <QuestionCircleOutlined />
                        </Tooltip>

                     } addonAfter={<div style={{ width: 80 }}>Link 1</div>}
                        value={state.link?.list?.link_1?.url}

                        onChange={(e) => {
                           let newState = state.link
                           newState.list.link_1.url = e.target.value
                           setState({ ...state, link: newState })
                        }}
                     />
                  </Col>
                  <Col xl={12} lg={12} md={24} sm={24} className={!isMobile ? "pdL" : "pdB"}>
                     <Input prefix={
                        <Tooltip placement="bottom" title={state.link.description}>
                           <QuestionCircleOutlined />
                        </Tooltip>

                     } addonAfter={<div style={{ width: 80 }}>Tên</div>}
                        value={state.link?.list?.link_1.name}

                        onChange={(e) => {
                           let newState = state.link
                           newState.list.link_1.name = e.target.value
                           setState({ ...state, link: newState })
                        }}
                     />
                  </Col>
               </Row>
               <Row>
                  <Col xl={12} lg={12} md={24} sm={24} className={!isMobile ? "pdR" : "pdB"}>
                     <Input prefix={
                        <Tooltip placement="bottom" title={state.link.description}>
                           <QuestionCircleOutlined />
                        </Tooltip>

                     } addonAfter={<div style={{ width: 80 }}>Link 2</div>}
                        value={state.link?.list?.link_2.url}

                        onChange={(e) => {
                           let newState = state.link
                           newState.list.link_2.url = e.target.value
                           setState({ ...state, link: newState })
                        }} />
                  </Col>
                  <Col xl={12} lg={12} md={24} sm={24} className={!isMobile ? "pdL" : "pdB"}>
                     <Input prefix={
                        <Tooltip placement="bottom" title={state.link.description}>
                           <QuestionCircleOutlined />
                        </Tooltip>

                     } addonAfter={<div style={{ width: 80 }}>Tên</div>}
                        value={state.link?.list?.link_2.name}
                        onChange={(e) => {
                           let newState = state.link
                           newState.list.link_2.name = e.target.value
                           setState({ ...state, link: newState })
                        }}
                     />
                  </Col>
               </Row>
               <Row>
                  <Col xl={12} lg={12} md={24} sm={24} className={!isMobile ? "pdR" : "pdB"}>
                     <Input prefix={
                        <Tooltip placement="bottom" title={state.link.description}>
                           <QuestionCircleOutlined />
                        </Tooltip>

                     } addonAfter={<div style={{ width: 80 }}>Link 3</div>}
                        value={state.link?.list?.link_3.url}

                        onChange={(e) => {
                           let newState = state.link
                           newState.list.link_3.url = e.target.value
                           setState({ ...state, link: newState })
                        }}
                     />
                  </Col>
                  <Col xl={12} lg={12} md={24} sm={24} className={!isMobile ? "pdL" : "pdB"}>
                     <Input prefix={
                        <Tooltip placement="bottom" title={state.link.description}>
                           <QuestionCircleOutlined />
                        </Tooltip>

                     } addonAfter={<div style={{ width: 80 }}>Tên</div>}
                        value={state.link?.list?.link_3.name}

                        onChange={(e) => {
                           let newState = state.link
                           newState.list.link_3.name = e.target.value
                           setState({ ...state, link: newState })
                        }}
                     />
                  </Col>
               </Row>
               {state.config_telegram ? 
                  <>
                  <Divider orientation="left" orientationMargin={0}>Cấu hình bot telegram</Divider>
               <Row>
                  <Col xl={24} lg={24} md={24} sm={24} className={"pdB"}>
                     <Input prefix={
                        <Tooltip placement="bottom" title={state.config_telegram.description}>
                           <QuestionCircleOutlined />
                        </Tooltip>

                     } addonAfter={<div style={{ width: 80 }}>Token bot</div>}
                        value={state.config_telegram?.list?.token}

                        onChange={(e) => setState({ ...state, config_telegram: { ...state.config_telegram, list: { ...state.config_telegram.list, token: e.target.value } } })}
                     />
                  </Col>
               </Row>
               <Row>
                  <Col xl={12} lg={12} md={24} sm={24} className={!isMobile ? "pdR" : "pdB"}>
                     <Input prefix={<KeyOutlined />} addonAfter={<div style={{ width: 80 }}>Mật khẩu bot</div>}
                        value={state.config_telegram?.list?.pass}
                        onChange={(e) => setState({ ...state, config_telegram: { ...state.config_telegram, list: { ...state.config_telegram.list, pass: e.target.value } } })}
                     />
                  </Col>
                  <Col xl={12} lg={12} md={24} sm={24}className={!isMobile ? "pdL" : "pdB"}>
                     <Input prefix={<KeyOutlined />} addonAfter={<div style={{ width: 80 }}>ChatId</div>}
                        disabled
                        value={state.config_telegram?.list?.chatId}
                        onChange={(e) => setState({ ...state, config_telegram: { ...state.config_telegram, list: { ...state.config_telegram.list, chatId: e.target.value } } })}
                     />
                  </Col>
                
               </Row>
               <Row>
                     <Col span={24}>
                        <p>Nhận thông báo</p>
                     <Checkbox.Group style={{ marginBottom: 10, width: "100%" }} value={state.config_telegram?.list?.enable} 
                        onChange={(e) => setState({ ...state, config_telegram: { ...state.config_telegram, list: { ...state.config_telegram.list, enable: e } } })}

                      >

                        <Checkbox  value="item">Đơn vật phẩm/Skin</Checkbox>
                        <Checkbox  value="account">Đơn account</Checkbox>
                        <Checkbox  value="boosting">Đơn cày thuê</Checkbox>
                        <Checkbox  value="wildcore">Đơn rút WC</Checkbox>
                        <Checkbox  value="withdraw">Đơn rút tiền</Checkbox>

                     </Checkbox.Group>
                     </Col>
                  </Row>
                  </> : null
               }

               {state.wildcore ? 
                  <>
                  <Divider orientation="left" orientationMargin={0}>Cấu hình rút WC</Divider>
               <Row>
                  <Col xl={24} lg={24} md={24} sm={24} className={"pdB"}>
                     <Input prefix={
                        <Tooltip placement="bottom" title={state.wildcore.description}>
                           <QuestionCircleOutlined />
                        </Tooltip>

                     } addonAfter={<div style={{ width: 80 }}>Rút WC</div>}
                        value={state.wildcore?.list}
                        placeholder="Danh sách rút WC, cách nhau |"
                        onChange={(e) => setState({ ...state, wildcore: {...state.wildcore,  list:  e.target.value } })}
                     />
                  </Col>
               </Row>
               </>
                : null}
            </Tabs.TabPane>
            <Tabs.TabPane tab="Kết bạn" key="2">
               <Row>
                  <Col span={24}>
                     <Divider orientation="left" orientationMargin={0}>Chọn danh sách kết bạn</Divider>
                     <Select
                        placeholder="Chọn list kết bạn"
                        value={state.riot_account.current_index}

                        style={{ width: "100%", marginBottom: 10, marginTop: 10 }}
                        onChange={(value) => {
                           setState({ ...state, riot_account: { ...state.riot_account, current_index: value } })
                        }}
                     >
                        {state.riot_account.list && state.riot_account.list?.length ?
                           state.riot_account.list.map((item, index) => {
                              return <Option value={index}><Badge color={"green"} size="20" style={{ marginRight: 10 }} text={item.name}></Badge></Option>

                           })
                           : null}

                     </Select>

                  </Col>
                  <Col span={24}>
                     {state.riot_account.list && state.riot_account.list?.length ?
                        state.riot_account.list.map((item, index) => {
                           return (
                              <div style={{ marginBottom: 20 }}>
                                 <Divider orientation="left" orientationMargin={0}>List {index + 1}</Divider>

                                 <div style={{ display: "flex" }}>
                                    <Input style={{ marginBottom: 10 }} value={item.name} addonAfter={"Tên list"}
                                       onChange={(e) => {
                                          let oldState = state.riot_account
                                          oldState.list[index].name = e.target.value

                                          let newState = oldState
                                          setState({ ...state, riot_account: { ...newState } })
                                       }}
                                    />
                                    <Popconfirm title="Chắc chắn muốn xóa？" okText="Yes" cancelText="No"
                                       onConfirm={() => {
                                          let newRiotAccount = state.riot_account
                                          newRiotAccount.list = state.riot_account.list.filter(list => list.key !== item.key)
                                          // if(index == state.riot_account.current_index)
                                          // {
                                          newRiotAccount.current_index = null

                                          // }
                                          setState(state => ({ ...state, riot_account: newRiotAccount }))
                                       }}

                                    >
                                       <Button danger icon={<DeleteOutlined />} onClick={() => {

                                       }} style={{ marginLeft: 10 }}>Xóa List</Button>
                                    </Popconfirm>

                                 </div>
                                 <div>
                                    <Tags btnText="Thêm Account" tags={item.item ? item.item : []} setTags={(e) => {
                                       let oldState = state.riot_account
                                       oldState.list[index].item = e
                                       let newState = oldState
                                       setState({ ...state, riot_account: { ...newState } })
                                    }} />
                                 </div>
                              </div>
                           )
                        }) : null
                     }
                  </Col>
                  <Col span={24} style={{ marginTop: 10 }}>
                     <Button icon={<PlusOutlined />} onClick={handleAddMoreList} type="dashed" style={{ width: "100%" }}>Thêm danh sách</Button>
                     {/* <Button icon={<SaveOutlined />} onClick={handleAddMoreList} type="primary" style={{ width: "100%", marginTop: 10 }}>Lưu cấu hình</Button> */}
                  </Col>
               </Row>
            </Tabs.TabPane>
            <Tabs.TabPane tab="Bank" key="3">
               <Divider orientation="left" orientationMargin={0}>Cấu hình nạp tiền ngân hàng</Divider>

               <Row>
                  <Col xl={12} lg={12} md={24} sm={24} className={!isMobile ? "pdR" : "pdB"}>
                     <NumericInput value={state.deposit_bank.rate} addonAfter={<div style={{ width: 80 }}>Rate %</div>}
                        onChange={(e) => {
                           setState({ ...state, deposit_bank: { ...state.deposit_bank, rate: Number(e.target.value) } })
                        }}
                     />
                  </Col>
                  <Col xl={12} lg={12} md={24} sm={24} className={!isMobile ? "pdL" : "pdB"}>
                     <Select
                        placeholder="Trạng thái"
                        value={state.deposit_bank.status}
                        style={{ width: "100%" }}
                        onChange={(value) => {

                           setState({ ...state, deposit_bank: { ...state.deposit_bank, status: value } })
                        }}
                     >
                        <Option value={true}><Badge color={"green"} size="20" text="Kích hoạt"></Badge></Option>
                        <Option value={false}><Badge color={"red"} size="20" text="Ngừng hoạt động"></Badge></Option>

                     </Select>
                  </Col>
                  <Col span={24}>
                     {state.deposit_bank?.bank_list && state.deposit_bank.bank_list?.length ?
                        state.deposit_bank.bank_list.map((item, index) => {
                           return (
                              <div >
                                 <Divider orientation="left" orientationMargin={0}>Tài khoản bank {index + 1}</Divider>
                                 <Row>
                                    <Col xl={12} lg={12} md={24} sm={24} className={!isMobile ? "pdR" : "pdB"}>
                                       <Select
                                          placeholder="Chọn ngân hàng"
                                          value={item.bank_name}
                                          style={{ width: "100%" }}
                                          onChange={(value) => {
                                             let oldState = state.deposit_bank
                                             oldState.bank_list[index].bank_name = value
                                             let newState = oldState
                                             setState({ ...state, deposit_bank: { ...newState } })
                                          }}
                                       >
                                          <Option value={"ACB"}><Badge color={"green"} size="20" text={"ACB"}></Badge></Option>
                                          <Option value={"TECHCOMBANK"}><Badge color={"green"} size="20" text={"TECHCOMBANK"}></Badge></Option>

                                       </Select>

                                    </Col>
                                    <Col xl={12} lg={12} md={24} sm={24} className={!isMobile ? "pdL" : "pdB"}>
                                       <Input value={item.account_name} addonAfter={<div style={{ width: 80 }}>Chủ TK</div>}
                                          onChange={(e) => {
                                             let oldState = state.deposit_bank
                                             oldState.bank_list[index].account_name = e.target.value
                                             let newState = oldState
                                             setState({ ...state, deposit_bank: { ...newState } })
                                          }}
                                       />

                                    </Col>
                                 </Row>

                                 <Row>
                                    <Col xl={12} lg={12} md={24} sm={24} className={!isMobile ? "pdR" : "pdB"}>
                                       <NumericInput value={item.account_number} addonAfter={<div style={{ width: 80 }}>Số TK</div>}
                                          onChange={(e) => {
                                             let oldState = state.deposit_bank
                                             oldState.bank_list[index].account_number = Number(e.target.value)
                                             let newState = oldState
                                             setState({ ...state, deposit_bank: { ...newState } })
                                          }}
                                       />
                                    </Col>
                                    <Col xl={12} lg={12} md={24} sm={24} className={!isMobile ? "pdL" : "pdB"}>
                                       <Input value={item.password} addonAfter={<div style={{ width: 80 }}>Mật khẩu</div>}
                                          onChange={(e) => {
                                             let oldState = state.deposit_bank
                                             oldState.bank_list[index].password = e.target.value
                                             let newState = oldState
                                             setState({ ...state, deposit_bank: { ...newState } })
                                          }}
                                       />
                                    </Col>
                                 </Row>
                                 <Row>
                                    <Col xl={12} lg={12} md={24} sm={24} className={!isMobile ? "pdR" : "pdB"}>
                                       <Input value={item.token} addonAfter={<div style={{ width: 80 }}>Token</div>}
                                          onChange={(e) => {
                                             let oldState = state.deposit_bank
                                             oldState.bank_list[index].token = e.target.value
                                             let newState = oldState
                                             setState({ ...state, deposit_bank: { ...newState } })
                                          }}
                                       />

                                    </Col>
                                    <Col xl={12} lg={12} md={24} sm={24} className={!isMobile ? "pdL" : "pdB"}>
                                       <Select
                                          placeholder="Trạng thái"
                                          value={item.status}
                                          style={{ width: "100%" }}
                                          onChange={(value) => {
                                             let oldState = state.deposit_bank
                                             oldState.bank_list[index].status = value
                                             let newState = oldState
                                             setState({ ...state, deposit_bank: { ...newState } })
                                          }}
                                       >
                                          <Option value={true}><Badge color={"green"} size="20" text="Kích hoạt"></Badge></Option>
                                          <Option value={false}><Badge color={"red"} size="20" text="Ngừng hoạt động"></Badge></Option>

                                       </Select>

                                    </Col>
                                    <Col span={24}>
                                       <Space direction="horizontal" style={{ width: '100%', justifyContent: isMobile ? "center" : "right" }} className="gx-w-100">

                                          <Popconfirm title="Chắc chắn muốn xóa？" okText="Yes" cancelText="No"
                                             onConfirm={() => {
                                                let newState = state.deposit_bank
                                                newState.bank_list = state.deposit_bank.bank_list.filter(list => list.account_number !== item.account_number)

                                                setState(state => ({ ...state, deposit_bank: newState }))
                                             }}

                                          >
                                             <Button danger size="small" icon={<DeleteOutlined />} >Xóa tài khoản</Button>
                                          </Popconfirm>
                                       </Space>

                                    </Col>
                                 </Row>



                              </div>
                           )
                        }) : null
                     }
                  </Col>
                  <Col span={24} style={{ marginTop: 10 }}>
                     <Button icon={<PlusOutlined />} onClick={() => {
                        setState({ ...state, deposit_bank: { ...state.deposit_bank, bank_list: [...state.deposit_bank.bank_list, { ...{ account_name: "", account_number: "", bank_name: null, status: false, token: null } }] } })
                     }} type="dashed" style={{ width: "100%" }}>Thêm tài khoản</Button>
                     {/* <Button icon={<SaveOutlined />} onClick={handleAddMoreList} type="primary" style={{ width: "100%", marginTop: 10 }}>Lưu cấu hình</Button> */}
                  </Col>
               </Row>
            </Tabs.TabPane>
            <Tabs.TabPane tab="Momo" key="4">
               <Divider orientation="left" orientationMargin={0}>Cấu hình nạp tiền momo</Divider>
               <Row>
                  <Col xl={12} lg={12} md={24} sm={24} className={!isMobile ? "pdR" : "pdB"}>
                     <Input value={state.deposit_momo.account_name} addonAfter={<div style={{ width: 80 }}>Chủ TK</div>}
                        onChange={(e) => {
                           setState({ ...state, deposit_momo: { ...state.deposit_momo, account_name: e.target.value } })
                        }}
                     />
                  </Col>
                  <Col xl={12} lg={12} md={24} sm={24} className={!isMobile ? "pdL" : "pdB"}>
                     <NumericInput value={state.deposit_momo.account_number} addonAfter={<div style={{ width: 80 }}>Số ĐT</div>}
                        onChange={(e) => {
                           setState({ ...state, deposit_momo: { ...state.deposit_momo, account_number: e.target.value } })
                        }}
                     />
                  </Col>
               </Row>
               <Row>
                  <Col xl={12} lg={12} md={24} sm={24} className={!isMobile ? "pdR" : "pdB"}>
                     <Input value={state.deposit_momo.token} addonAfter={<div style={{ width: 80 }}>Token</div>}
                        onChange={(e) => {
                           setState({ ...state, deposit_momo: { ...state.deposit_momo, token: e.target.value } })
                        }}
                     />
                  </Col>
                  <Col xl={12} lg={12} md={24} sm={24} className={!isMobile ? "pdL" : "pdB"}>
                     <NumericInput value={state.deposit_momo.password} addonAfter={<div style={{ width: 80 }}>Password</div>}
                        onChange={(e) => {
                           setState({ ...state, deposit_momo: { ...state.deposit_momo, password: e.target.value } })
                        }}
                     />
                  </Col>
               </Row>
               <Row>
                  <Col xl={12} lg={12} md={24} sm={24} className={!isMobile ? "pdR" : "pdB"}>
                     <NumericInput value={state.deposit_momo.rate} addonAfter={<div style={{ width: 80 }}>Rate %</div>}
                        onChange={(e) => {
                           setState({ ...state, deposit_momo: { ...state.deposit_momo, rate: Number(e.target.value) } })
                        }}
                     />
                  </Col>
                  <Col xl={12} lg={12} md={24} sm={24} className={!isMobile ? "pdL" : "pdB"}>
                     <Select
                        placeholder="Trạng thái"
                        value={state.deposit_momo.status}
                        style={{ width: "100%" }}
                        onChange={(value) => {

                           setState({ ...state, deposit_momo: { ...state.deposit_momo, status: value } })
                        }}
                     >
                        <Option value={true}><Badge color={"green"} size="20" text="Kích hoạt"></Badge></Option>
                        <Option value={false}><Badge color={"red"} size="20" text="Ngừng hoạt động"></Badge></Option>

                     </Select>
                  </Col>
               </Row>
            </Tabs.TabPane>
            <Tabs.TabPane tab="Card" key="5">
               <Divider style={{ marginTop: "-10px" }} orientation="left" orientationMargin={0}>Cấu hình nạp tiền thẻ cào</Divider>

               <Row>
                  <Col xl={12} lg={12} md={24} sm={24} className={!isMobile ? "pdR" : "pdB"}>
                     <Input value={state.deposit_card.partner_id} addonAfter={<div style={{ width: 80 }}>Partner Id</div>}
                        onChange={(e) => {
                           setState({ ...state, deposit_card: { ...state.deposit_card, partner_id: e.target.value } })
                        }}
                     />
                  </Col>
                  <Col xl={12} lg={12} md={24} sm={24} className={!isMobile ? "pdL" : "pdB"}>
                     <Input value={state.deposit_card.partner_key} addonAfter={<div style={{ width: 80 }}>Partner Key</div>}
                        onChange={(e) => {
                           setState({ ...state, deposit_card: { ...state.deposit_card, partner_key: e.target.value } })
                        }}
                     />

                  </Col>

                  <Col xl={12} lg={12} md={24} sm={24} className={!isMobile ? "pdR" : "pdB"}>
                     <Select
                        placeholder="Nhà mạng"
                        value={state.deposit_card.telco_list}
                        style={{ width: "100%" }}
                        allowClear
                        // tagRender={tagRender}
                        mode="multiple"
                        onChange={(value) => {

                           setState({ ...state, deposit_card: { ...state.deposit_card, telco_list: value } })
                        }}
                     >
                        <Option value={"VIETTEL"}><Badge color={"green"} size="20" text="VIETTEL"></Badge></Option>
                        <Option value={"MOBIFONE"}><Badge color={"green"} size="20" text="MOBI"></Badge></Option>
                        <Option value={"VINAPHONE"}><Badge color={"green"} size="20" text="VINA"></Badge></Option>

                     </Select>
                  </Col>
                  <Col xl={12} lg={12} md={24} sm={24} className={!isMobile ? "pdL" : "pdB"}>
                     <Select
                        placeholder="Trạng thái"
                        value={state.deposit_card.status}
                        style={{ width: "100%" }}
                        onChange={(value) => {

                           setState({ ...state, deposit_card: { ...state.deposit_card, status: value } })
                        }}
                     >
                        <Option value={true}><Badge color={"green"} size="20" text="Kích hoạt"></Badge></Option>
                        <Option value={false}><Badge color={"red"} size="20" text="Ngừng hoạt động"></Badge></Option>

                     </Select>
                  </Col>

                  <Col span={24}>
                     {state.deposit_card?.card_list && state.deposit_card.card_list?.length ?
                        state.deposit_card.card_list.map((item, index) => {
                           return (
                              <div >
                                 <Divider orientation="left" orientationMargin={0}>Loại thẻ {index + 1}</Divider>
                                 <Row>

                                    <Col xl={12} lg={12} md={24} sm={24} className={!isMobile ? "pdR" : "pdB"}>
                                       <NumericInput value={item.amount} addonAfter={<div style={{ width: 80 }}>Số tiền</div>}
                                          onChange={(e) => {
                                             let oldState = state.deposit_card
                                             oldState.card_list[index].amount = Number(e.target.value)
                                             let newState = oldState
                                             setState({ ...state, deposit_card: { ...newState } })
                                          }}
                                       />

                                    </Col>
                                    <Col xl={12} lg={12} md={24} sm={24} className={!isMobile ? "pdL" : "pdB"}>
                                       <NumericInput value={item.rate} addonAfter={<div style={{ width: 80 }}>Rate %</div>}
                                          onChange={(e) => {
                                             let oldState = state.deposit_card
                                             oldState.card_list[index].rate = Number(e.target.value)
                                             let newState = oldState
                                             setState({ ...state, deposit_card: { ...newState } })
                                          }}
                                       />

                                    </Col>
                                 </Row>

                                 <Row>
                                    <Col xl={12} lg={12} md={24} sm={24} className={!isMobile ? "pdR" : "pdB"}>
                                       <Input disabled value={`Thẻ cào ${curencyFormat(item.amount)} - Nhận ${item.rate}%`} addonAfter={<div style={{ width: 80 }}>Label</div>}

                                       />

                                    </Col>
                                    <Col xl={12} lg={12} md={24} sm={24} className={!isMobile ? "pdL" : "pdB"}>
                                       <Select
                                          placeholder="Trạng thái"
                                          value={item.status}
                                          style={{ width: "100%" }}
                                          onChange={(value) => {
                                             let oldState = state.deposit_card
                                             oldState.card_list[index].status = value
                                             let newState = oldState
                                             setState({ ...state, deposit_card: { ...newState } })
                                          }}
                                       >
                                          <Option value={true}><Badge color={"green"} size="20" text="Kích hoạt"></Badge></Option>
                                          <Option value={false}><Badge color={"red"} size="20" text="Ngừng hoạt động"></Badge></Option>

                                       </Select>
                                    </Col>
                                 </Row>
                                 <Row>

                                    <Col span={24}>
                                       <Space direction="horizontal" style={{ width: '100%', justifyContent: isMobile ? "center" : "right" }} className="gx-w-100">

                                          <Popconfirm title="Chắc chắn muốn xóa？" okText="Yes" cancelText="No"
                                             onConfirm={() => {
                                                let newState = state.deposit_card
                                                newState.card_list = state.deposit_card.card_list.filter(list => list.amount !== item.amount)

                                                setState(state => ({ ...state, deposit_card: newState }))
                                             }}

                                          >
                                             <Button danger size="small" icon={<DeleteOutlined />} >Xóa loại thẻ</Button>
                                          </Popconfirm>
                                       </Space>

                                    </Col>
                                 </Row>



                              </div>
                           )
                        }) : null
                     }
                  </Col>
                  <Col span={24} style={{ marginTop: 10 }}>
                     <Button icon={<PlusOutlined />} onClick={() => {
                        setState({ ...state, deposit_card: { ...state.deposit_card, card_list: [...state.deposit_card.card_list, { ...{ amount: 0, rate: 100, status: false } }] } })
                     }} type="dashed" style={{ width: "100%" }}>Thêm loại thẻ</Button>
                  </Col>
               </Row>
            </Tabs.TabPane>
            <Tabs.TabPane tab="Cày thuê" key="6">
               {
                  state.rank_price?.list ?
                     Object.keys(state.rank_price.list).map((key, index) => {
                        let item = state.rank_price.list[key]
                        if (item.name)
                           return (
                              <>
                                 <p>{`Giá cày thuê ${item.name}`}</p>
                                 <Row>
                                    <Col xl={12} lg={12} md={24} sm={24} className={!isMobile ? "pdR" : "pdB"}>
                                       <Input
                                          disabled
                                          value={item.name} addonAfter={<div style={{ width: 80 }}>Tên</div>}
                                          onChange={(e) => {
                                             setState({ ...state, rank_price: { ...state.rank_price, list: { ...state.rank_price.list, [key]: { ...item, name: e.target.value } } } })
                                          }}
                                       />
                                    </Col>
                                    <Col xl={12} lg={12} md={24} sm={24} className={!isMobile ? "pdL" : "pdB"}>
                                       <NumericInput value={item.price} addonAfter={<div style={{ width: 80 }}>Giá tiền</div>}
                                          disabled={index == 0 ? true : false}
                                          onChange={(e) => {
                                             setState({ ...state, rank_price: { ...state.rank_price, list: { ...state.rank_price.list, [key]: { ...item, price: Number(e.target.value) } } } })
                                          }}
                                       />
                                    </Col>
                                 </Row>
                              </>

                           )
                     }) : null
               }
            </Tabs.TabPane>
            <Tabs.TabPane tab="Ngày cày thuê" key="7">
               {
                  state.rank_price?.list ?
                     Object.keys(state.rank_date.list).map((key, index) => {
                        let item = state.rank_date.list[key]

                        return (
                           <>
                              <p>{`Ngày hoàn thành cày thuê ${key}`}</p>
                              <Row>
                                 <Col xl={12} lg={12} md={24} sm={24} className={!isMobile ? "pdR" : "pdB"}>
                                    <Input
                                       disabled
                                       value={key.toUpperCase()} addonAfter={<div style={{ width: 80 }}>Bậc</div>}
                                       onChange={(e) => {
                                          setState({ ...state, rank_date: { ...state.rank_date, list: { ...state.rank_date.list, [key]: e.target.value } } })
                                       }}
                                    />
                                 </Col>
                                 <Col xl={12} lg={12} md={24} sm={24} className={!isMobile ? "pdL" : "pdB"}>
                                    <NumericInput min={0} value={item} addonAfter={<div style={{ width: 120 }}>Ngày hoàn thành</div>}

                                       onChange={(e) => {
                                          state.rank_date.list[key] = Number(e.target.value)
                                          setState({ ...state, rank_date: state.rank_date })
                                       }}
                                    />
                                 </Col>
                              </Row>
                           </>

                        )
                     }) : null
               }
            </Tabs.TabPane>
            <Tabs.TabPane tab="Popup" key="8">
               {typeof state.popup?.list?.home !== "undefined" ?
                  <>
                     <Divider orientation="left" orientationMargin={0}>Popup trang chủ</Divider>
                     <Select
                        placeholder="Trạng thái"
                        value={state.popup?.list?.home?.status}
                        style={{ width: "100%", marginBottom: 10 }}
                        onChange={(value) => {

                           try {
                              let newState = state.popup
                              newState.list.home.status = value
                              setState({ ...state, popup: newState })
                           } catch (error) {

                           }
                        }}
                     >
                        <Option value={true}><Badge color={"green"} size="20" text="Kích hoạt"></Badge></Option>
                        <Option value={false}><Badge color={"red"} size="20" text="Ngừng hoạt động"></Badge></Option>

                     </Select>
                     <RichEditor
                       editorState={state.popup?.list?.home?.content}
                       onChange={(editorState)=>{
                          let newState = state.popup
                          newState.list.home.content = editorState
                          setState({ ...state, popup: newState })
                       }}
                     />
             
                  </>

                  : null
               }
               {typeof state.popup?.list?.boost !== "undefined" ?
                  <>
                     <Divider orientation="left" orientationMargin={0}>Popup cày thuê</Divider>
                     <Select
                        placeholder="Trạng thái"
                        value={state.popup?.list?.boost?.status}
                        style={{ width: "100%", marginBottom: 10 }}
                        onChange={(value) => {

                           try {
                              let newState = state.popup
                              newState.list.boost.status = value
                              setState({ ...state, popup: newState })
                           } catch (error) {

                           }
                        }}
                     >
                        <Option value={true}><Badge color={"green"} size="20" text="Kích hoạt"></Badge></Option>
                        <Option value={false}><Badge color={"red"} size="20" text="Ngừng hoạt động"></Badge></Option>

                     </Select>
                     <RichEditor
                       editorState={state.popup?.list?.boost?.content}
             
                       onChange={(editorState)=>{
                          let newState = state.popup
                          newState.list.boost.content = editorState
                          setState({ ...state, popup: newState })
                       }}
                     />
                  </>

                  : null
               }
            </Tabs.TabPane>
         </Tabs>
      </Modal>


   )
}
export default Accounts_History