// router.post("/wheel/add_wheel", auth, wheels.add_wheel);
// router.post("/wheel/update_wheel", auth, wheels.update_wheel);
// router.post("/wheel/play", auth, wheels.play);
// router.post("/wheel/get_wheel", auth, wheels.get_wheel);


export const ADD_WHEEL = "wheel/ADD_WHEEL";
export const ADD_WHEEL_SUCCESS = "wheel/ADD_WHEEL_SUCCESS";
export const ADD_WHEEL_ERROR = "wheel/ADD_WHEEL_ERROR";

export const UPDATE_WHEEL = "wheel/UPDATE_WHEEL";
export const UPDATE_WHEEL_SUCCESS = "wheel/UPDATE_WHEEL_SUCCESS";
export const UPDATE_WHEEL_ERROR = "wheel/UPDATE_WHEEL_ERROR";

export const PLAY_WHEEL = "wheel/PLAY_WHEEL";
export const PLAY_WHEEL_SUCCESS = "wheel/PLAY_WHEEL_SUCCESS";
export const PLAY_WHEEL_ERROR = "wheel/PLAY_WHEEL_ERROR";

export const DELETE_WHEEL = "wheel/DELETE_WHEEL";
export const DELETE_WHEEL_SUCCESS = "wheel/DELETE_WHEEL_SUCCESS";
export const DELETE_WHEEL_ERROR = "wheel/DELETE_WHEEL_ERROR";

export const GET_WHEEL = "wheel/GET_WHEEL";
export const GET_WHEEL_SUCCESS = "wheel/GET_WHEEL_SUCCESS";
export const GET_WHEEL_ERROR = "wheel/GET_WHEEL_ERROR";

export const RESET_WHEEL = "wheel/RESET_WHEEL";
export const RESET_WHEEL_SUCCESS = "wheel/RESET_WHEEL_SUCCESS";
export const RESET_WHEEL_ERROR = "wheel/RESET_WHEEL_ERROR";

export const GET_HISTORYS = "wheel/GET_HISTORYS";
export const GET_HISTORYS_SUCCESS = "wheel/GET_HISTORYS_SUCCESS";
export const GET_HISTORYS_ERROR = "wheel/GET_HISTORYS_ERROR";