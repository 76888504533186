//////DISCOUNT
// router.post("/DISCOUNTs/add_DISCOUNT", auth, DISCOUNTs.add_DISCOUNT);
// router.post("/DISCOUNTs/update_DISCOUNT", auth, DISCOUNTs.update_DISCOUNT);
// router.post("/DISCOUNTs/delete_DISCOUNT", auth, DISCOUNTs.delete_DISCOUNT);
// router.post("/DISCOUNTs/buy_DISCOUNT", auth, DISCOUNTs.buy_DISCOUNT);

export const ADD_DISCOUNT = "DISCOUNT/ADD_DISCOUNT";
export const ADD_DISCOUNT_SUCCESS = "DISCOUNT/ADD_DISCOUNT_SUCCESS";
export const ADD_DISCOUNT_ERROR = "DISCOUNT/ADD_DISCOUNT_ERROR";

export const UPDATE_DISCOUNT = "DISCOUNT/UPDATE_DISCOUNT";
export const UPDATE_DISCOUNT_SUCCESS = "DISCOUNT/UPDATE_DISCOUNT_SUCCESS";
export const UPDATE_DISCOUNT_ERROR = "DISCOUNT/UPDATE_DISCOUNT_ERROR";

export const DELETE_DISCOUNT = "DISCOUNT/DELETE_DISCOUNT";
export const DELETE_DISCOUNT_SUCCESS = "DISCOUNT/DELETE_DISCOUNT_SUCCESS";
export const DELETE_DISCOUNT_ERROR = "DISCOUNT/DELETE_DISCOUNT_ERROR";

export const GET_DISCOUNT = "DISCOUNT/GET_DISCOUNT";
export const GET_DISCOUNT_SUCCESS = "DISCOUNT/GET_DISCOUNT_SUCCESS";
export const GET_DISCOUNT_ERROR = "DISCOUNT/GET_DISCOUNT_ERROR";

export const RESET_DISCOUNT = "DISCOUNT/RESET_DISCOUNT";
export const RESET_DISCOUNT_SUCCESS = "DISCOUNT/RESET_DISCOUNT_SUCCESS";
export const RESET_DISCOUNT_ERROR = "DISCOUNT/RESET_DISCOUNT_ERROR";