import React, { useEffect, useState } from 'react';
import Header_Line from '../components/Header_Line';

import { message } from 'antd';
import SweetAlert from "react-bootstrap-sweetalert";
import Confetti from 'react-confetti';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory, useParams } from "react-router-dom";
import { useWindowSize } from 'react-use';
import Wheel from '../components/Wheel';
import { me } from '../modules/user/actions';
import { get_wheel, play_wheel, reset_wheel } from "../modules/wheel/actions";

const Home = (props) => {

    const dispatch = useDispatch();
    const _user = useSelector(({ user }) => user.me.user);
    const _wheelList = useSelector(({ wheel }) => wheel.wheelList);
    const _playWheel = useSelector(({ wheel }) => wheel.playWheel);
    const history = useHistory();
    let { url } = useParams();
    const { width, height } = useWindowSize()

    let initialState = {
        wheel: null,
        imgRotote: {},
        rando: 0,
        start: false,
        round: 1,
        showResult: false

    }
    const [state, setState] = useState(initialState)

    const handleGetWheel = async () => {
        dispatch(get_wheel({
            url,
            page: 0,
            limit: 1
        }))
    }
    const handleGetPlay = async () => {
        dispatch(play_wheel({
            _id: state.wheel._id
        }))
    }
    useEffect(() => {
        window.scrollTo(0, 0)
        handleGetWheel()
        return () => {
            dispatch(reset_wheel({ remove: ["all"] }))
        }
    }, [])
    useEffect(() => {
        if (_wheelList.data.length == 0 && _wheelList.status) {
            message.warning("Không tìm thấy vòng quay này")
            history.push("/")
        }
        else {
            setState({ ...state, wheel: _wheelList.data[0] })
        }

    }, [_wheelList.data])
    var handlePlay = function () {
        if (!_user._id) {
            return message.warning("Vui lòng đăng nhập")
        }
        if (_user.balance < state.wheel.price) {
            return message.warning("Số dư của bạn không đủ để quay, vui lòng nạp thêm tiền")
        }
        if (state.start) {
            return message.warning("Vui lòng đợi vòng quay kết thúc")
        }
        handleGetPlay()
    }
    useEffect(() => {
        if (_playWheel.status) {
            dispatch(me())
            let select = _playWheel.data
            let selectPostion = ((360 / 8) / 45 * select) - 1
            var randomNumber = (Math.floor(Math.random() * (10 - 5 + 1)) + 5) * 360 + state.rando
            let rando = selectPostion * 45 + randomNumber
            setState({
                ...state, start: true, rando: randomNumber, imgRotote: {
                    webkitTransform: "rotate(" + rando + "deg)",
                    mozTransform: "rotate(" + rando + "deg)",
                    msTransform: "rotate(" + rando + "deg)",
                    transform: "rotate(" + rando + "deg)",
                }
            })
            setTimeout(() => {
                setState(state => ({ ...state, start: false, showResult: true }))
             
            }, 5500);
            
        }
        else if (!_playWheel.status && _playWheel.msg !== null) message.warning(_playWheel.msg)

    }, [_playWheel])

    return (
        <div>
            {

                state.wheel ?
                    <div>
                        {state.showResult ?
                            <div>
                                {_playWheel.status && _playWheel.type !== 2 && state.start == false ?
                                 <Confetti
                                 width={width}
                                 height={height}
                             /> : null
                            }
                               
                            <SweetAlert
                                    show={state.showResult}
                                    type= {_playWheel.type == 2 ? "warning" : "success"}
                                   
                                    showCancel
                                    confirmBtnText={"Quay Tiếp"}
                                    cancelBtnText={"Đóng"}
                                    confirmBtnBsStyle="danger"
                                    cancelBtnBsStyle="default"
                                    title={_playWheel.type == 2 ? "Oppss!!" : "WWowww!!"}
                    
                                    onConfirm={()=>{
                                        setState({...state, showResult: false})
                                        handlePlay()
                                    }}
                                    onCancel={() =>  setState({...state, showResult: false})}
                                >
                                   { _playWheel.msg}
                                </SweetAlert>
                            </div>
                            : null
                        }
                        <Header_Line text={state.wheel.name} textColor="#656565" lineColor="#32c5d2" />
                        <Wheel {...state.wheel} round={2} onClick={handlePlay} {...state} />
                    </div>
                    : null
            }
        </div>
    );
}


export default Home;