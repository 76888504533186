import { httpClient as request } from "../../utils/Api";
// router.post("/discounts/add_discount", auth, discounts.add_discount);
// router.post("/discounts/update_discount", auth, discounts.update_discount);
// router.post("/discounts/delete_discount", auth, discounts.delete_discount);
// router.post("/discounts/buy_discount", auth, discounts.buy_discount);
// router.post("/discounts/get_discount", auth, discounts.get_discount);
export const add_discount = payload => {
  return request.post("/discount/add_discount", payload)
};

export const update_discount = payload => {
  return request.post("/discount/update_discount", payload)
};

export const delete_discount = payload => {
  return request.post("/discount/delete_discount", payload)
};

export const get_discounts = payload => {
  return request.post("/discount/get_discounts", payload)
};