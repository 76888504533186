import * as types from "./constants";
// router.post("/boost/buy_boost", auth, boosts.buy_boost);
// router.post("/boost/update_boost", auth, boosts.update_boost);
// router.post("/boost/get_boost", auth, boosts.get_boost);
// router.post("/boost/apply_boost", auth, boosts.apply_boost);

export function buy_boost(payload) {
  return {
    type: types.BUY_BOOST,
    payload,
  };
}
export function update_boost(payload) {
  return {
    type: types.UPDATE_BOOST,
    payload,
  };
}
export function get_boost(payload) {
  return {
    type: types.GET_BOOST,
    payload,
  };
}
export function apply_boost(payload) {
  return {
    type: types.APPLY_BOOST,
    payload,
  };
}
export function reset_boost(payload) {
  return {
    type: types.RESET_BOOST,
    payload,
  };
}
export function get_boost_price(payload) {
  return {
    type: types.GET_BOOST_PRICE,
    payload,
  };
}