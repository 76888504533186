import { put, call, takeLatest } from "redux-saga/effects";
import * as types from "./constants";
import * as services from "./services";
// router.post("/category/create_category", auth, categorys.create_category);
// router.post("/category/update_category", auth, categorys.update_category);
// router.post("/category/delete_category", auth, categorys.delete_category);
// router.post("/category/get_categorys", auth, categorys.get_categorys);

export function* create_category(action) {
  try {
    const { data, status } = yield call(services.create_category, action.payload);
    yield put({
      type: types.CREATE_CATEGORY_SUCCESS,
      payload: data,
    });
  } catch (error) {
    if (error.response) {
      const { data, status } = error.response
      if (status == 401)
        yield put({
          type: "user/LOG_OUT_SUCCESS",
          payload: {
            msg: data.msg,
            status: false
          },
        });
      else yield put({
        type: types.CREATE_CATEGORY_ERROR,
        payload: {msg:"Không thể kết nối server", status:false},
      });
    }
    else
      yield put({
        type: types.CREATE_CATEGORY_ERROR,
        payload: {
          msg: `Không thể kết nối server`,
          status: false
        },
      });
  }
}
export function* update_category(action) {
  try {
    const { data, status } = yield call(services.update_category, action.payload);
    yield put({
      type: types.UPDATE_CATEGORY_SUCCESS,
      payload: data,
    });
  } catch (error) {
    if (error.response) {
      const { data, status } = error.response
      if (status == 401)
        yield put({
          type: "user/LOG_OUT_SUCCESS",
          payload: {
            msg: data.msg,
            status: false
          },
        });
      else yield put({
        type: types.UPDATE_CATEGORY_ERROR,
        payload: {msg:"Không thể kết nối server", status:false},
      });
    }
    else
      yield put({
        type: types.UPDATE_CATEGORY_ERROR,
        payload: {
          msg: `Không thể kết nối server`,
          status: false
        },
      });
  }
}
export function* delete_category(action) {
  try {
    const { data, status } = yield call(services.delete_category, action.payload);
    yield put({
      type: types.DELETE_CATEGORY_SUCCESS,
      payload: data,
    });
  } catch (error) {
    if (error.response) {
      const { data, status } = error.response
      if (status == 401)
        yield put({
          type: "user/LOG_OUT_SUCCESS",
          payload: {
            msg: data.msg,
            status: false
          },
        });
      else yield put({
        type: types.DELETE_CATEGORY_ERROR,
        payload: {msg:"Không thể kết nối server", status:false},
      });
    }
    else
      yield put({
        type: types.DELETE_CATEGORY_ERROR,
        payload: {
          msg: `Không thể kết nối server`,
          status: false
        },
      });
  }
}
export function* get_categorys(action) {
  try {
    //console.log(1)
    const { data, status } = yield call(services.get_categorys, action.payload);
    yield put({
      type: types.GET_CATEGORY_SUCCESS,
      payload: data,
    });
  } catch (error) {
    if (error.response) {
      const { data, status } = error.response
      if (status == 401)
        yield put({
          type: "user/LOG_OUT_SUCCESS",
          payload: {
            msg: data.msg,
            status: false
          },
        });
      else yield put({
        type: types.GET_CATEGORY_ERROR,
        payload: {msg:"Không thể kết nối server", status:false},
      });
    }
    else
      yield put({
        type: types.GET_CATEGORY_ERROR,
        payload: {
          msg: `Không thể kết nối server`,
          status: false
        },
      });
  }
}
export default function* categorySaga() {
  yield takeLatest(types.CREATE_CATEGORY, create_category);
  yield takeLatest(types.UPDATE_CATEGORY, update_category);
  yield takeLatest(types.DELETE_CATEGORY, delete_category);
  yield takeLatest(types.GET_CATEGORY, get_categorys);

}
